import { Typography } from 'antd';
import { Button, useModal } from '@tml-component-library/src';
import { CheckCircleOutlined, CheckCircleTwoTone, CheckCircleFilled } from '@tml-component-library/src/icons';
import { KnowledgeBaseCommand, StatusType } from 'api/knowledge-base/types';
import { ManageRegenerationKnowledgeBaseModal } from 'features/company-admin/manage-regeneration-knowledge-base/ManageRegenerationKnowledgeBaseModal';
import { getKnowledgeBaseActionProps } from '../utils';

type KnowledgeBaseCreationStepsProps = {
  knowledgeBaseId: string;
  courseTemplateId: string;
  isLoading: boolean;
  currentStatus?: StatusType;
  onUpdateKnowledgeBase: (command: KnowledgeBaseCommand) => () => void;
};

const KB_CREATION_STEPS = [
  {
    label: 'Lessons',
    state: StatusType.CREATING_QUESTIONS,
  },
  {
    label: 'Publish',
    state: StatusType.READY_PUBLISH,
  },
];

const getCurrentStateConfig = (currentStateIndex: number, stepStateIndex: number) => {
  if (currentStateIndex === stepStateIndex) {
    return {
      Icon: <CheckCircleTwoTone className="text-2xl text-custom-blue-primary" />,
      font: 'font-bold',
    };
  }

  if (currentStateIndex > stepStateIndex) {
    return {
      Icon: <CheckCircleFilled className="text-2xl text-custom-black-transparent-45" />,
      font: 'font-normal',
    };
  }

  if (currentStateIndex < stepStateIndex) {
    return {
      Icon: <CheckCircleOutlined className="text-2xl text-custom-black-transparent-45" />,
      font: 'font-normal',
    };
  }

  return {
    Icon: null,
    font: '',
  };
};

export const KnowledgeBaseCreationSteps = ({
  knowledgeBaseId,
  courseTemplateId,
  currentStatus,
  isLoading,
  onUpdateKnowledgeBase,
}: KnowledgeBaseCreationStepsProps) => {
  const {
    showModal: showRegenerationKbModal,
    hideModal: hideRegenerationKbModal,
    open: openRegenerationModal,
  } = useModal();

  const { showButton, text, textPublish, onClickPublish, onClick, disabled, ...actionProps } =
    getKnowledgeBaseActionProps(onUpdateKnowledgeBase, showRegenerationKbModal, currentStatus);
  const currentStateIndex = Object.values(StatusType).findIndex((status) => status === currentStatus);

  return (
    <div className="flex flex-col gap-7">
      <div className="flex flex-col gap-2.5">
        <Typography.Title level={4}>Course Creation</Typography.Title>

        {KB_CREATION_STEPS.map(({ label, state }) => {
          const stateIndex = Object.values(StatusType).findIndex((status) => status === state);
          const { font, Icon } = getCurrentStateConfig(currentStateIndex, stateIndex);

          return (
            <div
              key={state}
              className="flex gap-2.5 items-center"
            >
              {Icon}
              <Typography.Text className={font}>{label}</Typography.Text>
            </div>
          );
        })}
      </div>

      {showButton && (
        <div className="w-full flex flex-col justify-center gap-2.5">
          <Button
            className="w-full"
            size="small"
            type="primary"
            disabled={disabled || isLoading}
            onClick={onClick}
            {...actionProps}
          >
            {text}
          </Button>
          <Button
            className="w-full"
            size="small"
            type="primary"
            disabled={disabled || isLoading}
            onClick={onClickPublish}
            {...actionProps}
          >
            {textPublish}
          </Button>
        </div>
      )}

      {Boolean(courseTemplateId && knowledgeBaseId) && (
        <ManageRegenerationKnowledgeBaseModal
          courseTemplateId={courseTemplateId}
          knowledgeBaseId={knowledgeBaseId}
          open={openRegenerationModal}
          hideModal={hideRegenerationKbModal}
        />
      )}
    </div>
  );
};
