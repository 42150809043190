import { useCallback, useState } from 'react';

export const useModal = () => {
  const [open, setOpen] = useState(false);

  const showModal = useCallback(() => setOpen(true), [setOpen]);

  const hideModal = useCallback(() => setOpen(false), [setOpen]);

  return {
    open,
    showModal,
    hideModal,
  };
};
