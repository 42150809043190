import { useLazyCourseListQuery } from 'api/course/course.api';

export const useSelectCourse = () => {
  const [fetchCourseListQuery, { data, isFetching: isCoursesLoading }] = useLazyCourseListQuery();

  const fetchCourseList = async (departmentId: string) => fetchCourseListQuery({ departmentId }).unwrap();

  const coursesOptions = data?.courses?.map((course) => {
    return {
      key: course.id,
      label: course.name,
      value: course.id,
    };
  });

  return {
    fetchCourseList,
    isCoursesLoading,
    coursesOptions,
  };
};
