import { FileFormats } from '@tml-component-library/src';
import { messages } from './messages';

export enum ManageKbDocumentFields {
  FILE_NAME = 'fileName',
  FILE_VERSION = 'version',
  FILE_DESCRIPTION = 'description',
  UPLOAD_DOCUMENT = 'fileUpload',
}

export const ACCEPTED_FILE_FORMATS: string[] = [
  FileFormats.PDF,
  FileFormats.DOC,
  FileFormats.DOCX,
  FileFormats.TXT,
  FileFormats.PPTX,
];

export enum ManageKbDocumentsTab {
  DOCUMENTS,
  SUPPLEMENTS,
}

export const TAB_ITEMS = [
  {
    label: messages.documentsAdded,
    value: ManageKbDocumentsTab.DOCUMENTS,
  },
  {
    label: messages.availableSupplements,
    value: ManageKbDocumentsTab.SUPPLEMENTS,
  },
];
