import { Meta } from '@tml-component-library/src/components/Meta';
import { KnowledgeBaseDetails } from 'features/company-admin/knowledge-base-details';

export const KnowledgeBaseDetailsPage = () => (
  <>
    <Meta title="Knowledge Base Details" />

    <KnowledgeBaseDetails />
  </>
);
