import { useCallback, useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { CustomFormProvider, Modal } from '@tml-component-library/src';
import { FormRequiredMark } from 'components/FormRequiredMark';
import {
  resetComponentFileListState,
  useLinkComponentFileMutation,
  useUnlinkComponentFileMutation,
} from 'api/component-file/component-file.api';
import { useLazyModuleDetailsQuery } from 'api/module/module.api';
import { useManageDocuments, useUploadFileByUrl } from 'hooks';
import { ComponentFileEntity } from 'api/component-file/types';
import { FileCategory, FileType } from 'api/file/types';
import { manageDocumentValidationSchema } from './validation.schema';
import { ManageSupplementDocumentForm } from './components/ManageSupplementDocumentForm';
import { ManageDocumentFields } from './constants';
import { messages } from './messages';
import { ManageDocumentValues } from './types';
import { useAppDispatch } from '../../../redux';

type ManageDocumentModalProps = {
  open: boolean;
  hideModal: () => void;
  data?: ComponentFileEntity;
};

export const ManageSupplementDocumentModal = ({ data, open, hideModal }: ManageDocumentModalProps) => {
  const { createFile, updateFile } = useManageDocuments();
  const { uploadFileByUrl } = useUploadFileByUrl();
  const [linkComponentFile] = useLinkComponentFileMutation();
  const [unlinkComponentFile] = useUnlinkComponentFileMutation();
  const [getModuleDetails] = useLazyModuleDetailsQuery();

  const dispatch = useAppDispatch();

  const initialValues = useMemo(
    () => ({
      [ManageDocumentFields.DEPARTMENTS]: data?.department?.id,
      [ManageDocumentFields.COURSE_TEMPLATE]: data?.course?.id,
      [ManageDocumentFields.FILE_NAME]: data?.file?.name,
      [ManageDocumentFields.FILE_VERSION]: data?.file?.version,
      [ManageDocumentFields.FILE_CATEGORY]: FileCategory.CUSTOMIZABLE_TEMPLATE,
      [ManageDocumentFields.MODULES]: data?.module?.id,
      [ManageDocumentFields.UPLOAD_DOCUMENT]: data?.file?.nameWithExtension,
    }),
    [data]
  );

  const onSubmit = useCallback(
    async (values: Partial<ManageDocumentValues>) => {
      const documentValue = values[ManageDocumentFields.UPLOAD_DOCUMENT];
      const prevModuleId = data?.topic?.id;
      const currentModuleId = values[ManageDocumentFields.MODULES]!;

      const wasDocumentValueChanged = typeof documentValue !== 'string';
      const uuid = wasDocumentValueChanged ? uuidV4() : data?.file?.id!;

      const fileEntity = {
        id: uuid,
        name: values[ManageDocumentFields.FILE_NAME]!,
        nameWithExtension: wasDocumentValueChanged ? documentValue!.name : documentValue,
        version: values[ManageDocumentFields.FILE_VERSION]!,
        type: FileType.TM_DOCUMENT,
        category: values[ManageDocumentFields.FILE_CATEGORY]!,
      };

      if (wasDocumentValueChanged) {
        await uploadFileByUrl(uuid, documentValue as File);

        await createFile(fileEntity);
      } else {
        await updateFile(fileEntity);
      }

      if (prevModuleId !== currentModuleId) {
        if (data?.id) await unlinkComponentFile(data?.id);

        const { componentType } = await getModuleDetails({ id: currentModuleId }).unwrap();

        await linkComponentFile({
          fileId: uuid,
          componentId: currentModuleId,
          componentType,
        });
      } else {
        dispatch(resetComponentFileListState());
      }

      hideModal();
    },
    [
      createFile,
      data,
      getModuleDetails,
      hideModal,
      linkComponentFile,
      unlinkComponentFile,
      updateFile,
      uploadFileByUrl,
      dispatch,
    ]
  );

  const getDeleteFileHandler = () => {
    if (!data) return;

    return async () => {
      if (data?.id) await unlinkComponentFile(data?.id);

      hideModal();
    };
  };

  return (
    <Modal
      title={data ? messages.editDocument : messages.addDocument}
      open={open}
      className="py-5 px-6"
      onCancel={hideModal}
      footer={null}
      destroyOnClose
    >
      <CustomFormProvider
        onSubmit={onSubmit}
        validationSchema={manageDocumentValidationSchema}
        initialValues={initialValues}
        requiredMark={FormRequiredMark}
      >
        <ManageSupplementDocumentForm
          documentName={data?.file?.name}
          onCancel={hideModal}
          onDelete={getDeleteFileHandler()}
        />
      </CustomFormProvider>
    </Modal>
  );
};
