import { CompanyList } from 'features/tm-staff/company-list';
import { Meta } from '@tml-component-library/src/components/Meta';

export const CompaniesPage = () => (
  <>
    <Meta title="Companies" />

    <CompanyList />
  </>
);
