import { ListParams, ListResponse } from 'utils';
import { QuestionType } from '../quiz/types';
import { ModuleEntity } from '../module/types';
import { TopicEntity } from '../topic/types';
import { RelatedRoleEntity, TemplateType } from '../types';

export enum CourseTemplateStatus {
  CREATED = 'CREATED',
  EDIT = 'EDIT',
  PUBLISH = 'PUBLISH',
  UNPUBLISH = 'UNPUBLISH',
  ARCHIVED = 'ARCHIVED',
}

export interface CourseTemplateShortListRequest extends ListParams {
  status: CourseTemplateStatus;
}

export interface CourseTemplateShortEntity {
  id: string;
  courseId: string;
  companyId: string;
  courseName: string;
  version: number;
  templateType: TemplateType;
  basedOnCourseTemplateId: string;
}

export interface CourseTemplateShortListResponse extends ListResponse {
  totalCourseTemplates: number;
  courseTemplates: CourseTemplateShortEntity[];
}

export interface CreateCourseTemplateRequest {
  courseId: string;
  basedOnCourseTemplateId?: string;
}

export interface CourseTemplateDetailsRequest {
  id: string;
  knowledgeBaseId?: string;
}

export interface CourseTemplateRoleEntity extends RelatedRoleEntity {
  basedOnRoleId: string;
  createdAt: string;
  updatedAt: string;
}

export interface CourseTemplateTopicEntity extends TopicEntity {
  basedOnTopicId: string;
  moduleId: string;
  settings: RoleSettingEntity[];
}

export interface CourseTemplateModuleEntity extends ModuleEntity {
  basedOnModuleId: string;
  prerequisitesIds: string[];
  settings: RoleSettingEntity[];
  topics: CourseTemplateTopicEntity[];
}

export interface CourseTemplateDetailsResponse {
  id: string;
  courseId: string;
  companyId: string;
  courseName: string;
  templateType: TemplateType;
  basedOnCourseTemplateId: string;
  status: CourseTemplateStatus;
  version: number;
  roles: CourseTemplateRoleEntity[];
  modules: CourseTemplateModuleEntity[];
}

export interface QuizSettingEntity {
  id: string;
  questionType: QuestionType;
  numberOfQuestions: number;
  requirement: string;
  ordinalNumber: number;
  createdAt: string;
  updatedAt: string;
}

export interface RoleSettingEntity {
  id: string;
  courseTemplateRoleId?: string;
  isIncluded: boolean;
  isFinalized: boolean;
  quizSettings: QuizSettingEntity[];
  isProgressiveLessonsExist?: boolean;
  isComplexLessonsExist?: boolean;
  isStandardLessonsExist?: boolean;
}

export interface CourseTemplateModuleListRequest extends ListParams {
  courseTemplateId: string;
}

export interface CourseTemplateModuleListResponse extends ListResponse {
  totalCourseTemplateModules: number;
  courseTemplateModules: CourseTemplateModuleEntity[];
}

export interface CourseTemplateModuleDetailsRequest {
  id: string;
}

export interface CourseTemplateTopicListRequest extends ListParams {
  courseTemplateId: string;
}

export interface CourseTemplateTopicListResponse extends ListResponse {
  totalCourseTemplateTopics: number;
  courseTemplateModules: CourseTemplateTopicEntity[];
}

export interface CourseTemplateTopicDetailsRequest {
  id: string;
}
