import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Typography } from 'antd';
import { Checkbox, useFormContext, TextInput, FormField } from '@tml-component-library/src';
import { QuestionOptionsEntity } from 'api/quiz/types';
import { ManageQuestionFormField } from '../constants';
import { messages } from '../messages';

interface MultipleQuestionAnswersProps {
  options: QuestionOptionsEntity;
  initialValues: string;
}

export const MultipleQuestionAnswers = ({ initialValues, options }: MultipleQuestionAnswersProps) => {
  const { setFieldValue, values, getFieldsErrors } = useFormContext();
  const [checkedItems, setCheckedItems] = useState<(keyof QuestionOptionsEntity)[]>(initialValues.split(','));

  const onChangeCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toUpperCase();

      if (checkedItems.includes(value)) {
        setCheckedItems((prev) => {
          return prev.filter((item) => item !== value);
        });
      } else {
        setCheckedItems((prev) => {
          return [...prev, value];
        });
      }
    },
    [checkedItems]
  );

  const onChangeAnswerOptionText = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const answerOptionKey = event.target.dataset.key as string;
      const prevFieldValue = {
        ...values[ManageQuestionFormField.ANSWER_OPTIONS],
        [answerOptionKey]: event.target.value,
      };

      setFieldValue(ManageQuestionFormField.ANSWER_OPTIONS, prevFieldValue);
    },
    [setFieldValue, values]
  );

  useEffect(() => {
    setFieldValue(ManageQuestionFormField.ANSWER, checkedItems.join(','));
  }, [checkedItems, setFieldValue]);

  const isThereAnswerOptionValidationError = getFieldsErrors([ManageQuestionFormField.ANSWER_OPTIONS]).some(
    (item) => item.errors.length
  );
  const isThereCorrectCheckboxValidationError = getFieldsErrors([ManageQuestionFormField.ANSWER]).some(
    (item) => item.errors.length
  );

  return (
    <div className="flex flex-col gap-1">
      {Object.keys(options).map((key) => (
        <div
          key={key}
          className="py-1 px-2 flex gap-2.5 items-center justify-between rounded-lg border border-solid border-custom-natural-gray-5"
        >
          <Typography.Text className="block font-bold">{key.toUpperCase()} :</Typography.Text>

          {/* This FormField is needed here and hidden because we need to initiate state of questionOptions field */}
          <FormField
            className="hidden"
            name={ManageQuestionFormField.ANSWER_OPTIONS}
          />
          <TextInput
            data-key={key}
            className="w-3/4"
            value={values[ManageQuestionFormField.ANSWER_OPTIONS]?.[key]}
            onChange={onChangeAnswerOptionText}
          />

          <div className="flex items-center gap-2">
            {/* This FormField is needed here and hidden because we need to initiate state of answer field */}
            <FormField
              className="hidden"
              name={ManageQuestionFormField.ANSWER}
            />
            <Checkbox
              value={key}
              onChange={onChangeCheckbox}
              checked={checkedItems.includes(key.toUpperCase())}
            />
            <Typography.Text>Correct</Typography.Text>
          </div>
        </div>
      ))}

      {isThereAnswerOptionValidationError && (
        <Typography.Text type="danger">{messages.emptyAnswerOptionsNotAllowed}</Typography.Text>
      )}
      {isThereCorrectCheckboxValidationError && (
        <Typography.Text type="danger">{messages.atLeastOneAnswerShouldBeCorrect}</Typography.Text>
      )}
    </div>
  );
};
