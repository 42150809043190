import { CustomFormInputLabel, RequiredMark } from '@tml-component-library/src';

export const FormRequiredMark: RequiredMark = (label, { required }) => (
  <CustomFormInputLabel
    required={required}
    content={label}
    labelStyles={{
      fontSize: 14,
    }}
  />
);
