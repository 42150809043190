import { Typography } from 'antd';
import { DropdownMenu } from '@tml-component-library/src/components/DropdownMenu';
import { LevelType } from 'api/types';
import { messages } from '../messages';
import { LevelTitles } from '../constants';

interface ContentHeaderProps {
  onChange: (value: LevelType) => void;
  level: string;
  headerTitle?: string;
  title?: string;
}

export const ContentHeader = ({ onChange, level, headerTitle, title }: ContentHeaderProps) => (
  <div className="bg-custom-natural-gray-3 z-10">
    <div className="flex justify-between items-center">
      <Typography.Title
        className="!font-medium break-normal"
        level={5}
      >
        {headerTitle}
      </Typography.Title>

      <div className="flex gap-2.5">
        <Typography.Text className="font-bold break-normal">{messages.levels}</Typography.Text>
        <DropdownMenu
          className="whitespace-nowrap"
          defaultSelected={level}
          value={level}
          onSelect={onChange}
          items={(Object.keys(LevelTitles) as LevelType[]).map((item) => ({
            label: LevelTitles[item],
            value: item,
          }))}
        />
      </div>
    </div>

    <Typography.Title
      className="!my-2.5 !font-medium"
      level={3}
    >
      {title}
    </Typography.Title>
  </div>
);
