import { TableProps } from 'antd';
import { Table } from '@tml-component-library/src/components';
import { useUserListQuery } from 'api/user/user.api';
import { UserAuthoritiesEntity, UserEntity } from 'api/user/types';

export type UserListColumns = TableProps<UserEntity>['columns'];

interface UserListTableProps {
  columns: UserListColumns;
  companyId?: string;
  isInitialLoading?: boolean;
  userRole: UserAuthoritiesEntity;
}

export const UserListTable = ({ isInitialLoading, companyId, columns, userRole }: UserListTableProps) => {
  const { data, isLoading } = useUserListQuery({
    userRole,
    companyId,
  });

  return (
    <Table
      loading={isLoading || isInitialLoading}
      columns={columns}
      dataSource={data?.users || []}
      rowKey="id"
      sticky
      pagination={false}
    />
  );
};
