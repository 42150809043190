import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import {
  DepartmentListRequest,
  DepartmentListResponse,
  DepartmentDetailsResponse,
  DepartmentDetailsRequest,
} from './types';

export const departmentApi = createApi({
  reducerPath: 'department',
  tagTypes: ['departmentList', 'departmentDetails'],
  baseQuery: axiosQuery({ baseUrl: '/department' }),
  endpoints: (builder) => ({
    departmentList: builder.query<DepartmentListResponse, DepartmentListRequest | void>({
      query: (params) => ({
        url: '/list',
        params: { ...DEFAULT_LIST_PARAMS, ...params },
      }),
      providesTags: ['departmentList'],
    }),
    departmentDetails: builder.query<DepartmentDetailsResponse, DepartmentDetailsRequest>({
      query: ({ id }) => ({
        url: `/${id}`,
      }),
      providesTags: ['departmentDetails'],
    }),
  }),
});

export const { useDepartmentListQuery, useDepartmentDetailsQuery } = departmentApi;
