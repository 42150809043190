import { Spin } from 'antd';
import { RoleSettingsTabs } from 'features/shared/manage-role-settings/RoleSettingsTabs';
import { ModuleTopic } from 'features/shared/manage-module-topic/ModuleTopic';
import { useCourseTemplateDetailsContext } from '../CourseTemplateDetailsProvider';

interface ContentProps {
  isStaff: boolean;
}

export const Content = ({ isStaff = false }: ContentProps) => {
  const { isLoading, selectedComponent, courseTemplateDetailsData } = useCourseTemplateDetailsContext();

  const roleSettings = selectedComponent?.settings;
  const id = selectedComponent?.id;

  return (
    <div className="flex flex-col h-full gap-2.5 overflow-auto bg-custom-natural-gray-3 p-4">
      <Spin spinning={isLoading}>
        <div
          key={id} // To re-render child components in case of ID was changed with initial state for them
          className="min-h-40"
        >
          {!isLoading && (
            <>
              {id && (
                <ModuleTopic
                  id={id}
                  staff={isStaff}
                  componentType={selectedComponent!.componentType}
                />
              )}

              {courseTemplateDetailsData?.roles.length && roleSettings && (
                <RoleSettingsTabs
                  isStaff={isStaff}
                  roles={courseTemplateDetailsData?.roles}
                  roleSettings={roleSettings}
                  componentType={selectedComponent!.componentType}
                />
              )}
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};
