import { Navigate } from 'react-router-dom';
import { useUser } from 'common/user/useUser';
import { Routes } from '../routes';
import { UserAuthoritiesEntity } from '../../api/user/types';

export const RedirectComponent = () => {
  const { data: { metadata } = {} } = useUser();

  switch (true) {
    case metadata?.authorities?.includes(UserAuthoritiesEntity.ROLE_STAFF):
      return (
        <Navigate
          to={Routes.staff()}
          replace
        />
      );
    case metadata?.authorities?.includes(UserAuthoritiesEntity.ROLE_ADMIN):
      return (
        <Navigate
          to={Routes.admin()}
          replace
        />
      );
    default:
      return null;
  }
};
