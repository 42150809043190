export const hideEmailPrefix = (email: string) => {
  const prefix = email.indexOf('@');
  const startIndex = 1;
  const endIndex = prefix - 1 || 0;

  return (
    email.slice(0, startIndex) +
    email.slice(startIndex, endIndex).replace(/./g, '*') +
    email.slice(endIndex)
  );
};
