import { Empty, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuestionsListQuery } from 'api/quiz/quiz.api';
import { LevelType } from 'api/types';
import { ComponentType, QuestionType } from 'api/quiz/types';
import { Question } from './Question';
import { messages } from '../messages';

interface CommonQuestionsProps {
  id: string;
  type: ComponentType;
  level: LevelType;
  isPublished: boolean;
  editHeaderTitle: string;
  roleId?: string;
  regenerationVersion?: number;
}

export const CommonQuestions = ({
  id,
  roleId,
  type,
  level,
  isPublished,
  editHeaderTitle,
  regenerationVersion,
}: CommonQuestionsProps) => {
  const { kbId } = useParams();

  const { data, isFetching } = useQuestionsListQuery({
    knowledgeBaseId: kbId!,
    componentId: id,
    componentType: type,
    questionType: `${QuestionType.ILE_ESSAY},${QuestionType.OPEN},${QuestionType.MULTIPLE}`,
    courseTemplatesRoleId: roleId,
    level,
    regenerationVersion,
  });

  const questions = data?.questions;

  return (
    <Spin
      wrapperClassName={`min-h-60 overflow-y-auto ${isPublished ? 'mb-4' : 'mb-16'}`}
      spinning={isFetching}
    >
      {!isFetching &&
        (questions?.length ? (
          questions.map((question) => (
            <Question
              key={question.questionId}
              question={question}
              editHeaderTitle={editHeaderTitle}
              disableEditing={isPublished}
            />
          ))
        ) : (
          <Empty
            className="mt-8"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={messages.noQuestions}
          />
        ))}
    </Spin>
  );
};
