import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Tag, Typography } from 'antd';
import { Button, useModal, compileMarkdownToJSX, convertDateFormat, DAY_FORMAT } from '@tml-component-library/src';
import { ManageLessonModal } from 'features/company-admin/manage-lesson/ManageLessonModal';
import { useQuestionsListQuery } from 'api/quiz/quiz.api';
import { LevelType } from 'api/types';
import { LessonEntity } from 'api/lesson/types';
import { ComponentType, QuestionType } from 'api/quiz/types';
import { Question } from './Question';
import { messages } from '../messages';

interface LessonProps {
  editHeaderTitle: string;
  level: LevelType;
  lesson: LessonEntity;
  disableEditing: boolean;
  ordinalNumber: number;
  regenerationVersion?: number;
}

export const Lesson = ({
  editHeaderTitle,
  lesson,
  level,
  disableEditing,
  ordinalNumber,
  regenerationVersion,
}: LessonProps) => {
  const { id, name, content, createdAt, updatedAt, updatedByUserName } = lesson;
  const { kbId } = useParams();
  const [isContentShown, setIsContentShown] = useState(true);

  const toggleShowingContent = () => {
    setIsContentShown((prev) => !prev);
  };
  const { open, showModal, hideModal } = useModal();

  const { data } = useQuestionsListQuery({
    knowledgeBaseId: kbId!,
    componentId: id,
    componentType: ComponentType.LESSON,
    questionType: `${QuestionType.ILE_ESSAY},${QuestionType.OPEN},${QuestionType.MULTIPLE}`,
    level,
    regenerationVersion,
  });

  const questions = data?.questions;

  const isLessonUpdated = Boolean(updatedByUserName);
  const date = isLessonUpdated
    ? messages.updatedAt(convertDateFormat(updatedAt, DAY_FORMAT), updatedByUserName)
    : messages.createdAt(convertDateFormat(createdAt, DAY_FORMAT));

  const leftSide = (
    <Typography.Text
      ellipsis={{ tooltip: date }}
      className="text-xs italic mr-2.5"
    >
      {date}
    </Typography.Text>
  );

  return (
    <div
      id={id}
      className="p-4 rounded-lg border border-solid border-custom-natural-gray-5"
    >
      <div className="px-2 flex items-center justify-between bg-white">
        <div>
          <Typography.Text className="font-bold text-xs mr-2.5">
            {messages.lessonOrdinalNumber(ordinalNumber)}
          </Typography.Text>
          {isLessonUpdated && <Tag color="blue">{messages.updated}</Tag>}
        </div>
        <Button
          onClick={toggleShowingContent}
          className="p-0 [&_span]:underline"
          type="link"
        >
          {isContentShown ? messages.hideQuestions : messages.showQuestions}
        </Button>
      </div>

      <div>
        <div className="flex flex-col w-full my-4">
          <Typography.Text className="text-base font-bold">{compileMarkdownToJSX(name)}</Typography.Text>
          <Typography.Text>{compileMarkdownToJSX(content)}</Typography.Text>
          <div className="w-full flex justify-between items-center">
            {leftSide}
            <Button
              size="small"
              type="primary"
              onClick={showModal}
              disabled={disableEditing}
            >
              {messages.editLesson}
            </Button>
          </div>
        </div>
        {!!questions?.length && (
          <>
            <Divider className="my-2.5" />
            <Typography.Text className="font-bold text-xs">{messages.questions}</Typography.Text>
            <div className={`${isContentShown ? 'block' : 'hidden'}`}>
              {questions.map((question) => (
                <Question
                  key={question.questionId}
                  editHeaderTitle={editHeaderTitle}
                  disableEditing={disableEditing}
                  question={question}
                />
              ))}
            </div>
          </>
        )}
        <ManageLessonModal
          leftSide={leftSide}
          editHeaderTitle={editHeaderTitle}
          open={open}
          hide={hideModal}
          lesson={lesson}
        />
      </div>
    </div>
  );
};
