import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { FileUploadingException } from './exceptions';

class FileUploader {
  private httpClient: AxiosInstance;

  private isUploading = false;

  private error?: Error;

  constructor() {
    this.httpClient = axios.create();
  }

  public async uploadFile(url: string, file: File, config: AxiosRequestConfig): Promise<void> {
    try {
      this.isUploading = true;

      await this.httpClient.put(url, file, config);

      this.isUploading = false;
    } catch (err) {
      this.isUploading = false;
      this.error = err as Error;

      throw new FileUploadingException('Uploading has been failed');
    }
  }

  public getIsUploading(): boolean {
    return this.isUploading;
  }

  public getError(): Error | void {
    return this.error;
  }
}

export const fileUploader = new FileUploader();
