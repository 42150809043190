import { useKnowledgeBaseListQuery } from 'api/knowledge-base/knowledge-base.api';
import { StatusType } from 'api/knowledge-base/types';

export const usePublishedKbList = () => {
  const { data: { knowledgeBases = [] } = {}, isFetching: isKnowledgeBasesLoading } = useKnowledgeBaseListQuery({
    status: StatusType.PUBLISHED,
  });

  return {
    knowledgeBases,
    isKnowledgeBasesLoading,
  };
};
