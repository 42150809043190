import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { Spinner } from '@tml-component-library/src';
import { UserAuthoritiesEntity } from 'api/user/types';
import { useUser } from 'common/user/useUser';
import { useAuth } from 'common/auth';
import { Routes } from '../routes';

export const ProtectedRoute = ({ userRole }: any) => {
  const { data: { metadata } = {}, isFetching } = useUser();
  const { isEnabledAccessToCompany } = useAuth();
  const { pathname } = useLocation();

  if (isFetching) return <Spinner />;

  const isLoggedInAsCustomerAdmin =
    metadata?.authorities?.includes(UserAuthoritiesEntity.ROLE_STAFF) && isEnabledAccessToCompany;

  /*
   * Redirect to Admin panel in case of using Go Back browser button when trying to return to Staff panel
   */
  const shouldBeRedirected = isLoggedInAsCustomerAdmin && pathname.includes(Routes.staff());
  if (shouldBeRedirected) return <Navigate to={Routes.admin()} />;

  if (isLoggedInAsCustomerAdmin) return <Outlet />;

  if (metadata?.authorities?.includes(userRole)) return <Outlet />;

  return (
    <Navigate
      to={Routes.root()}
      replace
    />
  );
};
