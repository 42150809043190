import { useCallback, useEffect, useState } from 'react';
import { Modal, RadioGroup } from '@tml-component-library/src';
import {
  useLazyCourseTemplateModuleDetailsQuery,
  useLazyCourseTemplateTopicDetailsQuery,
} from 'api/course-template/course-template.api';
import { ManageKbDocumentsModalFooter } from './components';
import { ManageKbDocumentsTab, TAB_ITEMS } from './constants';
import { messages } from './messages';
import { DocumentsTab } from './DocumentsTab';
import { SupplementsTab } from './SupplementsTab';
import { ManageKbDocumentProvider } from './ManageKbDocumentProvider';

interface ManageKbDocumentsModalProps {
  open: boolean;
  hideModal: () => void;
  topicId: string;
}

export const ManageKbDocumentsModal = ({ open, hideModal, topicId }: ManageKbDocumentsModalProps) => {
  const [activeTab, setActiveTab] = useState(ManageKbDocumentsTab.DOCUMENTS);
  const [fetchCourseTemplateTopicDetailsQuery, { data: topicDetailsData }] = useLazyCourseTemplateTopicDetailsQuery();
  const [fetchCourseTemplateModuleListQuery, { data: moduleDetailsData }] = useLazyCourseTemplateModuleDetailsQuery();

  const fetchCourseTemplateTopicModuleDetails = useCallback(async () => {
    if (!topicId) return;

    const topicDetailsResponse = await fetchCourseTemplateTopicDetailsQuery({ id: topicId }).unwrap();
    await fetchCourseTemplateModuleListQuery({ id: topicDetailsResponse.moduleId });
  }, [topicId, fetchCourseTemplateTopicDetailsQuery, fetchCourseTemplateModuleListQuery]);

  const changeActiveTab = (tab: ManageKbDocumentsTab) => setActiveTab(tab);

  useEffect(() => {
    fetchCourseTemplateTopicModuleDetails();
  }, [fetchCourseTemplateTopicModuleDetails]);

  return (
    <Modal
      title={messages.manageDocuments}
      open={open}
      width={652}
      className="py-5 px-6"
      onCancel={hideModal}
      footer={<ManageKbDocumentsModalFooter hideModal={hideModal} />}
      destroyOnClose
    >
      <div className="flex flex-col gap-2.5">
        <div>
          {moduleDetailsData?.name} - {topicDetailsData?.name}
        </div>
        <RadioGroup
          defaultValue={activeTab}
          options={TAB_ITEMS}
          onChange={changeActiveTab}
        />

        <ManageKbDocumentProvider
          topicId={topicId}
          topicData={topicDetailsData}
          moduleData={moduleDetailsData}
        >
          {activeTab === ManageKbDocumentsTab.DOCUMENTS && <DocumentsTab />}
          {activeTab === ManageKbDocumentsTab.SUPPLEMENTS && <SupplementsTab />}
        </ManageKbDocumentProvider>
      </div>
    </Modal>
  );
};
