import { useCallback, useMemo } from 'react';
import { CustomFormProvider, useToast } from '@tml-component-library/src';
import { FormRequiredMark } from 'components/FormRequiredMark';
import { useEditRoleSettingMutation } from 'api/course-template/course-template.api';
import { QuestionType } from 'api/quiz/types';
import { QuizSettingEntity } from 'api/course-template/types';
import { splitRoleSettings } from 'utils';
import { ManageRoleSettingsForm } from './ManageRoleSettingsForm';
import { useManageRoleSettingsContext } from '../ManageRoleSettingsContext';
import { manageRoleSettingsValidationSchema } from '../validation.schema';
import { messages } from '../messages';

interface ManageRoleSettingsValues {
  [QuestionType.OPEN]: number;
  [QuestionType.MULTIPLE]: number;
  interactiveLearningExercise: QuizSettingEntity[];
}

export const ManageRoleSettings = () => {
  const { roleSetting, endEditing, onSubmit: handleSubmit } = useManageRoleSettingsContext();
  const { quizSettings } = roleSetting;
  const { openSetting, multipleSetting, ILESetting } = splitRoleSettings(quizSettings);

  const [editRoleSetting, { isLoading }] = useEditRoleSettingMutation();

  const { showSuccess } = useToast();

  const initialValues = useMemo(
    () => ({
      [QuestionType.OPEN]: openSetting.numberOfQuestions,
      [QuestionType.MULTIPLE]: multipleSetting.numberOfQuestions,
      interactiveLearningExercise: ILESetting,
    }),
    [ILESetting, multipleSetting, openSetting]
  );

  const onSubmit = useCallback(
    async (values: ManageRoleSettingsValues) => {
      const request = {
        ...roleSetting,
        quizSettings: [
          { ...openSetting, numberOfQuestions: values[QuestionType.OPEN] },
          { ...multipleSetting, numberOfQuestions: values[QuestionType.MULTIPLE] },
          ...values.interactiveLearningExercise
            .filter(({ requirement }) => Boolean(requirement))
            .map((exercise) => ({
              ...exercise,
              questionType: QuestionType.ILE_ESSAY,
            })),
        ],
      };

      await editRoleSetting(request).unwrap();
      if (handleSubmit) await handleSubmit();

      showSuccess(messages.settingSuccessfullyUpdated);

      endEditing();
    },
    [roleSetting, openSetting, multipleSetting, endEditing, editRoleSetting, handleSubmit, showSuccess]
  );

  return (
    <CustomFormProvider
      onSubmit={onSubmit}
      validationSchema={manageRoleSettingsValidationSchema}
      initialValues={initialValues}
      requiredMark={FormRequiredMark}
    >
      <ManageRoleSettingsForm isLoading={isLoading} />
    </CustomFormProvider>
  );
};
