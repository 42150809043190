import { Space, Typography } from 'antd';
import { ReactNode } from 'react';

interface Props {
  text: string;
  icon: ReactNode;
}

export const LegendItem = ({ text, icon }: Props) => {
  return (
    <Space
      size={10}
      align="start"
    >
      {icon}
      <Typography.Text>{text}</Typography.Text>
    </Space>
  );
};
