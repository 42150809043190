import { ReactNode } from 'react';

export interface NavigationItem {
  key: string;
  label: string;
  icon: ReactNode;
}

export const getCurrentRootOption = (items: NavigationItem[], option: string) =>
  items.find(({ key }) => option === key);

export const getCurrentOptionFromNestedPath = (items: NavigationItem[], path: string) =>
  items.find(({ key }) => path.includes(key)) || items[0];
