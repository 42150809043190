import { green, blue } from '@ant-design/colors';

export const colors = {
  'neutral-gray-1': '#F0F0F0',
  'natural-gray-3': '#f5f5f5',
  'natural-gray-5': '#d9d9d9',
  'black-transparent': 'rgba(255, 255, 255, 0.65)',
  'neutral-gray-icon': 'rgba(0, 0, 0, 0.45)',
  'neutral-fill-gray': '#fafafa',
  'base-blue-1': '#E6F4FF',
  'base-blue-6': '#1677FF',
  'base-green-6': '#237804',
  'black-transparent-45': '#00000073',
  'blue-primary': '#1677FF',
  'green-5': green[5],
  'blue-7': blue[6],
  'blue-1': blue[0],
  'red-1': '#D9363E',
};
