import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import { QuestionListResponse, QuestionListRequest, QuestionEntity, UpdateQuestionRequest } from './types';

export const quizApi = createApi({
  reducerPath: 'quiz',
  tagTypes: ['questionList', 'questionDetails'],
  baseQuery: axiosQuery({ baseUrl: '/quiz/questions' }),
  endpoints: (builder) => ({
    questionsList: builder.query<QuestionListResponse, QuestionListRequest>({
      query: ({ knowledgeBaseId, ...params }) => ({
        url: `/${knowledgeBaseId}`,
        params: { ...DEFAULT_LIST_PARAMS, ...params },
      }),
      providesTags: ['questionList'],
    }),

    updateQuestionDetails: builder.mutation<QuestionEntity, UpdateQuestionRequest>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['questionDetails', 'questionList'],
    }),

    deleteQuestion: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['questionList'],
    }),
  }),
});

export const { useQuestionsListQuery, useDeleteQuestionMutation, useUpdateQuestionDetailsMutation } = quizApi;
