import { Typography } from 'antd';
import { Button, useModal, messages as sharedMessages } from '@tml-component-library/src';
import { useCompanyDetailsQuery } from 'api/company/company.api';
import { useUser } from 'common/user/useUser';
import { ManageCompanyStudentLearningDetails } from '../manage-company-student-learning-details';
import { messages } from './messages';

export const CompanyStudentLearningDetails = () => {
  const { open, showModal, hideModal } = useModal();
  const { data: userData } = useUser();
  const { data: companyDetailsData, isFetching } = useCompanyDetailsQuery(userData!.companyId);

  const internalName = companyDetailsData?.details?.internalName || '-';
  const domain = companyDetailsData?.details?.domain || '-';

  return (
    <>
      <div className="flex flex-col gap-4">
        <Typography.Title
          level={4}
          className="!font-normal"
        >
          {messages.studentCompanyDetails}
        </Typography.Title>

        <div className="flex flex-col gap-4">
          <div>
            <Typography.Text className="text-base !font-medium">{messages.internalCompanyName}</Typography.Text>
            <Typography.Paragraph className="text-base">{internalName}</Typography.Paragraph>
          </div>

          <div>
            <Typography.Text className="text-base !font-medium">{messages.domainIndustry}</Typography.Text>
            <Typography.Paragraph className="text-base">{domain}</Typography.Paragraph>
          </div>
        </div>

        <div className="flex justify-end">
          <Button
            disabled={isFetching}
            onClick={showModal}
          >
            {companyDetailsData ? sharedMessages.edit : sharedMessages.add}
          </Button>
        </div>
      </div>

      <ManageCompanyStudentLearningDetails
        data={companyDetailsData!}
        hideModal={hideModal}
        open={open}
      />
    </>
  );
};
