import { TableProps, Typography } from 'antd';
import { ProductEntity } from 'api/product/types';
import { Button, messages as sharedMessages } from '@tml-component-library/src';
import { messages } from '../messages';

export interface ProductListActions {
  onEdit: (value: ProductEntity) => void;
}

export const getColumnConfig = (actions: ProductListActions): TableProps<ProductEntity>['columns'] => [
  {
    title: messages.productName,
    dataIndex: 'name',
    width: '40%',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (_, { name }) => <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>,
  },
  {
    title: messages.description,
    dataIndex: 'description',
    width: '40%',
    sorter: (a, b) => a.description.localeCompare(b.description),
    render: (_, { description }) => (
      <Typography.Text ellipsis={{ tooltip: description }}>{description}</Typography.Text>
    ),
  },
  {
    title: messages.action,
    width: '20%',
    render: (_, product) => (
      <Button
        type="link"
        className="pl-0"
        onClick={() => actions.onEdit(product)}
      >
        {sharedMessages.edit}
      </Button>
    ),
  },
];
