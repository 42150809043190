import { Typography } from 'antd';
import { useState } from 'react';
import { EditorState } from 'draft-js';
// @ts-expect-error: No declaration file for module
import { Editor } from 'medium-draft';
import { convertToEditorValue, convertToMarkdownValue } from './utils';
import { TOOLBAR_CONFIG, BLOCK_BUTTONS } from './constants';
import './styles/MarkdownEditor.styles.css';

interface MarkdownEditorProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}

export const MarkdownEditor = ({
  id,
  value = '',
  onChange,
  placeholder,
}: Partial<MarkdownEditorProps>) => {
  const [editorState, setEditorState] = useState(convertToEditorValue(value));

  const handleChange = (state: EditorState) => {
    setEditorState(state);
    onChange?.(convertToMarkdownValue(state));
  };

  return (
    /* Wrapping to typography to achieve applying styles for text blocks */
    <Typography.Text>
      <Editor
        id={id}
        placeholder={placeholder}
        editorState={editorState}
        toolbarConfig={TOOLBAR_CONFIG}
        blockButtons={BLOCK_BUTTONS}
        onChange={handleChange}
        sideButtons={[]} /* For hiding left menu toolbar */
      />
    </Typography.Text>
  );
};
