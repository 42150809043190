import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import {
  CreateFileRequest,
  FileEntity,
  FileListRequest,
  FileListResponse,
  FileUploadUrlResponse,
  UpdateFileRequest,
} from './types';

export const fileApi = createApi({
  reducerPath: 'file',
  tagTypes: ['fileList', 'fileDetails'],
  baseQuery: axiosQuery({ baseUrl: '/file' }),
  endpoints: (builder) => ({
    fileList: builder.query<FileListResponse, FileListRequest>({
      query: (params) => ({
        url: '/list',
        params: { ...DEFAULT_LIST_PARAMS, ...params },
      }),
      providesTags: ['fileList'],
    }),

    createFile: builder.mutation<FileEntity[], CreateFileRequest>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['fileList', 'fileDetails'],
    }),

    updateFile: builder.mutation<FileEntity[], UpdateFileRequest>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['fileList', 'fileDetails'],
    }),

    fileDetails: builder.query<FileEntity, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ['fileDetails'],
    }),

    getFilesUploadUrl: builder.mutation<FileUploadUrlResponse, void>({
      query: (params) => ({
        url: '/upload-url',
        method: 'GET',
        params,
      }),
    }),

    deleteFile: builder.mutation<void, string>({
      query: (fileId) => ({
        url: `/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['fileList', 'fileDetails'],
    }),
  }),
});

export const {
  useFileListQuery,
  useCreateFileMutation,
  useUpdateFileMutation,
  useGetFilesUploadUrlMutation,
  useDeleteFileMutation,
} = fileApi;
