import { useCallback, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { CustomFormProvider, useToast } from '@tml-component-library/src';
import { FormRequiredMark } from 'components/FormRequiredMark';
import { ManageModuleTopicFields } from './constants';
import { manageModuleTopicValidationSchema } from './validation.schema';
import { ManageModuleTopicForm } from './components/ManageModuleTopicForm';

interface ManageModuleTopicValues {
  [ManageModuleTopicFields.NAME]?: string;
  [ManageModuleTopicFields.DESCRIPTION]?: string;
}

interface ManageModuleTopicProps<TData extends ManageModuleTopicValues> {
  message: string;
  isLoading: boolean;
  data: TData;
  onEdit: (data: TData) => Promise<void>;
  onCancel: () => void;
}

export const ManageModuleTopic = <TData extends ManageModuleTopicValues>({
  onEdit,
  message,
  isLoading,
  data,
  onCancel,
}: ManageModuleTopicProps<TData>) => {
  const { showSuccess, showError } = useToast();

  const initialValues: ManageModuleTopicValues = useMemo(
    () => ({
      [ManageModuleTopicFields.NAME]: data?.name,
      [ManageModuleTopicFields.DESCRIPTION]: data?.description,
    }),
    [data]
  );

  const onSubmit = useCallback(
    async (values: ManageModuleTopicValues) => {
      try {
        await onEdit({ ...data, ...values });

        showSuccess(message);
      } catch (error) {
        showError((error as AxiosResponse).data.error_message);
      }

      onCancel();
    },
    [onCancel, onEdit, data, showSuccess, message, showError]
  );

  return (
    <CustomFormProvider
      onSubmit={onSubmit}
      validationSchema={manageModuleTopicValidationSchema}
      initialValues={initialValues}
      requiredMark={FormRequiredMark}
    >
      <ManageModuleTopicForm
        isLoading={isLoading}
        onCancel={onCancel}
      />
    </CustomFormProvider>
  );
};
