import { Divider } from 'antd';
import { useEffect } from 'react';
import { DefaultOptionType } from 'antd/es/select';
import {
  CustomFormInputLabel,
  FormField,
  messages as sharedMessages,
  Select,
  TextInput,
  UploadDragger,
  UploadDraggerOnChangeHandler,
  UploadedList,
  useFormContext,
} from '@tml-component-library/src';
import { useSelectCourse, useSelectDepartment } from 'hooks';
import { ConfirmationDescription } from 'components/ConfirmationDescription';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { messages as commonMessages } from 'shared/messages';
import { useSelectTopic } from '../hooks';
import { ACCEPTED_FILE_FORMATS, ManageDocumentFields } from '../constants';
import { MANAGE_DOCUMENT_FIELD_LABELS, MANAGE_DOCUMENT_FIELD_PLACEHOLDERS, messages } from '../messages';

interface ManageDocumentFormProps {
  onCancel: () => void;
  documentName?: string;
  onDelete?: () => Promise<void>;
}

export const ManageDocumentForm = ({ onCancel, onDelete, documentName }: ManageDocumentFormProps) => {
  const { values, setFieldValue, isSubmitting, submit, initialValues } = useFormContext();

  const { isLoading: isDepartmentsLoading, options: departmentsOptions } = useSelectDepartment();
  const { fetchCourseList, isCoursesLoading, coursesOptions } = useSelectCourse();
  const { isFetching: isTopicListLoading, fetchModuleList, topicOptions } = useSelectTopic();

  useEffect(() => {
    const departmentValue = initialValues[ManageDocumentFields.DEPARTMENT];
    const courseValue = initialValues[ManageDocumentFields.COURSE];

    if (departmentValue) fetchCourseList(departmentValue);
    if (courseValue) fetchModuleList(courseValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeFile: UploadDraggerOnChangeHandler = ({ fileList }) => {
    const value = fileList[0]?.originFileObj;
    setFieldValue(ManageDocumentFields.UPLOAD_DOCUMENT, value);
  };

  const onChangeDepartment = (value: string) => {
    fetchCourseList(value);
    if (values[ManageDocumentFields.DEPARTMENT]) setFieldValue(ManageDocumentFields.DEPARTMENT, value);
    if (values[ManageDocumentFields.COURSE]) setFieldValue(ManageDocumentFields.COURSE, undefined);
    if (values[ManageDocumentFields.MODULE]) setFieldValue(ManageDocumentFields.MODULE, undefined);
    if (values[ManageDocumentFields.TOPIC]) setFieldValue(ManageDocumentFields.TOPIC, undefined);
  };

  const onChangeCourse = (value: string) => {
    fetchModuleList(value);
    setFieldValue(ManageDocumentFields.COURSE, value);
    if (values[ManageDocumentFields.MODULE]) setFieldValue(ManageDocumentFields.MODULE, undefined);
    if (values[ManageDocumentFields.TOPIC]) setFieldValue(ManageDocumentFields.TOPIC, undefined);
  };

  const onChangeTopic = (value: string, topicOption: DefaultOptionType) => {
    setFieldValue(ManageDocumentFields.TOPIC, value);
    setFieldValue(ManageDocumentFields.MODULE, topicOption.moduleId);
  };

  const isThereInitialFileValue =
    values[ManageDocumentFields.UPLOAD_DOCUMENT] && typeof values[ManageDocumentFields.UPLOAD_DOCUMENT] === 'string';

  const fileName = typeof values.document === 'string' ? values.document : (values.document as File)?.name;

  const departmentSelectOptions = departmentsOptions || [
    {
      label: messages.select,
      value: initialValues[ManageDocumentFields.DEPARTMENT],
    },
  ];
  const courseSelectOptions = coursesOptions || [
    {
      label: messages.select,
      value: initialValues[ManageDocumentFields.COURSE],
    },
  ];
  const topicSelectOptions = topicOptions?.map((option) => ({
    label: option.moduleName,
    key: option.moduleId,
    title: option.moduleName,
    options: option.topics,
  })) || [
    {
      label: '',
      key: '',
      title: '',
      options: [
        {
          moduleId: initialValues[ManageDocumentFields.MODULE],
          value: initialValues[ManageDocumentFields.TOPIC],
          label: messages.select,
        },
      ],
    },
  ];

  const isSelectDepartmentDisabled = isSubmitting || !departmentsOptions?.length || isDepartmentsLoading;
  const isSelectCourseDisabled =
    isSubmitting ||
    !values[ManageDocumentFields.DEPARTMENT] ||
    !coursesOptions?.length ||
    isCoursesLoading ||
    isDepartmentsLoading;
  const isSelectTopicDisabled =
    isSubmitting ||
    !values[ManageDocumentFields.COURSE] ||
    !topicOptions?.length ||
    isTopicListLoading ||
    isCoursesLoading ||
    isDepartmentsLoading;

  const deleteConfirmationProps = {
    title: sharedMessages.confirmDeletion,
    description: <ConfirmationDescription description={commonMessages.deleteDocument(documentName)} />,
    okText: sharedMessages.delete,
    cancelText: sharedMessages.cancel,
  };

  return (
    <div>
      {/* invisible field to register Module field, without this value of field can't be stored  */}
      <FormField
        className="hidden"
        name={ManageDocumentFields.MODULE}
      />

      <div className="flex flex-col gap-2.5">
        <div className="flex flex-row gap-2.5">
          <FormField
            name={ManageDocumentFields.FILE_NAME}
            className="w-full m-0"
            label={MANAGE_DOCUMENT_FIELD_LABELS[ManageDocumentFields.FILE_NAME]}
            required
          >
            <TextInput
              placeholder={MANAGE_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.FILE_NAME]}
              required
            />
          </FormField>

          <FormField
            name={ManageDocumentFields.FILE_VERSION}
            className="w-full m-0"
            label={MANAGE_DOCUMENT_FIELD_LABELS[ManageDocumentFields.FILE_VERSION]}
            required
          >
            <TextInput placeholder={MANAGE_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.FILE_VERSION]} />
          </FormField>
        </div>

        <FormField
          name={ManageDocumentFields.DESCRIPTION}
          className="col-span-full m-0"
          label={MANAGE_DOCUMENT_FIELD_LABELS[ManageDocumentFields.DESCRIPTION]}
          required
        >
          <TextInput.TextArea
            rows={4}
            placeholder={MANAGE_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.DESCRIPTION]}
          />
        </FormField>

        <div className="flex gap-2.5">
          <FormField
            className="w-full m-0"
            name={ManageDocumentFields.DEPARTMENT}
            label={MANAGE_DOCUMENT_FIELD_LABELS[ManageDocumentFields.DEPARTMENT]}
            required
          >
            <Select
              placeholder={MANAGE_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.DEPARTMENT]}
              loading={isDepartmentsLoading}
              disabled={isSelectDepartmentDisabled}
              options={departmentSelectOptions}
              onChange={onChangeDepartment}
            />
          </FormField>

          <FormField
            className="w-full m-0"
            name={ManageDocumentFields.COURSE}
            label={MANAGE_DOCUMENT_FIELD_LABELS[ManageDocumentFields.COURSE]}
            required
          >
            <Select
              placeholder={MANAGE_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.COURSE]}
              loading={isCoursesLoading}
              disabled={isSelectCourseDisabled}
              options={courseSelectOptions}
              onChange={onChangeCourse}
            />
          </FormField>
        </div>

        <FormField
          name={ManageDocumentFields.TOPIC}
          label={MANAGE_DOCUMENT_FIELD_LABELS[ManageDocumentFields.TOPIC]}
          required
        >
          <Select
            placeholder={MANAGE_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.TOPIC]}
            loading={isTopicListLoading || isCoursesLoading}
            disabled={isSelectTopicDisabled}
            options={topicSelectOptions}
            onChange={onChangeTopic}
          />
        </FormField>
      </div>

      <FormField
        name={ManageDocumentFields.UPLOAD_DOCUMENT}
        label={MANAGE_DOCUMENT_FIELD_LABELS[ManageDocumentFields.UPLOAD_DOCUMENT]}
        {...(!isThereInitialFileValue ? { required: true } : {})}
      >
        {!isThereInitialFileValue ? (
          <UploadDragger
            uploadProps={{
              beforeUpload: () => false,
              multiple: false,
              maxCount: 1,
              accept: ACCEPTED_FILE_FORMATS.join(','),
              onChange: onChangeFile,
              withCredentials: true,
            }}
          />
        ) : (
          <UploadedList name={fileName} />
        )}
      </FormField>

      <Divider className="my-2.5" />

      <CustomFormInputLabel
        content={messages.requiredFields}
        required
      />

      <div className="mt-3">
        <ManageEntityModalFooter
          deleteConfirmationProps={deleteConfirmationProps}
          actionDisabled={isSubmitting}
          onDelete={onDelete}
          onCancel={onCancel}
          onSave={submit}
          editSaveLoading={isSubmitting}
        />
      </div>
    </div>
  );
};
