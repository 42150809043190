import { QuestionType } from 'api/quiz/types';
import { messages } from '../messages';

export const QuizRequirementLabels = {
  [QuestionType.OPEN]: messages.open,
  [QuestionType.MULTIPLE]: messages.multiple,
};

export const ManageRoleSettingsFields = {
  [QuestionType.OPEN]: QuestionType.OPEN,
  [QuestionType.MULTIPLE]: QuestionType.MULTIPLE,
  ILE_ESSAY: 'interactiveLearningExercise',
};

export const AMOUNT_QUESTION_OPTIONS = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
];
