import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RadioGroup } from '@tml-component-library/src';
import { useLazyRoleSettingDetailsQuery } from 'api/course-template/course-template.api';
import { CourseTemplateRoleEntity, RoleSettingEntity } from 'api/course-template/types';
import { ComponentType } from 'api/quiz/types';
import { ManageRoleSettingsEditableView } from './ManageRoleSettingsEditableView';
import { messages } from './messages';

interface CourseTemplateComponentRoleSettingsTabsProps {
  componentType: ComponentType;
  isStaff?: boolean;
  roleSettings: RoleSettingEntity[];
  roles: CourseTemplateRoleEntity[];
}

export const RoleSettingsTabs = ({
  isStaff,
  componentType,
  roles,
  roleSettings,
}: CourseTemplateComponentRoleSettingsTabsProps) => {
  const { templateId } = useParams();

  const [getRoleSettingDetails, { data: roleSetting, isFetching }] = useLazyRoleSettingDetailsQuery();

  const [currentRoleId, setCurrentRoleId] = useState<string>(roles[0].id);

  const handleCurrentRoleChange = (value: string) => {
    setCurrentRoleId(value);
  };

  const items = roles.map(({ id: value, titleAbbreviated: label }) => ({
    value,
    label,
  }));

  const currentRoleSettingId = roleSettings.find(
    ({ courseTemplateRoleId: settingsRoleId }) => settingsRoleId === currentRoleId
  )?.id;

  useEffect(() => {
    if (currentRoleSettingId) getRoleSettingDetails(currentRoleSettingId);
  }, [currentRoleSettingId, getRoleSettingDetails]);

  return (
    <>
      <Typography.Title
        level={5}
        className="!font-medium"
      >
        {messages.requirementsForStudentRoles}
      </Typography.Title>

      <RadioGroup
        defaultValue={currentRoleId}
        options={items}
        onChange={handleCurrentRoleChange}
      />

      {roleSetting && (
        <ManageRoleSettingsEditableView
          courseTemplateId={templateId!}
          isStaff={Boolean(isStaff)}
          componentType={componentType}
          roleSetting={roleSetting}
          isLoading={isFetching}
        />
      )}
    </>
  );
};
