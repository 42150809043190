import { useState } from 'react';
import { Button, Table } from '@tml-component-library/src/components';
import { useModal } from '@tml-component-library/src';
import { ProductEntity } from 'api/product/types';
import { useProductListQuery } from 'api/product/product.api';
import { messages } from './messages';
import { getColumnConfig } from './configs/tableConfig';
import { ManageCompanyProduct } from '../manage-company-product/ManageCompanyProduct';

export const CompanyProductList = () => {
  const { data: productListData, isFetching } = useProductListQuery();

  const [product, setProduct] = useState<ProductEntity>();
  const { open, showModal, hideModal } = useModal();

  const onEdit = (value: ProductEntity) => {
    setProduct(value);
    showModal();
  };

  const onHideModal = () => {
    setProduct(undefined);
    hideModal();
  };

  return (
    <div className="h-full w-full flex flex-col overflow-auto">
      <Table
        loading={isFetching}
        columns={getColumnConfig({ onEdit })}
        dataSource={isFetching ? [] : productListData?.products}
        rowKey="id"
        sticky
        pagination={false}
      />

      <Button
        className="w-full my-4"
        type="primary"
        onClick={showModal}
      >
        {messages.addProduct}
      </Button>

      <ManageCompanyProduct
        open={open}
        hideModal={onHideModal}
        data={product}
      />
    </div>
  );
};
