import { ManageStudentFields } from './constants';

export const messages = {
  createNewStudent: 'Create New Student',
  editStudent: 'Edit Student',
  requiredFields: 'Required fields',
  resendLink: 'Resend Invite',
  firstName: 'First Name',
  lastName: 'Last Name',
  department: 'Department',
  phoneNumber: 'Phone Number',
  secondPhoneNumber: 'Secondary Phone Number',
  startDate: 'Start Date',
  studentRole: 'Student Role',
  email: 'Email',
  studentResume: 'Student Resume',
  linkedInUrl: 'LinkedIn',

  enterFirstName: 'Enter First Name',
  enterLastName: 'Enter Last Name',
  enterPhoneNumber: 'Enter Phone Number',
  enterSecondPhoneNumber: 'Enter Phone Number',
  enterEmail: 'Enter Email Address',
  enterStartDate: 'Select date',
  select: 'Select',
  enterLinkedinUrl: 'Enter LinkedIn Profile URL (https://linkedin.com/in/username)',
  resumeUpload: 'Resume Upload',
  replace: 'Replace',
  removeCourseToEdit: 'De-assign active courses to edit',

  none: 'none',
  availableCourses: 'Available Courses',
  courseAssignment: 'Course Assignment',
  version: 'Version',
  assigned: 'Assigned',
  created: 'Created',
  assign: 'Assign',
  close: 'Close',

  knowledgeBaseName: (name: string, version: number, role?: string) => `${name} V${version} ${role}`,

  deleteStudent: (name?: string) => `Delete the student “${name}”`,
};

export const MANAGE_ADMIN_FIELD_LABELS = {
  [ManageStudentFields.FIRST_NAME]: messages.firstName,
  [ManageStudentFields.LAST_NAME]: messages.lastName,
  [ManageStudentFields.PHONE_NUMBER]: messages.phoneNumber,
  [ManageStudentFields.SECONDARY_PHONE_NUMBER]: messages.secondPhoneNumber,
  [ManageStudentFields.EMAIL]: messages.email,
  [ManageStudentFields.START_DATE]: messages.startDate,
  [ManageStudentFields.STUDENT_ROLE]: messages.studentRole,
  [ManageStudentFields.DEPARTMENT]: messages.department,
  [ManageStudentFields.CV]: messages.resumeUpload,
  [ManageStudentFields.LINKEDIN_URL]: messages.linkedInUrl,
};

export const MANAGE_ADMIN_FIELD_PLACEHOLDERS = {
  [ManageStudentFields.FIRST_NAME]: messages.enterFirstName,
  [ManageStudentFields.LAST_NAME]: messages.enterLastName,
  [ManageStudentFields.PHONE_NUMBER]: messages.enterPhoneNumber,
  [ManageStudentFields.SECONDARY_PHONE_NUMBER]: messages.enterPhoneNumber,
  [ManageStudentFields.EMAIL]: messages.enterEmail,
  [ManageStudentFields.STUDENT_ROLE]: messages.select,
  [ManageStudentFields.DEPARTMENT]: messages.select,
  [ManageStudentFields.START_DATE]: messages.enterStartDate,
  [ManageStudentFields.LINKEDIN_URL]: messages.enterLinkedinUrl,
};
