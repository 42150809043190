import { useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import {
  useLazyCourseTemplateModuleDetailsQuery,
  useLazyCourseTemplateTopicDetailsQuery,
  useUpdateCourseTemplateModuleDetailsMutation,
  useUpdateCourseTemplateTopicDetailsMutation,
} from 'api/course-template/course-template.api';
import { ComponentType } from 'api/quiz/types';
import { CourseTemplateModuleEntity, CourseTemplateTopicEntity } from 'api/course-template/types';
import { ManageModuleTopic } from './ManageModuleTopic';
import { ModuleTopicView } from './components/ModuleTopicView';
import { messages } from './messages';

export interface ModuleTopicProps {
  id: string;
  componentType: ComponentType;
  staff?: boolean;
  onEdit?: (...args: any[]) => void;
}

export const ModuleTopic = ({ staff = false, id, componentType, onEdit }: ModuleTopicProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const [getTopic, { data: topic, isLoading: isTopicLoading }] = useLazyCourseTemplateTopicDetailsQuery();
  const [getModule, { data: module, isLoading: isModuleLoading }] = useLazyCourseTemplateModuleDetailsQuery();

  const [editModule, { isLoading: isUpdateModuleLoading }] = useUpdateCourseTemplateModuleDetailsMutation();
  const [editTopic, { isLoading: isUpdateTopicLoading }] = useUpdateCourseTemplateTopicDetailsMutation();

  const isModule = useMemo(
    () => componentType === ComponentType.MODULE || componentType === ComponentType.MODULE_PRODUCT,
    [componentType]
  );

  useEffect(() => {
    if (isModule) {
      getModule({ id });
      return;
    }

    getTopic({ id });
  }, [getModule, getTopic, isModule, id]);

  const startEditing = () => {
    setIsEditing(true);
    if (onEdit) onEdit(true);
  };
  const endEditing = () => {
    setIsEditing(false);
    if (onEdit) onEdit(false);
  };

  const handleEdit = async <TData,>(data: TData) => {
    if (isModule) {
      await editModule(data as CourseTemplateModuleEntity).unwrap();
      return;
    }

    await editTopic(data as CourseTemplateTopicEntity).unwrap();
  };

  const data = isModule ? module : topic;

  const ModuleTopicComponent = isEditing ? (
    <ManageModuleTopic
      isLoading={isUpdateModuleLoading || isUpdateTopicLoading}
      data={data!}
      message={messages.success(isModule)}
      onEdit={handleEdit}
      onCancel={endEditing}
    />
  ) : (
    <ModuleTopicView
      isStaff={staff}
      isModule={isModule}
      data={data}
      onEdit={startEditing}
    />
  );

  return (
    <Spin spinning={isTopicLoading || isModuleLoading}>
      <div className="w-full min-h-36">{data && ModuleTopicComponent}</div>
    </Spin>
  );
};
