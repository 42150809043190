export const DAY_TIME_FORMAT = 'MM/DD/YYYY h:mm a';

export const DAY_FORMAT = 'MM/DD/YYYY';

export enum EventType {
  REQUEST_ERROR = 'requestError',
}

export enum FileFormats {
  DOCX = 'application/msword',
  DOC = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  TXT = 'text/plain',
  PDF = 'application/pdf',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  VTT = 'text/vtt',
}

export enum FileExtensions {
  DOCX = '.docx',
  DOC = '.doc',
  TXT = '.txt',
  PDF = '.pdf',
  PPT = '.ppt',
  PPTX = '.pptx',
  VTT = '.vtt',
}

export const ACCEPTED_CV_FORMATS: string[] = [FileFormats.PDF];
