import { Typography, theme } from 'antd';
import { CSSProperties, ReactNode } from 'react';

type CustomFormInputLabelProps = {
  content?: ReactNode;
  required?: boolean;
  labelStyles?: CSSProperties;
};

export const CustomFormInputLabel = ({
  content = '',
  required = false,
  labelStyles,
}: CustomFormInputLabelProps) => {
  const {
    token: { colorError },
  } = theme.useToken();

  return (
    <div className="flex gap-2 items-center">
      {required ? (
        <>
          <span
            style={{
              color: colorError,
              fontSize: 14,
            }}
          >
            *
          </span>
          <Typography.Text style={labelStyles}>{content}</Typography.Text>
        </>
      ) : (
        <Typography.Text style={labelStyles}>{content}</Typography.Text>
      )}
    </div>
  );
};
