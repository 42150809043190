import { useParams } from 'react-router-dom';
import { useDepartmentDetailsQuery } from 'api/department/department.api';
import { useHeader } from 'hooks';
import { Routes } from 'router/routes';
import { StudentRolesList } from './StudentRolesList';
import { messages } from './messages';

interface EnhancedStudentRolesProps {
  isStaff?: boolean;
}

export const EnhancedStudentRoles = ({ isStaff }: EnhancedStudentRolesProps) => {
  const { departmentId } = useParams();

  const { data: { name: departmentName } = {} } = useDepartmentDetailsQuery({ id: departmentId! });

  useHeader(isStaff ? Routes.courseManagement() : Routes.companyDepartments(), messages.headerTitle(departmentName), [
    departmentName,
    isStaff,
  ]);

  return <StudentRolesList />;
};
