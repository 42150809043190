import { Navigate, createBrowserRouter } from 'react-router-dom';
import { LoginLayout } from '@tml-component-library/src/layouts';
import { SignInPage } from 'pages/shared/sign-in/SignInPage';
import { SignInSuccessPage } from 'pages/shared/sign-in-success/SignInSuccessPage';
import { SignInErrorPage } from 'pages/shared/sign-in-error/SignInErrorPage';
import { CompaniesPage } from 'pages/tm-staff/companies/CompaniesPage';
import { DepartmentsPage } from 'pages/tm-staff/departments/DepartmentsPage';
import { StudentRolesPage } from 'pages/tm-staff/student-roles/StudentRolesPage';
import { CourseTemplatesPage } from 'pages/tm-staff/course-templates/CourseTemplatesPage';
import { CompanyStudentRolesPage } from 'pages/company-admin/company-student-roles/CompanyStudentRolesPage';
import { CompanyDepartmentsPage } from 'pages/company-admin/company-departments/CompanyDepartmentsPage';
import { CompanyDetailsPage } from 'pages/tm-staff/company-details/CompanyDetailsPage';
import { UserManagementPage } from 'pages/company-admin/user-management/UserManagementPage';
import { KnowledgeBasesPage } from 'pages/company-admin/knowledge-bases/KnowledgeBasesPage';
import { CompanySettingsPage } from 'pages/company-admin/company-settings/CompanySettingsPage';
import { SupplementsLibraryPage } from 'pages/tm-staff/supplements-library/SupplementsLibraryPage';
import { KnowledgeBaseDetailsPage } from 'pages/company-admin/knowledge-base-details/KnowledgeBaseDetailsPage';
import { CourseTemplateDetailsPage } from 'pages/tm-staff/course-template-details/CourseTemplateDetailsPage';
import { CompanyCourseSettingsPage } from 'pages/company-admin/company-course-settings/CompanyCourseSettingsPage';
import { CoursesPage } from 'pages/tm-staff/courses/CoursesPage';
import { CompanyCoursesPage } from 'pages/company-admin/company-courses/CompanyCoursesPage';
import { StaffHome } from 'features/tm-staff/staff-home';
import { AdminHome } from 'features/company-admin/admin-home';
import { UserAuthoritiesEntity } from 'api/user/types';
import { PrivateRoute } from './components/PrivateRoute';
import { PublicRoute } from './components/PublicRoute';
import { ProtectedRoute } from './components/ProtectedRoute';
import { RedirectComponent } from './components/RedirectComponent';
import { Routes } from './routes';

export const router = createBrowserRouter([
  {
    element: <PublicRoute />,
    children: [
      {
        element: <LoginLayout />,
        children: [
          {
            path: Routes.signIn(),
            element: <SignInPage />,
          },
          {
            path: Routes.signInSuccess(),
            element: <SignInSuccessPage />,
          },
          {
            path: Routes.signInError(),
            element: <SignInErrorPage />,
          },
        ],
      },
    ],
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        element: <RedirectComponent />,
        path: Routes.root(),
      },
      {
        element: <ProtectedRoute userRole={UserAuthoritiesEntity.ROLE_STAFF} />,
        children: [
          {
            element: <StaffHome />,
            path: Routes.staff(),
            children: [
              {
                path: Routes.staff(),
                element: (
                  <Navigate
                    to={Routes.companies()}
                    replace
                  />
                ),
              },
              {
                path: Routes.companies(),
                element: <CompaniesPage />,
              },
              {
                path: Routes.companyDetails(),
                element: <CompanyDetailsPage />,
              },
              {
                path: Routes.kbSettings(),
                element: <div>kb settings</div>,
              },
              {
                path: Routes.supplements(),
                element: <SupplementsLibraryPage />,
              },
              {
                path: Routes.courses(),
                element: <CoursesPage />,
              },
              {
                path: Routes.courseManagement(),
                element: <DepartmentsPage />,
              },
              {
                path: Routes.courseTemplates(),
                element: <CourseTemplatesPage />,
              },
              {
                path: Routes.courseTemplatesDetails(),
                element: <CourseTemplateDetailsPage />,
              },
              {
                path: Routes.roles(),
                element: <StudentRolesPage />,
              },
              {
                path: Routes.manageAccess(),
                element: <div>accesss</div>,
              },
            ],
          },
        ],
      },
      {
        element: <ProtectedRoute userRole={UserAuthoritiesEntity.ROLE_ADMIN} />,
        children: [
          {
            element: <AdminHome />,
            path: Routes.admin(),
            children: [
              {
                path: Routes.admin(),
                element: (
                  <Navigate
                    to={Routes.companySettings()}
                    replace
                  />
                ),
              },
              {
                path: Routes.companyRoles(),
                element: <CompanyStudentRolesPage />,
              },
              {
                path: Routes.knowledgeBaseDetails(),
                element: <KnowledgeBaseDetailsPage />,
              },
              {
                path: Routes.knowledgeBases(),
                element: <KnowledgeBasesPage />,
              },
              {
                path: Routes.companyCourses(),
                element: <CompanyCoursesPage />,
              },
              {
                path: Routes.companyDepartments(),
                element: <CompanyDepartmentsPage />,
              },
              {
                path: Routes.companySettings(),
                element: <CompanySettingsPage />,
              },
              {
                path: Routes.companyCourseTemplatesDetails(),
                element: <CompanyCourseSettingsPage />,
              },

              {
                path: Routes.usersManagement(),
                element: <UserManagementPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
