import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CustomFormProvider, useToast } from '@tml-component-library/src';
import { useLinkComponentFileMutation } from 'api/component-file/component-file.api';
import { CreateFileEntity, FileType } from 'api/file/types';
import { useManageDocuments, useUploadFileByUrl } from 'hooks';
import { ManageKbDocumentForm } from './components';
import { ManageKbDocumentFormValues } from './types';
import { ManageKbDocumentFields } from './constants';
import { messages } from './messages';
import { manageKbDocumentValidationSchema } from './validation.schema';
import { KnowledgeBaseFileList } from '../knowledge-base-file-list';
import { useManageKbDocumentContext } from './ManageKbDocumentProvider';

export const DocumentsTab = () => {
  const { topicData } = useManageKbDocumentContext();
  const { uploadFileByUrl } = useUploadFileByUrl();
  const { createFile } = useManageDocuments();
  const [linkComponentFile] = useLinkComponentFileMutation();
  const { showSuccess } = useToast();

  const initialValues = useMemo(
    () => ({
      [ManageKbDocumentFields.FILE_NAME]: '',
      [ManageKbDocumentFields.FILE_VERSION]: '',
      [ManageKbDocumentFields.FILE_DESCRIPTION]: '',
      [ManageKbDocumentFields.UPLOAD_DOCUMENT]: null,
    }),
    []
  );

  const onSubmit = async (values: ManageKbDocumentFormValues) => {
    const uuid = uuidv4();
    const document = values[ManageKbDocumentFields.UPLOAD_DOCUMENT];

    const fileEntity: CreateFileEntity = {
      id: uuid,
      name: values[ManageKbDocumentFields.FILE_NAME],
      type: FileType.COMPANY_DOCUMENT,
      nameWithExtension: document!.name,
      version: values[ManageKbDocumentFields.FILE_VERSION],
      description: values[ManageKbDocumentFields.FILE_DESCRIPTION],
    };

    await uploadFileByUrl(uuid, document as File);

    await createFile(fileEntity);

    await linkComponentFile({
      fileId: uuid,
      componentId: topicData?.basedOnTopicId!,
      componentType: topicData?.componentType!,
    });

    showSuccess(messages.createFileSuccess);
  };

  return (
    <div className="flex flex-col gap-2.5">
      <div className="h-60 overflow-hidden">
        <KnowledgeBaseFileList
          componentId={topicData?.basedOnTopicId}
          fileType={FileType.COMPANY_DOCUMENT}
        />
      </div>

      <CustomFormProvider
        onSubmit={onSubmit}
        validationSchema={manageKbDocumentValidationSchema}
        initialValues={initialValues}
      >
        <ManageKbDocumentForm />
      </CustomFormProvider>
    </div>
  );
};
