import dayjs from 'dayjs';
import { TableProps, Typography } from 'antd';
import { commonMessages, DAY_FORMAT, Table } from '@tml-component-library/src';
import { useComponentFileListQuery } from 'api/component-file/component-file.api';
import { ComponentFileEntity } from 'api/component-file/types';
import { FileType } from 'api/file/types';

const getColumnConfig = (): TableProps<ComponentFileEntity>['columns'] => [
  {
    title: commonMessages.fileName,
    dataIndex: ['file', 'name'],
    width: '25%',
    render: (_, { file }) => <Typography.Text ellipsis={{ tooltip: file?.name }}>{file?.name}</Typography.Text>,
  },
  {
    title: commonMessages.description,
    dataIndex: ['file', 'description'],
    width: '25%',
    render: (_, { file }) => (
      <Typography.Text ellipsis={{ tooltip: file?.description }}>{file?.description}</Typography.Text>
    ),
  },
  {
    title: commonMessages.date,
    dataIndex: ['file', 'updatedAt'],
    width: '25%',
    render: (_, { file }) => dayjs(file?.updatedAt).format(DAY_FORMAT),
  },
  {
    title: commonMessages.version,
    dataIndex: ['file', 'version'],
    width: '25%',
    render: (_, { file }) => `v${file?.version}.0`,
  },
];

export const SourceModalList = () => {
  const { data: { componentFiles } = {}, isLoading } = useComponentFileListQuery({
    fileType: FileType.COMPANY_DOCUMENT,
  });

  return (
    <div className="max-h-[510px] overflow-auto">
      <Table
        loading={isLoading}
        sticky
        dataSource={componentFiles}
        rowKey="id"
        columns={getColumnConfig()}
        pagination={false}
      />
    </div>
  );
};
