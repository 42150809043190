import { ManageModuleTopicFields } from './constants';

export const messages = {
  success: (isModule: boolean) => `${isModule ? 'Module' : 'Topic'} description has been successfully updated`,
};

export const MANAGE_MODULE_TOPIC_FIELDS_LABELS = {
  [ManageModuleTopicFields.NAME]: 'Title',
  [ManageModuleTopicFields.DESCRIPTION]: 'Description',
};

export const MANAGE_MODULE_TOPIC_FIELDS_PLACEHOLDERS = {
  [ManageModuleTopicFields.NAME]: 'Enter Title',
  [ManageModuleTopicFields.DESCRIPTION]: 'Enter Description',
};
