import { FileFormats } from '@tml-component-library/src';

export enum ManageDocumentFields {
  FILE_NAME = 'fileNameWithExtension',
  FILE_VERSION = 'version',
  DESCRIPTION = 'description',
  DEPARTMENT = 'relatedDepartments',
  COURSE = 'course',
  TOPIC = 'topic',
  MODULE = 'module',
  UPLOAD_DOCUMENT = 'document',
}

export const ACCEPTED_FILE_FORMATS: string[] = [
  FileFormats.PDF,
  FileFormats.DOC,
  FileFormats.DOCX,
  FileFormats.TXT,
  FileFormats.PPTX,
];
