import { Typography } from 'antd';
import { Button } from '@tml-component-library/src/components';
import { DefaultCompanyLogo } from '@tml-component-library/src/icons';
import { convertDateFormat } from '@tml-component-library/src/utils/convertDateFormat';
import { DAY_FORMAT } from '@tml-component-library/src/shared/constants';
import { CompanyEntity } from 'api/company/types';
import { messages } from './messages';
import { Statuses } from '../Statuses';

interface CompanyProfileProps extends Partial<CompanyEntity> {
  disabledEdit: boolean;
  onEditClick?: () => void;
}

export const CompanyProfile = ({
  details,
  tmAccountManager,
  status,
  logo,
  subscriptionStartDate,
  disabledEdit,
  onEditClick,
}: CompanyProfileProps) => {
  return (
    <div className="flex gap-6">
      <div className="flex flex-col justify-center items-center">
        <img
          className="rounded-lg"
          src={logo?.downloadLink || DefaultCompanyLogo}
          alt={messages.defaultCompanyLogo}
          width={150}
          height="auto"
        />
      </div>
      <div className="flex flex-1 gap-6">
        <div className="flex flex-1 flex-col gap-1">
          <Typography.Text className="text-xl">{details?.name}</Typography.Text>
          <Typography.Text className="text-xs">{details?.companyUrl}</Typography.Text>
          <Typography.Text className="text-xs mb-2.5">{details?.linkedInUrl}</Typography.Text>
          {tmAccountManager && (
            <>
              <Typography.Text className="text-xs font-bold">{messages.tmAccountManager}</Typography.Text>
              <Typography.Text className="text-xs">{tmAccountManager}</Typography.Text>
            </>
          )}
        </div>
        <div className="flex flex-1 flex-col gap-1">
          {status && (
            <div className="flex items-center gap-2.5 mb-2.5">
              <Typography.Text className="text-xs font-bold">{messages.status}</Typography.Text>
              <Statuses status={status} />
            </div>
          )}
          {subscriptionStartDate && (
            <>
              <Typography.Text className="text-xs font-bold">{messages.subscriptionStartDate}</Typography.Text>
              <Typography.Text className="text-xs">
                {convertDateFormat(subscriptionStartDate, DAY_FORMAT)}
              </Typography.Text>
            </>
          )}
        </div>
        {details?.internalNotes && (
          <div className="flex flex-1 flex-col gap-1 pt-0.5">
            <Typography.Text className="text-xs font-bold">{messages.internalNotes}</Typography.Text>
            <Typography.Text className="text-xs">{details?.internalNotes}</Typography.Text>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-end">
        {onEditClick && (
          <Button
            disabled={disabledEdit}
            onClick={onEditClick}
          >
            {messages.edit}
          </Button>
        )}
      </div>
    </div>
  );
};
