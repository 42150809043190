import { FileCategory } from 'api/file/types';
import { ManageDocumentFields } from './constants';

export const messages = {
  addDocument: 'Add a Supplement Document',
  editDocument: 'Edit a Supplement Document',
  save: 'Save',
  cancel: 'Cancel',
  requiredFields: 'Required fields',
  fileName: 'File Name',
  fileVersion: 'File Version',
  fileCategory: 'File Type',
  description: 'Description',
  departments: 'Department',
  uploadDocument: 'Upload Document',
  enterFileName: 'Enter file name',
  enterFileVersion: 'Enter file version',
  enterDescription: 'Add a description',
  enterFileCategory: 'Customizable Template',
  select: 'Select',
  selectDepartment: 'Select department',
  selectCourse: 'Select course',
  selectModules: 'Select module',
  modules: 'Module',
  roles: 'Student Role',
  customizableTemplate: 'Customizable Template',
  additionalLessons: 'Additional Lessons',
  exercises: 'Exercises',
  course: 'Course',
};

export const MANAGE_SUPPLEMENT_DOCUMENT_FIELD_LABELS = {
  [ManageDocumentFields.FILE_NAME]: messages.fileName,
  [ManageDocumentFields.FILE_VERSION]: messages.fileVersion,
  [ManageDocumentFields.FILE_CATEGORY]: messages.fileCategory,
  [ManageDocumentFields.DEPARTMENTS]: messages.departments,
  [ManageDocumentFields.UPLOAD_DOCUMENT]: messages.uploadDocument,
  [ManageDocumentFields.MODULES]: messages.modules,
  [ManageDocumentFields.COURSE_TEMPLATE]: messages.course,
};

export const MANAGE_SUPPLEMENT_DOCUMENT_FIELD_PLACEHOLDERS = {
  [ManageDocumentFields.FILE_NAME]: messages.enterFileName,
  [ManageDocumentFields.FILE_VERSION]: messages.enterFileVersion,
  [ManageDocumentFields.FILE_CATEGORY]: messages.enterFileCategory,
  [ManageDocumentFields.DEPARTMENTS]: messages.select,
  [ManageDocumentFields.MODULES]: messages.select,
  [ManageDocumentFields.COURSE_TEMPLATE]: messages.select,
};

export const CATEGORY_OPTIONS = [
  {
    label: messages.customizableTemplate,
    value: FileCategory.CUSTOMIZABLE_TEMPLATE,
  },
  {
    label: messages.additionalLessons,
    value: FileCategory.ADDITIONAL_LESSONS,
  },
  {
    label: messages.exercises,
    value: FileCategory.EXERCISES,
  },
];
