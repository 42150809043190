import { Typography } from 'antd';
import { Button, messages as sharedMessages, Modal } from '@tml-component-library/src';
import { StudentRolesList } from 'features/shared/student-roles-list';
import { messages } from '../messages';

interface ManageRolesModalProps {
  open: boolean;
  hideModal: () => void;
}

export const ManageRolesModal = ({ open, hideModal }: ManageRolesModalProps) => (
  <Modal
    title={messages.studentRoles}
    width={700}
    open={open}
    footer={null}
    destroyOnClose
    onCancel={hideModal}
  >
    <div className="mb-2.5 opacity-65">
      <Typography.Text className="italic">{messages.rolesAreManaged}</Typography.Text>
    </div>

    <StudentRolesList />

    <div className="mt-3 w-full flex justify-end">
      <Button onClick={hideModal}>{sharedMessages.cancel}</Button>
    </div>
  </Modal>
);
