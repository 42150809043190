export const messages = {
  noCourses: 'No Courses',
  courseTemplatesVersions: 'Course Templates Versions',
  courseVersions: 'Open Course Versions',
  courseSettings: 'Course Settings',
  headerTitle: (name?: string) => (name ? `Courses (${name})` : 'Courses'),
  courseCreationRequirement: 'Course Creation Requirement',
  goToCompanySettings: 'Go to Company Settings',
  companyDetails: 'Company Details',
  companyDocuments: 'Company Documents',
  products: 'Products',
  pleaseEnter: 'Please enter',
  inThe: 'in the',
  companySettings: 'Company Settings',
  inOrderToProceed: 'in order to proceed with the course creation',
};
