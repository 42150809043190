import { ManageCompanyFields } from './constants';

export const messages = {
  newCompany: 'Create New Company Account',
  editCompany: 'Edit Company Account',
  save: 'Save',
  disable: 'Disable',
  cancel: 'Cancel',
  confirmDisabling: 'Confirm Disabling',
  disableCompany: (name?: string) => `Disable the company “${name}”`,

  // validation
  invalidUrl: 'Please enter a valid URL',
  requiredField: 'This field can’t be empty',
  requiredSelection: 'Please make a selection',
  maxCharacters: (count: number) => `Max ${count} characters`,
  // validation
};

export const MANAGE_COMPANY_FIELDS_LABELS = {
  [ManageCompanyFields.COMPANY_NAME]: 'Company Name',
  [ManageCompanyFields.COMPANY_URL]: 'Company URL',
  [ManageCompanyFields.LINKEDIN_URL]: 'Company LinkedIn',
  [ManageCompanyFields.TM_MANAGER]: 'TM Account Manager',
  [ManageCompanyFields.SUBSCRIPTION_START_DATE]: 'Subscription Start Date',
  [ManageCompanyFields.INTERNAL_NOTES]: 'Internal Notes',
};
