import { Spin, theme, Typography } from 'antd';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { KnowledgeBaseEntity } from 'api/knowledge-base/types';
import { AssignedKnowledgeBase } from 'api/user/types';
import { AvailableKbListItem } from './AvailableKbListItem';
import { messages } from '../messages';

type AvailableKBListProps = {
  isLoading: boolean;
  kbs: KnowledgeBaseEntity[];
  onItemClick: (kb: KnowledgeBaseEntity, roleId: string) => void;
  assignedKbs: AssignedKnowledgeBase[];
  onClose: () => void;
  userRoleId: string;
};

export const AvailableKBList = ({
  isLoading,
  kbs,
  onItemClick,
  assignedKbs,
  onClose,
  userRoleId,
}: AvailableKBListProps) => {
  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  /*
   * Add only last version from the available list and concat with assigned KBs
   */
  const preparedKbs = kbs.filter((kb, i) => i === 0 || assignedKbs.find(({ knowledgeBaseId: id }) => id === kb.id));

  const KbList = preparedKbs.length ? (
    preparedKbs.map((knowledgeBase) => {
      const assignedKb = assignedKbs.find(({ knowledgeBaseId: id }) => id === knowledgeBase.id);
      const isAssigned = Boolean(assignedKb);
      const hideAssignButton = !isAssigned && Boolean(assignedKbs.length);

      return (
        <AvailableKbListItem
          key={knowledgeBase.id}
          knowledgeBase={knowledgeBase}
          assignedKb={assignedKb}
          showAssignButton={!hideAssignButton}
          onAssign={() => onItemClick(knowledgeBase, assignedKb?.roleId!)}
          disabled={isAssigned}
          userRoleId={userRoleId}
        />
      );
    })
  ) : (
    <div className="flex gap-2">
      <Typography.Text>•</Typography.Text>
      <Typography.Text>{messages.none}</Typography.Text>
    </div>
  );

  return (
    <div
      className="mt-3"
      style={{
        borderRadius: borderRadiusLG,
      }}
    >
      <Typography.Title
        className="!text-sm !font-normal"
        level={5}
      >
        {messages.availableCourses}
      </Typography.Title>

      <Spin spinning={isLoading}>
        <div className="flex flex-col gap-2 min-h-52 my-2.5 ml-2">{!isLoading && KbList}</div>
      </Spin>

      <div className="mt-3">
        <ManageEntityModalFooter
          actionDisabled={isLoading}
          onCancel={onClose}
          cancelText={messages.close}
        />
      </div>
    </div>
  );
};
