import { LevelType } from 'api/types';
import { QuestionType } from 'api/quiz/types';
import { messages } from '../messages';

export const LevelTitles = {
  [LevelType.STANDARD]: messages.standard,
  [LevelType.PROGRESSIVE]: messages.progressive,
  [LevelType.COMPLEX]: messages.complex,
};

export const QuestionTypeColors = {
  [QuestionType.OPEN]: 'lime',
  [QuestionType.ILE_ESSAY]: 'yellow',
  [QuestionType.MULTIPLE]: 'orange',
};

export const QuestionTypeMessages = {
  [QuestionType.OPEN]: messages.openQuestion,
  [QuestionType.ILE_ESSAY]: messages.ILEQuestion,
  [QuestionType.MULTIPLE]: messages.multipleQuestion,
};

export enum Steps {
  COMPONENTS = 'COMPONENTS',
  QUIZ = 'QUIZ',
}

export enum TreeIconColors {
  FULL = 'success',
  PARTLY = 'warning',
  NO = 'error',
}
