import { TableProps, Typography } from 'antd';
import { Button, convertDateFormat, DAY_FORMAT, messages as sharedMessages } from '@tml-component-library/src';
import { CloudDownloadOutlined, DeleteOutlined } from '@tml-component-library/src/icons';
import { ConfirmationDescription } from 'components/ConfirmationDescription';
import { ComponentFileEntity } from 'api/component-file/types';
import { CATEGORY_TYPES } from 'shared/messages';
import { messages } from '../messages';
import { KnowledgeBaseFileListActions } from '../types';

export const getSupplementsConfig = (): TableProps<ComponentFileEntity>['columns'] => [
  {
    title: messages.fileName,
    dataIndex: ['file', 'name'],
    width: '25%',
    sorter: ({ file: aFile }, { file: bFile }) => aFile.name.localeCompare(bFile.name),
    render: (_, { file }) => <Typography.Text ellipsis={{ tooltip: file.name }}>{file.name}</Typography.Text>,
  },
  {
    title: messages.fileType,
    dataIndex: ['file', 'category'],
    width: '25%',
    sorter: ({ file: aFile }, { file: bFile }) => aFile.category.localeCompare(bFile.category),
    render: (_, { file }) => (
      <Typography.Text ellipsis={{ tooltip: file.category }}>{CATEGORY_TYPES[file.category]}</Typography.Text>
    ),
  },
  {
    title: messages.date,
    dataIndex: ['file', 'updatedAt'],
    width: '20%',
    render: (_, { file }) => convertDateFormat(file.updatedAt, DAY_FORMAT),
    sorter: (a, b) => a.file.updatedAt.localeCompare(b.file.updatedAt),
  },
  {
    title: messages.version,
    dataIndex: ['file', 'version'],
    width: '15%',
    sorter: ({ file: aFile }, { file: bFile }) => aFile.version.localeCompare(bFile.version),
    render: (_, { file }) => <Typography.Text ellipsis={{ tooltip: file.version }}>{file.version}</Typography.Text>,
  },
  {
    title: messages.actions,
    width: '15%',
    render: (_, { file }) => (
      <div className="flex gap-2">
        <div className="w-full ">
          <Button
            href={file.downloadLink}
            size="small"
            icon={<CloudDownloadOutlined />}
            className="w-full pl-0"
            download={file.name}
            target="_blank"
            rel="noreferrer"
          />
        </div>
      </div>
    ),
  },
];

export const getDocumentsConfig = (
  actions: KnowledgeBaseFileListActions
): TableProps<ComponentFileEntity>['columns'] => [
  {
    title: messages.fileName,
    dataIndex: ['file', 'name'],
    width: '25%',
    sorter: ({ file: aFile }, { file: bFile }) => aFile.name.localeCompare(bFile.name),
    render: (_, { file }) => <Typography.Text ellipsis={{ tooltip: file.name }}>{file.name}</Typography.Text>,
  },
  {
    title: messages.description,
    dataIndex: ['file', 'description'],
    width: '25%',
    sorter: ({ file: aFile }, { file: bFile }) => aFile.description.localeCompare(bFile.description),
    render: (_, { file }) => (
      <Typography.Text ellipsis={{ tooltip: file.description }}>{file.description}</Typography.Text>
    ),
  },
  {
    title: messages.date,
    dataIndex: ['file', 'updatedAt'],
    width: '20%',
    sorter: ({ file: aFile }, { file: bFile }) => aFile.updatedAt.localeCompare(bFile.updatedAt),
    render: (_, { file }) => convertDateFormat(file.updatedAt, DAY_FORMAT),
  },
  {
    title: messages.version,
    dataIndex: ['file', 'version'],
    width: '15%',
    sorter: ({ file: aFile }, { file: bFile }) => aFile.version.localeCompare(bFile.version),
    render: (_, { file }) => <Typography.Text ellipsis={{ tooltip: file.version }}>{file.version}</Typography.Text>,
  },
  {
    title: messages.actions,
    width: '15%',
    render: (_, { file, id }) => (
      <div className="flex gap-2">
        <div className="w-full ">
          <Button
            href={file.downloadLink}
            size="small"
            icon={<CloudDownloadOutlined />}
            className="w-full pl-0"
            download={file.name}
            target="_blank"
            rel="noreferrer"
          />
        </div>

        {actions?.onUnlinkFile && (
          <div className="w-full ">
            <Button
              size="small"
              confirmProps={{
                title: messages.deleteConfirmation,
                description: <ConfirmationDescription description={messages.deleteConfirmWarning(file.name)} />,
                okText: sharedMessages.delete,
                cancelText: sharedMessages.cancel,
              }}
              icon={<DeleteOutlined />}
              className="pl-0"
              onClick={() => actions.onUnlinkFile!(id)}
            />
          </div>
        )}
      </div>
    ),
  },
];
