import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Typography } from 'antd';
import { Routes } from 'router/routes';
import { useAuth } from 'common/auth';
import { SignInSchema } from './schema/sign-in.validation';
import { messages } from './messages';

export const SignIn = () => {
  const { signIn, signInLoading: isLoading } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [clientReady, setClientReady] = useState<boolean>(false);

  useEffect(() => setClientReady(true), []);

  const handleSubmit = async (values: { [x: string]: string }) => {
    try {
      await signIn({ email: values.email });

      navigate(Routes.signInSuccess(), {
        state: { email: values.email },
      });
    } catch (error) {
      const message = (error as AxiosResponse).data.error_message;

      form.setFields([
        {
          name: 'email',
          value: values.email,
          errors: [message],
        },
      ]);
    }
  };

  return (
    <Form
      className="mt-8"
      form={form}
      name="sign-in-form"
      onFinish={handleSubmit}
      style={{ width: 268 }}
      validateMessages={SignInSchema}
      layout="vertical"
      size="small"
    >
      <Form.Item
        className="pb-2.5 mb-0"
        name={['email']}
        rules={[{ type: 'email', required: true }]}
      >
        <Input
          className="mb-1.5"
          size="large"
          placeholder={messages.enterYourEmail}
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => {
          const disabled =
            !clientReady ||
            !form.isFieldsTouched(true) ||
            !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
            isLoading;

          return (
            <Button
              className="w-full"
              size="large"
              type="primary"
              htmlType="submit"
              disabled={disabled}
            >
              {messages.logIn}
            </Button>
          );
        }}
      </Form.Item>
      <Form.Item className="text-center">
        <NavLink
          rel="noreferrer"
          to="http://help.timeml.ai/"
          target="_blank"
        >
          <Typography.Text
            className="text-xs"
            underline
          >
            {messages.needHelp}
          </Typography.Text>
        </NavLink>
      </Form.Item>
    </Form>
  );
};
