import { useState } from 'react';
import { AlertProps, Spin, TreeProps } from 'antd';
import dayjs from 'dayjs';
import { AlertMessage, DAY_FORMAT, RadioGroup } from '@tml-component-library/src';
import { LevelType } from 'api/types';
import { ComponentType } from 'api/quiz/types';
import { StatusType } from 'api/knowledge-base/types';
import {
  CourseTemplateModuleEntity,
  CourseTemplateRoleEntity,
  CourseTemplateTopicEntity,
} from 'api/course-template/types';
import { Topics } from './Topics';
import { Lessons } from './Lessons';
import { ContentHeader } from './ContentHeader';
import { TopicFooter } from './TopicFooter';
import { CommonQuestions } from './CommonQuestions';
import { getAlertMessageProps } from '../utils';
import { LevelTitles, Steps } from '../constants';
import { messages } from '../messages';
import { ModuleFooter } from './ModuleFooter';

interface ContentProps {
  level: LevelType;
  onLevelChange: (level: LevelType) => void;
  courseTemplateId: string;
  isLoading: boolean;
  isModule: boolean;
  onSelect: TreeProps['onSelect'];
  fetchCourseTemplate: () => Promise<void>;
  regenerationVersion?: number;
  role?: CourseTemplateRoleEntity;
  knowledgeBaseName?: string;
  knowledgeBaseStatus?: StatusType;
  module?: CourseTemplateModuleEntity;
  topic?: CourseTemplateTopicEntity;
  updatedAt?: string;
}

export const Content = ({
  level,
  regenerationVersion,
  onLevelChange,
  courseTemplateId,
  isLoading,
  isModule,
  knowledgeBaseStatus,
  module,
  topic,
  role,
  fetchCourseTemplate,
  updatedAt,
  onSelect,
}: ContentProps) => {
  const [step, setStep] = useState<Steps>(Steps.COMPONENTS);

  const onStepChange = (value: Steps) => setStep(value);

  const items = [
    {
      value: Steps.COMPONENTS,
      label: isModule ? messages.topics : messages.lessons,
    },
    {
      value: Steps.QUIZ,
      label: isModule ? messages.module : messages.topic,
    },
  ];

  const updatedAtDate = updatedAt ? dayjs(updatedAt).format(DAY_FORMAT) : undefined;

  const isPublished = knowledgeBaseStatus === StatusType.PUBLISHED;
  const { showAlert, ...props } = getAlertMessageProps(knowledgeBaseStatus || StatusType.READY_PUBLISH, updatedAtDate);

  const componentId = isModule ? module?.id : topic?.id;
  const title = isModule ? module?.name : topic?.name;

  const editHeaderTitle = messages.editHeaderTitle(LevelTitles[level], module?.name, topic?.name);

  const FooterComponent = isModule ? (
    <ModuleFooter
      courseTemplateId={courseTemplateId}
      module={module}
      role={role}
      fetchCourseTemplate={fetchCourseTemplate}
    />
  ) : (
    <TopicFooter
      courseTemplateId={courseTemplateId}
      topic={topic}
      role={role}
      fetchCourseTemplate={fetchCourseTemplate}
    />
  );

  return (
    <div className="h-full flex flex-col gap-2.5 overflow-hidden">
      {showAlert && <AlertMessage {...(props as AlertProps)} />}

      <div className="relative h-full flex flex-col gap-2.5 overflow-hidden pb-4">
        <div className="flex flex-col h-full bg-custom-natural-gray-3">
          <div className="flex flex-col min-h-full h-full p-4 pb-0">
            <ContentHeader
              level={level}
              title={title}
              headerTitle={module?.name}
              onChange={onLevelChange}
            />

            <div>
              <RadioGroup
                defaultValue={step}
                options={items}
                onChange={onStepChange}
              />
            </div>
            {isLoading ? (
              <Spin
                className="flex justify-center items-center w-full h-full"
                spinning
              />
            ) : (
              <>
                {step === Steps.COMPONENTS &&
                  (isModule ? (
                    <Topics
                      topics={module?.topics}
                      onSelect={onSelect}
                      isPublished={isPublished}
                    />
                  ) : (
                    <Lessons
                      key={componentId}
                      editHeaderTitle={editHeaderTitle}
                      isPublished={isPublished}
                      level={level}
                      roleId={role?.id!}
                      topicId={topic?.id!}
                      regenerationVersion={regenerationVersion}
                    />
                  ))}

                {step === Steps.QUIZ && componentId && (
                  <CommonQuestions
                    id={componentId}
                    editHeaderTitle={editHeaderTitle}
                    isPublished={isPublished}
                    roleId={role?.id}
                    type={isModule ? ComponentType.MODULE : ComponentType.TOPIC}
                    level={level}
                    regenerationVersion={regenerationVersion}
                  />
                )}
              </>
            )}
          </div>
          {!isPublished ? FooterComponent : null}
        </div>
      </div>
    </div>
  );
};
