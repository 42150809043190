import { useParams } from 'react-router-dom';
import { CardsLayout } from 'components/CardsLayout';
import { useRoleListQuery } from 'api/role/role.api';
import { StudentRoleCard } from './components/StudentRoleCard';
import { messages } from './messages';

export const StudentRolesList = () => {
  const { departmentId } = useParams();

  const { data: { roles = [] } = {}, isLoading } = useRoleListQuery({
    departmentId: departmentId!,
  });

  const showContent = !isLoading && Boolean(roles?.length);
  const showEmpty = !isLoading && !roles?.length;

  return (
    <CardsLayout
      isLoading={isLoading}
      showEmpty={showEmpty}
      showContent={showContent}
      emptyText={messages.noRoles}
    >
      {roles.map((role) => (
        <StudentRoleCard
          key={role.id}
          role={role}
        />
      ))}
    </CardsLayout>
  );
};
