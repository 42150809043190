import { authApi } from 'api/auth/auth.api';
import { userApi } from 'api/user/user.api';
import { fileApi } from 'api/file/file.api';
import { companyApi } from 'api/company/company.api';
import { roleApi } from 'api/role/role.api';
import { departmentApi } from 'api/department/department.api';
import { courseApi } from 'api/course/course.api';
import { knowledgeBaseApi } from 'api/knowledge-base/knowledge-base.api';
import { topicApi } from 'api/topic/topic.api';
import { quizApi } from 'api/quiz/quiz.api';
import { moduleApi } from 'api/module/module.api';
import { courseTemplateApi } from 'api/course-template/course-template.api';
import { lessonApi } from 'api/lesson/lesson.api';
import { productApi } from 'api/product/product.api';
import { componentFileApi } from 'api/component-file/component-file.api';

export const apiList = [
  authApi,
  userApi,
  fileApi,
  companyApi,
  roleApi,
  departmentApi,
  courseApi,
  knowledgeBaseApi,
  topicApi,
  quizApi,
  moduleApi,
  courseTemplateApi,
  lessonApi,
  productApi,
  componentFileApi,
];

export const rootReducer = apiList.reduce(
  (reducers, { reducerPath, reducer }) => ({
    ...reducers,
    [reducerPath]: reducer,
  }),
  {}
);

export const middlewares = apiList.map(({ middleware }) => middleware);
