import {
  CustomFormInputLabel,
  FormField,
  TextInput,
  useFormContext,
  messages as sharedMessages,
} from '@tml-component-library/src';
import { ConfirmationDescription } from 'components/ConfirmationDescription';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { Divider, Typography } from 'antd';
import { messages } from '../messages';
import { ManageCompanyProductFields } from '../constants';

interface ManageCompanyProductFormProps {
  onCancel: () => void;
  warningMessage?: string;
  onDelete?: () => Promise<void>;
  productName?: string;
}

export const ManageCompanyProductForm = ({
  warningMessage,
  onCancel,
  onDelete,
  productName,
}: ManageCompanyProductFormProps) => {
  const { isSubmitting, submit } = useFormContext();

  const deleteConfirmationProps = {
    title: sharedMessages.confirmDeletion,
    description: productName ? <ConfirmationDescription description={messages.deleteProduct(productName)} /> : '',
    okText: sharedMessages.delete,
    cancelText: sharedMessages.cancel,
  };

  return (
    <div className="flex flex-col gap-2">
      {warningMessage && (
        <div className="p-2 bg-custom-natural-gray-3">
          <Typography.Paragraph className="!m-0">{warningMessage}</Typography.Paragraph>
        </div>
      )}
      <div className="flex flex-col gap-2.5">
        <FormField
          name={ManageCompanyProductFields.PRODUCT_NAME}
          label={messages.productName}
          className="w-full m-0"
          required
        >
          <TextInput
            placeholder={messages.enterProductName}
            required
          />
        </FormField>

        <FormField
          name={ManageCompanyProductFields.DESCRIPTION}
          label={messages.description}
          className="w-full m-0"
          required
        >
          <TextInput.TextArea
            placeholder={messages.enterProductDescription}
            rows={4}
            required
          />
        </FormField>
      </div>

      <Divider className="my-2.5" />

      <CustomFormInputLabel
        content={sharedMessages.requiredFields}
        required
      />

      <div className="mt-3">
        <ManageEntityModalFooter
          actionDisabled={isSubmitting}
          onCancel={onCancel}
          onDelete={onDelete}
          onSave={submit}
          deleteConfirmationProps={deleteConfirmationProps}
        />
      </div>
    </div>
  );
};
