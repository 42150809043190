import { Divider, Select } from 'antd';
import {
  Button,
  CustomFormInputLabel,
  TextInput,
  FormField,
  useFormContext,
  messages as sharedMessages,
} from '@tml-component-library/src';
import { ConfirmationDescription } from 'components/ConfirmationDescription';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { ManageAdminFields, PHONE_NUMBER_OPTIONS } from '../constants';
import { MANAGE_ADMIN_FIELD_LABELS, MANAGE_ADMIN_FIELD_PLACEHOLDERS, messages } from '../messages';

type MangeAdminFormProps = {
  isResending: boolean;
  onResendInviteLink?: () => Promise<any>;
  onCancel: () => void;
  onDelete?: () => Promise<void>;
  actionDisabled?: boolean;
  adminName?: string;
};

export const MangeAdminForm = ({
  adminName,
  onResendInviteLink,
  isResending,
  onCancel,
  onDelete,
  actionDisabled,
}: MangeAdminFormProps) => {
  const { submit } = useFormContext();

  const deleteConfirmationProps = {
    title: sharedMessages.confirmDeletion,
    description: <ConfirmationDescription description={messages.deleteAdmin(adminName)} />,
    okText: sharedMessages.delete,
    cancelText: sharedMessages.cancel,
  };

  return (
    <div>
      <div className="flex flex-col gap-2.5">
        <div className="flex flex-row gap-2.5">
          <FormField
            name={ManageAdminFields.FIRST_NAME}
            className="w-full m-0"
            label={MANAGE_ADMIN_FIELD_LABELS[ManageAdminFields.FIRST_NAME]}
            required
          >
            <TextInput
              placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageAdminFields.FIRST_NAME]}
              required
            />
          </FormField>

          <FormField
            name={ManageAdminFields.LAST_NAME}
            className="w-full m-0"
            label={MANAGE_ADMIN_FIELD_LABELS[ManageAdminFields.LAST_NAME]}
            required
          >
            <TextInput placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageAdminFields.LAST_NAME]} />
          </FormField>
        </div>

        <FormField
          name={ManageAdminFields.COMPANY_ROLE}
          className="m-0"
          label={MANAGE_ADMIN_FIELD_LABELS[ManageAdminFields.COMPANY_ROLE]}
          required
        >
          <TextInput placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageAdminFields.COMPANY_ROLE]} />
        </FormField>

        <FormField
          name={ManageAdminFields.PHONE_NUMBER}
          className="m-0"
          label={MANAGE_ADMIN_FIELD_LABELS[ManageAdminFields.PHONE_NUMBER]}
        >
          <TextInput
            addonAfter={
              <FormField
                name={ManageAdminFields.PHONE_NUMBER_TYPE}
                noStyle
              >
                <Select options={PHONE_NUMBER_OPTIONS} />
              </FormField>
            }
            placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageAdminFields.PHONE_NUMBER]}
          />
        </FormField>

        <FormField
          name={ManageAdminFields.SECONDARY_PHONE_NUMBER}
          className="m-0"
          label={MANAGE_ADMIN_FIELD_LABELS[ManageAdminFields.SECONDARY_PHONE_NUMBER]}
        >
          <TextInput
            addonAfter={
              <FormField
                name={ManageAdminFields.SECONDARY_PHONE_NUMBER_TYPE}
                noStyle
              >
                <Select options={PHONE_NUMBER_OPTIONS} />
              </FormField>
            }
            placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageAdminFields.SECONDARY_PHONE_NUMBER]}
          />
        </FormField>

        <div className="flex gap-4 justify-between items-end">
          <FormField
            name={ManageAdminFields.EMAIL}
            className="col-span-full m-0 flex-[1_0_0]"
            label={MANAGE_ADMIN_FIELD_LABELS[ManageAdminFields.EMAIL]}
            required
          >
            <TextInput placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageAdminFields.EMAIL]} />
          </FormField>
          {onResendInviteLink && (
            <div className="flex flex-col justify-end">
              <Button
                onClick={onResendInviteLink}
                disabled={isResending}
                type="link"
              >
                {messages.resendLink}
              </Button>
            </div>
          )}
        </div>
      </div>

      <Divider className="my-2.5" />

      <CustomFormInputLabel
        content={messages.requiredFields}
        required
      />

      <div className="mt-3">
        <ManageEntityModalFooter
          deleteConfirmationProps={deleteConfirmationProps}
          actionDisabled={Boolean(actionDisabled)}
          onDelete={onDelete}
          onCancel={onCancel}
          onSave={submit}
        />
      </div>
    </div>
  );
};
