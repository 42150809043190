import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form, FormProps, FormInstance } from 'antd';
import { Schema, ValidationError } from 'yup';

export type RequiredMark = (
  label: ReactNode,
  info: { required: boolean }
) => ReactNode;

interface CustomFormProviderProps<Values> {
  onSubmit: (values: Values) => Promise<any>;
  validationSchema: Schema;
  initialValues: Values;
  validateOnSubmit?: boolean;
  requiredMark?: RequiredMark;
  children?: ReactNode;
  layout?: FormProps['layout'];
}

interface FormContextValues<Values extends object> {
  values: Record<keyof Values, any>;
  submit: () => Promise<void>;
  resetErrors: () => void;
  resetForm: () => void;
  setFieldValue: (key: keyof Values, value: any) => void;
  isSubmitting: boolean;
  getFieldsValue: () => Values;
  getFieldsErrors: FormInstance['getFieldsError'];
  initialValues: Values;
}

const FormContext = createContext({} as FormContextValues<any>);

/* Main purpose of creation own form provider is to avoid dependency with antd cause form from ant is hard to customize
 so this provider allows us to put here any form library we want */
const CustomFormProvider = <Values extends object>({
  onSubmit,
  initialValues,
  validationSchema,
  validateOnSubmit = true,
  requiredMark,
  layout = 'vertical',
  children,
}: CustomFormProviderProps<Values>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm<Values>();

  const resetForm = useCallback(() => form.resetFields(), [form]);

  const resetErrors = useCallback(
    (keyName?: string | keyof Values) => {
      const values = form.getFieldsValue();

      const fieldsData = Object.keys(values).map((key) => {
        const name = key as keyof Values;
        const fieldError = form.getFieldError(key);
        const keyNameError = keyName === name ? [] : fieldError;
        const errors = keyName ? keyNameError : [];

        return {
          name,
          value: values[name],
          errors,
        };
      });

      form.setFields(fieldsData);
    },
    [form]
  );

  const handleValuesChange = (changedValues: Values) =>
    Object.keys(changedValues).forEach((name) => resetErrors(name));

  const submit = useCallback(async () => {
    setIsSubmitting(true);

    if (validateOnSubmit) resetErrors();

    const values = form.getFieldsValue();

    try {
      let submitValues = values;

      if (validateOnSubmit) {
        submitValues = await validationSchema.validate(values, {
          abortEarly: false,
        });
      }

      await onSubmit(submitValues);

      setIsSubmitting(false);
      resetForm();
    } catch (err) {
      setIsSubmitting(false);
      if (err instanceof ValidationError) {
        const fieldData = err.inner.map((error) => {
          const key = error.path as keyof Values;

          return {
            name: key,
            value: values[key as keyof Values],
            errors: [error.message],
          };
        });
        form.setFields(fieldData);
      }
    }
  }, [resetErrors, onSubmit, form, resetForm]);

  useEffect(() => {
    const initialData = Object.keys(form.getFieldsValue()).map((key) => ({
      name: key,
      value: initialValues[key as keyof Values],
      errors: [],
    }));

    form.setFields(initialData);
  }, [form, initialValues]);

  const formValues = Object.keys(initialValues).reduce((acc, key) => {
    acc[key] = Form.useWatch(key, form);

    return acc;
  }, {} as any);

  const setFieldValue = useCallback(
    (key: keyof Values, value: any) => {
      resetErrors(key);
      form.setFieldValue(key, value);
    },
    [form]
  );

  const contextValue = useMemo(
    () => ({
      values: formValues,
      submit,
      resetErrors,
      resetForm,
      initialValues,
      setFieldValue,
      isSubmitting,
      getFieldsValue: form.getFieldsValue,
      getFieldsErrors: form.getFieldsError,
    }),
    [
      formValues,
      submit,
      resetErrors,
      resetForm,
      initialValues,
      setFieldValue,
      isSubmitting,
      form,
    ]
  );

  return (
    <FormContext.Provider value={contextValue as FormContextValues<Values>}>
      <Form
        onValuesChange={handleValuesChange}
        form={form}
        requiredMark={requiredMark}
        layout={layout}
        initialValues={initialValues}
      >
        {children}
      </Form>
    </FormContext.Provider>
  );
};

const useFormContext = () => useContext(FormContext);
const FormField = Form.Item;

export { CustomFormProvider, useFormContext, FormField };
