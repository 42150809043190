import { useLoginAsMutation } from 'api/auth/auth.api';
import { useCallback, useState } from 'react';
import { LoginAsRequest } from 'api/auth/types';
import { useClearAPIsCache } from 'hooks';
import { authRepository } from '../auth.repository';

export const useLoginAs = () => {
  const { onClearApisCache } = useClearAPIsCache();
  const [loginAsMutation] = useLoginAsMutation();
  const [isEnabledAccessToCompany, setIsEnabled] = useState<boolean>(
    Boolean(authRepository.getEnableAccessToCompany())
  );

  const loginAs = useCallback(
    async (params: LoginAsRequest, access: boolean) => {
      const tokens = await loginAsMutation(params).unwrap();

      authRepository.setAuthTokens(tokens);
      authRepository.setEnableAccessToCompany(access);
      setIsEnabled(access);

      onClearApisCache();
    },
    [onClearApisCache, loginAsMutation]
  );

  const clearAccessToCompany = useCallback(() => {
    authRepository.setEnableAccessToCompany(false);
    setIsEnabled(false);
  }, [setIsEnabled]);

  return {
    loginAs,
    isEnabledAccessToCompany,
    clearAccessToCompany,
  };
};
