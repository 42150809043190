import { ReactNode } from 'react';
import { Menu, MenuProps } from 'antd';

type NavigationProps = {
  menuProps: MenuProps;
  footer: ReactNode;
  header: ReactNode;
};

const defaultProps = {
  theme: 'dark',
  mode: 'inline',
};

export const NavigationBar = ({
  menuProps,
  footer,
  header,
}: NavigationProps) => {
  return (
    <div className="h-full flex flex-col justify-between content-between">
      <div>
        <div className="p-5">{header}</div>
        <Menu {...({ ...defaultProps, ...menuProps } as MenuProps)} />
      </div>
      {footer}
    </div>
  );
};
