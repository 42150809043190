import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import { TopicEntity, TopicListRequest, TopicListResponse, TopicRequest } from './types';

export const topicApi = createApi({
  reducerPath: 'topic',
  tagTypes: ['topicList', 'topicDetails'],
  baseQuery: axiosQuery({ baseUrl: '/topic' }),
  endpoints: (builder) => ({
    topicList: builder.query<TopicListResponse, TopicListRequest>({
      query: ({ ...params }) => ({
        url: `/list`,
        params: { ...DEFAULT_LIST_PARAMS, ...params },
        method: 'GET',
      }),
    }),

    topicDetails: builder.query<TopicEntity, TopicRequest>({
      query: ({ id, ...params }) => ({
        url: `/${id}`,
        params,
      }),
      providesTags: ['topicDetails'],
    }),

    updateTopicDetails: builder.mutation<TopicEntity, TopicEntity>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['topicDetails', 'topicList'],
    }),
  }),
});

export const { useLazyTopicListQuery, useLazyTopicDetailsQuery } = topicApi;
