import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import { LessonEntity, LessonListRequest, LessonListResponse, LessonRequest } from './types';

export const lessonApi = createApi({
  reducerPath: 'lesson',
  tagTypes: ['lessonList', 'lessonDetails'],
  baseQuery: axiosQuery({ baseUrl: '/lesson' }),
  endpoints: (builder) => ({
    lessonList: builder.query<LessonListResponse, LessonListRequest>({
      query: ({ courseTemplatesTopicId, ...params }) => ({
        url: `/list/${courseTemplatesTopicId}`,
        params: { ...DEFAULT_LIST_PARAMS, ...params },
        method: 'GET',
      }),
      providesTags: ['lessonList'],
    }),

    lessonDetails: builder.query<LessonEntity, LessonRequest>({
      query: ({ id, ...params }) => ({
        url: `/${id}`,
        params,
      }),
      providesTags: ['lessonDetails'],
    }),

    updateLessonDetails: builder.mutation<LessonEntity, LessonEntity>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['lessonDetails', 'lessonList'],
    }),

    deleteLesson: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['lessonList'],
    }),
  }),
});

export const { useLessonListQuery, useUpdateLessonDetailsMutation, useDeleteLessonMutation } = lessonApi;
