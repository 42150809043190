import { Divider, Typography } from 'antd';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { QuestionType } from 'api/quiz/types';
import { splitRoleSettings } from 'utils';
import { messages } from '../messages';
import { QuizRequirementLabels } from '../constants';
import { useManageRoleSettingsContext } from '../ManageRoleSettingsContext';

export const ManageRoleSettingsView = () => {
  const { isModule, startEditing, roleSetting } = useManageRoleSettingsContext();

  const { quizSettings } = roleSetting;
  const { openSetting, multipleSetting, ILESetting } = splitRoleSettings(quizSettings);

  return (
    <>
      <div className="flex flex-col">
        <Typography.Text className="font-bold py-2.5">{messages.quizRequirements}</Typography.Text>

        {openSetting && (
          <div className="py-1">
            <Typography.Text className="pr-2.5 font-bold">{openSetting.numberOfQuestions}</Typography.Text>
            <Typography.Text>{QuizRequirementLabels[QuestionType.OPEN]}</Typography.Text>
          </div>
        )}

        {multipleSetting && (
          <div className="py-1">
            <Typography.Text className="pr-2.5 font-bold">{multipleSetting.numberOfQuestions}</Typography.Text>
            <Typography.Text>{QuizRequirementLabels[QuestionType.MULTIPLE]}</Typography.Text>
          </div>
        )}

        <Divider className="my-2" />

        {!isModule && (
          <>
            {ILESetting.map(({ id, requirement }) => (
              <div
                key={id}
                className="flex flex-col py-2"
              >
                <Typography.Text className="font-bold pb-1">{messages.interactiveLearningExercise}</Typography.Text>
                <Typography.Text>{requirement}</Typography.Text>
              </div>
            ))}

            {Boolean(ILESetting.length) && <Divider className="my-2.5" />}
          </>
        )}
      </div>

      <ManageEntityModalFooter onEdit={startEditing} />
    </>
  );
};
