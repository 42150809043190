import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import {
  LinkComponentFileRequest,
  ComponentFileEntity,
  ComponentFileListRequest,
  ComponentFileListResponse,
} from './types';

export const componentFileApi = createApi({
  reducerPath: 'component-file',
  tagTypes: ['componentFileList'],
  baseQuery: axiosQuery({ baseUrl: '/component/file' }),
  endpoints: (builder) => ({
    componentFileList: builder.query<ComponentFileListResponse, ComponentFileListRequest>({
      query: (params) => ({
        url: '/list',
        params: { ...DEFAULT_LIST_PARAMS, ...params },
      }),
      providesTags: ['componentFileList'],
    }),

    linkComponentFile: builder.mutation<ComponentFileEntity, LinkComponentFileRequest>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['componentFileList'],
    }),

    unlinkComponentFile: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['componentFileList'],
    }),
  }),
});

export const resetComponentFileListState = () => componentFileApi.util.invalidateTags(['componentFileList']);

export const { useComponentFileListQuery, useLinkComponentFileMutation, useUnlinkComponentFileMutation } =
  componentFileApi;
