import { NavLink, useParams } from 'react-router-dom';
import { Button } from '@tml-component-library/src';
import { useCourseListQuery } from 'api/course/course.api';
import { useDepartmentDetailsQuery } from 'api/department/department.api';
import { Card } from 'components/Card';
import { CardsLayout } from 'components/CardsLayout';
import { Routes } from 'router/routes';
import { useHeader } from 'hooks';
import { messages } from './messages';
import { useCourseSettings } from './hooks';

interface CoursesListProps {
  isStaff?: boolean;
}

export const CoursesList = ({ isStaff }: CoursesListProps) => {
  const { departmentId } = useParams();
  const { getClickCourseSettingsHandler, isCourseSettingsLoading, isCourseSettingsButtonDisabled } = useCourseSettings(
    departmentId!
  );

  const { data: { courses } = {}, isLoading } = useCourseListQuery({ departmentId: departmentId! });
  const { data: { name: departmentName } = {} } = useDepartmentDetailsQuery({ id: departmentId! });

  useHeader(isStaff ? Routes.courseManagement() : Routes.companyDepartments(), messages.headerTitle(departmentName), [
    departmentName,
    isStaff,
  ]);

  const showContent = !isLoading && Boolean(courses?.length);
  const showEmpty = !isLoading && !courses?.length;

  return (
    <CardsLayout
      isLoading={isLoading}
      showEmpty={showEmpty}
      showContent={showContent}
      emptyText={messages.noCourses}
    >
      {courses?.map(({ id, name, description }) => (
        <Card
          key={id}
          title={name}
          description={description}
          content={
            isStaff ? (
              <NavLink to={Routes.courseTemplates(departmentId, id)}>
                <Button
                  type="primary"
                  disabled={isLoading}
                >
                  {messages.courseTemplatesVersions}
                </Button>
              </NavLink>
            ) : (
              <>
                <Button
                  type="primary"
                  disabled={isLoading || isCourseSettingsLoading || isCourseSettingsButtonDisabled}
                  onClick={getClickCourseSettingsHandler(id)}
                >
                  {messages.courseSettings}
                </Button>

                <NavLink to={Routes.knowledgeBases(departmentId, id)}>
                  <Button
                    type="primary"
                    disabled={isLoading}
                  >
                    {messages.courseVersions}
                  </Button>
                </NavLink>
              </>
            )
          }
        />
      ))}
    </CardsLayout>
  );
};
