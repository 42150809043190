import { MouseEvent } from 'react';
import {
  Button as AntButton,
  ButtonProps as AntButtonProps,
  Popconfirm,
  PopconfirmProps,
} from 'antd';

export type ButtonProps = AntButtonProps & {
  onClick?: (e?: MouseEvent<HTMLElement>) => void | Promise<void>;
  confirmProps?: PopconfirmProps;
};

export const Button = ({
  children,
  confirmProps,
  onClick,
  ...props
}: ButtonProps) => {
  return confirmProps ? (
    <Popconfirm
      rootClassName="[&_.ant-popconfirm-buttons]:flex [&_.ant-popconfirm-buttons]:flex-row-reverse"
      {...confirmProps}
      onConfirm={onClick}
    >
      <AntButton {...props}>{children}</AntButton>
    </Popconfirm>
  ) : (
    <AntButton
      {...props}
      onClick={onClick}
    >
      {children}
    </AntButton>
  );
};
