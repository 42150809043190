import { useCallback } from 'react';
import { useCreateFileMutation, useDeleteFileMutation, useUpdateFileMutation } from 'api/file/file.api';
import { CreateFileEntity, UpdateFileRequest } from 'api/file/types';

export const useManageDocuments = () => {
  const [createFileMutation, { data: createData, isLoading: isCreating }] = useCreateFileMutation();
  const [updateFileMutation, { data: updateData, isLoading: isUpdating }] = useUpdateFileMutation();
  const [deleteFileMutation, { isLoading: isDeleting }] = useDeleteFileMutation();

  const createFile = useCallback(
    (fileEntity: Partial<CreateFileEntity>) =>
      createFileMutation({
        files: [fileEntity],
      }).unwrap(),
    [createFileMutation]
  );

  const updateFile = useCallback((body: UpdateFileRequest) => updateFileMutation(body).unwrap(), [updateFileMutation]);

  const deleteFile = useCallback((fileId: string) => deleteFileMutation(fileId).unwrap(), [deleteFileMutation]);

  return {
    createFile,
    updateFile,
    deleteFile,
    isCreating,
    isUpdating,
    isDeleting,
    deleteFileMutation,
    createData,
    updateData,
  };
};
