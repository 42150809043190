import {
  createContext,
  useCallback,
  useEffect,
  ReactNode,
  useMemo,
  useContext,
} from 'react';
import { notification } from 'antd';
import { EventType, messages } from '../../shared';

interface ToastProviderProps {
  children: ReactNode;
}

interface ToastContextValue {
  showError: (content: string) => void;
  showSuccess: (content: string) => void;
}

const ToastContext = createContext({} as ToastContextValue);

const useToast = () => useContext(ToastContext);

const ToastProvider = ({ children }: ToastProviderProps) => {
  const [notificationApi, context] = notification.useNotification({
    maxCount: 1,
  });

  const showError = useCallback(
    (content: string) =>
      notificationApi.error({
        placement: 'bottomRight',
        message: content,
      }),
    [notificationApi]
  );
  const showSuccess = useCallback(
    (content: string) =>
      notificationApi.success({
        placement: 'bottomRight',
        message: content,
      }),
    [notificationApi]
  );

  useEffect(() => {
    const listener = ((event: CustomEvent<string>) => {
      showError(event.detail || messages.somethingWentWrong);
    }) as EventListener;

    document.addEventListener(EventType.REQUEST_ERROR, listener);

    return () => {
      document.removeEventListener(EventType.REQUEST_ERROR, listener);
    };
  }, [showError]);

  const contextValue = useMemo(
    () => ({
      showError,
      showSuccess,
    }),
    [showError, showSuccess]
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {context}
      {children}
    </ToastContext.Provider>
  );
};

export { ToastProvider, useToast };
