import { Typography } from 'antd';
import React from 'react';

interface ConfirmationDescriptionProps {
  description: string;
}

/*
 * suffix - question mark for the end of description inside text area (for ...? and by default without truncating)
 * for tooltip we should additionally add question mark (because suffix avoided according impl. of Antd library)
 */
export const ConfirmationDescription = ({ description }: ConfirmationDescriptionProps) => (
  <Typography.Paragraph
    className="max-w-96"
    ellipsis={{ rows: 2, suffix: '?' }}
  >
    {description}
  </Typography.Paragraph>
);
