import { Button, ButtonProps } from '@tml-component-library/src';
import { Status } from 'api/company/types';
import { messages } from '../messages';

type ManageCompanyModalFooterProps = {
  onSave: () => void;
  onCancel: () => void;
  isLoading: boolean;
  status?: Status;
  onDisable?: () => void;
  disableConfirmationProps?: ButtonProps['confirmProps'];
};

export const ManageCompanyModalFooter = ({
  onDisable,
  onCancel,
  onSave,
  status,
  isLoading,
  disableConfirmationProps,
}: ManageCompanyModalFooterProps) => {
  return (
    <div className="flex justify-between">
      <div>
        {onDisable && (
          <Button
            confirmProps={disableConfirmationProps}
            disabled={isLoading || status === Status.INACTIVE}
            onClick={onDisable}
            danger
          >
            {messages.disable}
          </Button>
        )}
      </div>
      <div className="flex gap-2">
        <Button
          disabled={isLoading}
          onClick={onCancel}
        >
          {messages.cancel}
        </Button>
        <Button
          type="primary"
          disabled={isLoading}
          onClick={onSave}
        >
          {messages.save}
        </Button>
      </div>
    </div>
  );
};
