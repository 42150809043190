import { useEffect } from 'react';
import { Divider, Typography } from 'antd';
import {
  Button,
  CustomFormInputLabel,
  Datepicker,
  Select,
  TextInput,
  UploadDragger,
  UploadDraggerOnChangeHandler,
  UploadedList,
  useFormContext,
  FormField,
  messages as sharedMessages,
} from '@tml-component-library/src';
import { useKBStructureEntities } from 'hooks';
import { ConfirmationDescription } from 'components/ConfirmationDescription';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { ManageStudentFields, PHONE_NUMBER_OPTIONS, ACCEPTED_CV_FORMATS } from '../constants';
import { MANAGE_ADMIN_FIELD_LABELS, MANAGE_ADMIN_FIELD_PLACEHOLDERS, messages } from '../messages';

type ManageStudentFormProps = {
  isResending: boolean;
  isRoleSelectionDisabled: boolean;
  onResendInviteLink?: () => Promise<any>;
  onCancel: () => void;
  onDelete?: () => Promise<void>;
  studentName?: string;
};

export const ManageStudentForm = ({
  onResendInviteLink,
  isRoleSelectionDisabled,
  isResending,
  onCancel,
  onDelete,
  studentName,
}: ManageStudentFormProps) => {
  const { values, setFieldValue, submit, isSubmitting } = useFormContext();

  const { isFetchingDepartments, isFetchingRoles, departmentListData, rolesListData, fetchRoleList } =
    useKBStructureEntities();

  const departmentOptions =
    departmentListData?.departments.map((department) => ({
      label: department.name,
      value: department.id,
    })) || [];

  const rolesOptions = rolesListData?.roles.map((role) => ({
    label: role.titleAbbreviated,
    value: role.id,
  })) || [{ label: 'Select', value: values[ManageStudentFields.STUDENT_ROLE] }];

  useEffect(() => {
    if (!values[ManageStudentFields.DEPARTMENT]) return;

    fetchRoleList(values[ManageStudentFields.DEPARTMENT]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRoleList, values[ManageStudentFields.DEPARTMENT]]);

  const onChangeDepartment = (value: string) => {
    setFieldValue(ManageStudentFields.DEPARTMENT, value);
    /* setFieldValue triggers onChange event which triggers validation and validation message will disappear even if new value is undefined
    validationTrigger prop works unexpectedly https://github.com/ant-design/ant-design/issues/6538, so we can't show message only onSubmit,
    so we just don't set value when value of student role is falsy to avoid message disappearing */
    if (values[ManageStudentFields.STUDENT_ROLE]) {
      setFieldValue(ManageStudentFields.STUDENT_ROLE, undefined);
    }
  };

  const onAttachFile: UploadDraggerOnChangeHandler = (value) => {
    setFieldValue(ManageStudentFields.CV, value.fileList[0]?.originFileObj);
  };

  const onReplaceButtonClick = () => {
    setFieldValue(ManageStudentFields.CV, undefined);
  };

  const departmentRoleTooltip = isRoleSelectionDisabled ? messages.removeCourseToEdit : undefined;

  const isSelectRoleDisabled =
    isRoleSelectionDisabled ||
    !rolesOptions.length ||
    !values[ManageStudentFields.DEPARTMENT] ||
    isFetchingRoles ||
    isFetchingDepartments;

  const deleteConfirmationProps = {
    title: sharedMessages.confirmDeletion,
    description: <ConfirmationDescription description={messages.deleteStudent(studentName)} />,
    okText: sharedMessages.delete,
    cancelText: sharedMessages.cancel,
  };

  return (
    <div>
      <div className="flex flex-col gap-2.5">
        <div className="flex flex-row gap-2.5">
          <FormField
            name={ManageStudentFields.FIRST_NAME}
            className="w-full m-0"
            label={MANAGE_ADMIN_FIELD_LABELS[ManageStudentFields.FIRST_NAME]}
            required
          >
            <TextInput
              placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageStudentFields.FIRST_NAME]}
              required
            />
          </FormField>

          <div />

          <FormField
            name={ManageStudentFields.LAST_NAME}
            className="w-full m-0"
            label={MANAGE_ADMIN_FIELD_LABELS[ManageStudentFields.LAST_NAME]}
            required
          >
            <TextInput placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageStudentFields.LAST_NAME]} />
          </FormField>
        </div>
        <div className="flex gap-2.5">
          <FormField
            label={MANAGE_ADMIN_FIELD_LABELS[ManageStudentFields.DEPARTMENT]}
            name={ManageStudentFields.DEPARTMENT}
            className="w-full m-0"
            tooltip={departmentRoleTooltip}
            required
          >
            <Select
              disabled={!departmentOptions.length || isFetchingDepartments || isRoleSelectionDisabled}
              loading={isFetchingDepartments}
              options={departmentOptions}
              onChange={onChangeDepartment}
              placeholder={
                <Typography.Text>{MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageStudentFields.DEPARTMENT]}</Typography.Text>
              }
            />
          </FormField>

          <FormField
            label={MANAGE_ADMIN_FIELD_LABELS[ManageStudentFields.STUDENT_ROLE]}
            name={ManageStudentFields.STUDENT_ROLE}
            className="w-full m-0"
            tooltip={departmentRoleTooltip}
            required
          >
            <Select
              disabled={isSelectRoleDisabled}
              loading={isFetchingRoles}
              options={rolesOptions}
              placeholder={
                <Typography.Text>{MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageStudentFields.STUDENT_ROLE]}</Typography.Text>
              }
            />
          </FormField>

          <FormField
            name={ManageStudentFields.START_DATE}
            label={MANAGE_ADMIN_FIELD_LABELS[ManageStudentFields.START_DATE]}
            className="w-full m-0"
            required
          >
            <Datepicker
              className="w-full"
              placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageStudentFields.START_DATE]}
            />
          </FormField>
        </div>
        <FormField
          name={ManageStudentFields.PHONE_NUMBER}
          className="m-0"
          label={MANAGE_ADMIN_FIELD_LABELS[ManageStudentFields.PHONE_NUMBER]}
        >
          <TextInput
            addonAfter={
              <FormField
                name={ManageStudentFields.PHONE_NUMBER_TYPE}
                noStyle
              >
                <Select options={PHONE_NUMBER_OPTIONS} />
              </FormField>
            }
            placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageStudentFields.PHONE_NUMBER]}
          />
        </FormField>
        <FormField
          name={ManageStudentFields.SECONDARY_PHONE_NUMBER}
          className="m-0"
          label={MANAGE_ADMIN_FIELD_LABELS[ManageStudentFields.SECONDARY_PHONE_NUMBER]}
        >
          <TextInput
            addonAfter={
              <FormField
                name={ManageStudentFields.SECONDARY_PHONE_NUMBER_TYPE}
                noStyle
              >
                <Select options={PHONE_NUMBER_OPTIONS} />
              </FormField>
            }
            placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageStudentFields.SECONDARY_PHONE_NUMBER]}
          />
        </FormField>
        <div className="flex gap-4 justify-between items-end">
          <FormField
            name={ManageStudentFields.EMAIL}
            className="col-span-full m-0 flex-[1_0_0]"
            label={MANAGE_ADMIN_FIELD_LABELS[ManageStudentFields.EMAIL]}
            required
          >
            <TextInput placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageStudentFields.EMAIL]} />
          </FormField>

          {onResendInviteLink && (
            <div className="flex flex-col justify-end">
              <Button
                onClick={onResendInviteLink}
                disabled={isResending}
                type="link"
              >
                {messages.resendLink}
              </Button>
            </div>
          )}
        </div>

        <div>
          <CustomFormInputLabel
            content={messages.studentResume}
            required
          />

          <div className="p-2.5 mt-2.5 flex flex-col gap-2.5 border border-solid rounded-lg border-custom-natural-gray-5">
            <FormField
              name={ManageStudentFields.LINKEDIN_URL}
              label={MANAGE_ADMIN_FIELD_LABELS[ManageStudentFields.LINKEDIN_URL]}
            >
              <TextInput placeholder={MANAGE_ADMIN_FIELD_PLACEHOLDERS[ManageStudentFields.LINKEDIN_URL]} />
            </FormField>

            <FormField
              name={ManageStudentFields.CV}
              label={MANAGE_ADMIN_FIELD_LABELS[ManageStudentFields.CV]}
            >
              {values[ManageStudentFields.CV] && typeof values[ManageStudentFields.CV] === 'string' ? (
                <UploadedList
                  name={values[ManageStudentFields.CV] as string}
                  buttonText={messages.replace}
                  onClick={onReplaceButtonClick}
                />
              ) : (
                <UploadDragger
                  uploadProps={{
                    beforeUpload: () => false,
                    multiple: false,
                    maxCount: 1,
                    accept: ACCEPTED_CV_FORMATS.join(','),
                    onChange: onAttachFile,
                  }}
                />
              )}
            </FormField>
          </div>
        </div>
      </div>

      <Divider className="my-2.5" />

      <CustomFormInputLabel
        content={messages.requiredFields}
        required
      />

      <div className="mt-3">
        <ManageEntityModalFooter
          deleteConfirmationProps={deleteConfirmationProps}
          actionDisabled={isSubmitting}
          onDelete={onDelete}
          onCancel={onCancel}
          onSave={submit}
        />
      </div>
    </div>
  );
};
