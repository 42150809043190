import { Key } from 'react';
import { RadioChangeEvent, Tree, TreeProps, Typography } from 'antd';
import { Button, useModal } from '@tml-component-library/src';
import { CaretDownOutlined, SettingOutlined } from '@tml-component-library/src/icons';
import { CourseTemplateRoleEntity } from 'api/course-template/types';
import { StatusType } from 'api/knowledge-base/types';
import { SidebarRoles } from './SidebarRoles';
import { ManageRolesModal } from './ManageRolesModal';
import { TreeData } from '../utils';
import { messages } from '../messages';

interface SidebarProps {
  isLoading: boolean;
  treeData: TreeData;
  onTreeSelect: TreeProps['onSelect'];
  onExpand: TreeProps['onExpand'];
  onRoleChange: (event: RadioChangeEvent) => void;
  role?: CourseTemplateRoleEntity;
  roles?: CourseTemplateRoleEntity[];
  selectedKeys?: Key[];
  expandedKeys?: Key[];
  knowledgeBaseStatus?: StatusType;
}

export const Sidebar = ({
  isLoading,
  treeData,
  onTreeSelect,
  onRoleChange,
  roles,
  role,
  selectedKeys,
  expandedKeys,
  onExpand,
  knowledgeBaseStatus,
}: SidebarProps) => {
  const { showModal, open, hideModal } = useModal();
  const roleId = role?.id;

  return (
    <div className="overflow-hidden relative pb-4">
      <div className="flex flex-col h-full overflow-auto relative pt-0">
        <div className="sticky top-0 bg-white z-10">
          <div className="flex justify-between">
            <Typography.Title
              className="!font-medium"
              level={4}
            >
              {messages.studentRole}
            </Typography.Title>

            <Button
              icon={<SettingOutlined />}
              onClick={showModal}
              disabled={isLoading || knowledgeBaseStatus === StatusType.PUBLISHED}
            />
          </div>

          {roleId && (
            <SidebarRoles
              roleId={roleId}
              roles={roles}
              onChange={onRoleChange}
            />
          )}

          <Typography.Title
            className="!font-medium"
            level={4}
          >
            {messages.courseStructure}
          </Typography.Title>
        </div>

        <Tree
          key={roleId}
          showIcon
          blockNode
          showLine
          defaultExpandParent
          autoExpandParent
          switcherIcon={<CaretDownOutlined />}
          defaultSelectedKeys={selectedKeys}
          defaultExpandedKeys={expandedKeys}
          selectedKeys={selectedKeys}
          expandedKeys={expandedKeys}
          onSelect={onTreeSelect}
          onExpand={onExpand}
          treeData={treeData}
        />

        <ManageRolesModal
          open={open}
          hideModal={hideModal}
        />
      </div>
    </div>
  );
};
