import { useEffect, useState } from 'react';
import { RadioChangeEvent } from 'antd';
import { CourseTemplateRoleEntity } from 'api/course-template/types';

export const useRoles = (roles?: CourseTemplateRoleEntity[]) => {
  const [role, setRole] = useState<CourseTemplateRoleEntity>();

  useEffect(() => {
    // Init: set first role
    if (roles?.length) setRole(roles[0]);
  }, [roles, roles?.length]);

  const handleRoleChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    const selected = roles?.find(({ id }) => id === value);

    if (selected) {
      setRole(selected);
    }
  };

  return {
    role,
    onRoleChange: handleRoleChange,
  };
};
