import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { HttpStatusCode } from 'axios';
import { Button, useModal, useToast } from '@tml-component-library/src';
import { useCreateKnowledgeBaseMutation } from 'api/knowledge-base/knowledge-base.api';
import { messages } from '../messages';
import { KbCreationRequirementsModal } from './KbCreationRequirementsModal';

interface CreateKnowledgeBaseButtonProps {
  courseTemplateId?: string;
}

export const CreateKnowledgeBaseButton = ({ courseTemplateId }: CreateKnowledgeBaseButtonProps) => {
  const { courseId } = useParams();
  const [createKnowledgeBase, { isLoading }] = useCreateKnowledgeBaseMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const {
    open: openKbRequirementsModal,
    hideModal: hideoKbRequirementsModal,
    showModal: showKbRequirementsModal,
  } = useModal();
  const { showError } = useToast();

  const handleCreate = async () => {
    try {
      await createKnowledgeBase({ course: { id: courseId! }, courseTemplateId }).unwrap();
    } catch (err: any) {
      const errorStatusCode = err.data.status_code;

      if (errorStatusCode === HttpStatusCode.BadRequest) {
        showKbRequirementsModal();
        setErrorMessage(err.data.error_message);

        return;
      }

      showError(err.data.error_message);
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={handleCreate}
        disabled={isLoading}
      >
        {messages.createKnowledgeBase}
      </Button>

      <KbCreationRequirementsModal
        open={openKbRequirementsModal}
        hideModal={hideoKbRequirementsModal}
        content={errorMessage}
      />
    </>
  );
};
