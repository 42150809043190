import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import { CourseEntity, CourseListResponse, CourseListRequest } from './types';

export const courseApi = createApi({
  reducerPath: 'course',
  tagTypes: ['courseList', 'courseDetails'],
  baseQuery: axiosQuery({ baseUrl: '/course' }),
  endpoints: (builder) => ({
    courseList: builder.query<CourseListResponse, CourseListRequest>({
      query: (params) => ({
        url: '/list',
        method: 'GET',
        params: { ...DEFAULT_LIST_PARAMS, ...params },
      }),
      providesTags: ['courseList'],
    }),

    courseDetails: builder.query<CourseEntity, string>({
      query: (courseId) => ({
        url: `/${courseId}`,
        method: 'GET',
      }),
      providesTags: ['courseDetails'],
    }),
  }),
});

export const { useCourseListQuery, useLazyCourseListQuery, useCourseDetailsQuery } = courseApi;
