import { Button } from '@tml-component-library/src';
import { UserEntity } from 'api/user/types';
import { Statuses } from 'components/Statuses';
import { UserListColumns } from 'common/user-list-table';
import { messages } from '../messages';

export type CompanyAdminListActions = {
  onEdit: (userProfile?: UserEntity) => void;
};

export const getColumnConfig = (actions: CompanyAdminListActions): UserListColumns => [
  {
    title: messages.firstName,
    dataIndex: ['profile', 'firstName'],
    width: '27%',
    sorter: (a, b) => a.profile.firstName.localeCompare(b.profile.firstName),
  },
  {
    title: messages.lastName,
    dataIndex: ['profile', 'lastName'],
    width: '26%',
    sorter: (a, b) => a.profile.lastName.localeCompare(b.profile.lastName),
  },
  {
    title: messages.email,
    dataIndex: ['profile', 'email'],
    width: '26%',
    sorter: (a, b) => a.profile.email.localeCompare(b.profile.email),
  },
  {
    title: messages.status,
    dataIndex: 'status',
    width: '10%',
    render: (_, { status }) => <Statuses status={status} />,
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
  {
    title: messages.action,
    width: '11%',
    dataIndex: ['profile'],
    render: (_, user) => (
      <Button
        type="link"
        className="pl-0"
        onClick={() => actions.onEdit(user)}
      >
        {messages.edit}
      </Button>
    ),
  },
];
