import { Meta } from '@tml-component-library/src';
import { SupplementDocumentList } from 'features/tm-staff/supplement-document-list';

export const SupplementsLibraryPage = () => {
  return (
    <>
      <Meta title="Supplements Library" />

      <div className="flex flex-col max-h-full overflow-auto">
        <SupplementDocumentList />
      </div>
    </>
  );
};
