import { createContext, useContext } from 'react';
import { RoleSettingEntity } from 'api/course-template/types';

export interface ManageRoleSettingsContextValue {
  isModule: boolean;
  courseTemplateId: string;
  roleSetting: RoleSettingEntity;
  startEditing: () => void;
  endEditing: () => void;
  isStaff: boolean;
  onSubmit?: () => Promise<void>;
}

export const ManageRoleSettingsContext = createContext({} as ManageRoleSettingsContextValue);

export const useManageRoleSettingsContext = () => useContext<ManageRoleSettingsContextValue>(ManageRoleSettingsContext);
