import { useState } from 'react';
import { Typography } from 'antd';
import { Button, Table } from '@tml-component-library/src/components';
import { useComponentFileListQuery } from 'api/component-file/component-file.api';
import { FileType } from 'api/file/types';
import { ComponentFileEntity } from 'api/component-file/types';
import { useModal } from '@tml-component-library/src';
import { ManageDocumentModal } from 'features/company-admin/manage-document/ManageDocumentModal';
import { getColumnConfig } from './configs/tableConfig';
import { messages } from './messages';

interface CompanyDocumentListProps {
  showTitle?: boolean;
}

export const CompanyDocumentList = ({ showTitle = false }: CompanyDocumentListProps) => {
  const { data, isFetching } = useComponentFileListQuery(
    {
      fileType: FileType.COMPANY_DOCUMENT,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [selectedFile, setSelectedFile] = useState<ComponentFileEntity | undefined>();
  const { open, showModal, hideModal } = useModal();

  const onEdit = (file?: ComponentFileEntity) => {
    setSelectedFile(file);
    showModal();
  };

  return (
    <div className="h-full w-full flex flex-col">
      {showTitle && (
        <Typography.Title
          className="!font-medium"
          level={5}
        >
          {messages.companyDocuments}
        </Typography.Title>
      )}

      <div className="overflow-auto">
        <Table
          className="[&_.ant-table-cell-fix-right-first]:shadow-[-3px_0px_3px_0px_rgba(0,0,0,0.25)]"
          loading={isFetching}
          columns={getColumnConfig({ onEdit })}
          dataSource={data?.componentFiles || []}
          rowKey="id"
          pagination={false}
        />
      </div>

      <Button
        className="w-full my-4"
        type="primary"
        onClick={() => onEdit()}
      >
        {messages.addDocument}
      </Button>

      <ManageDocumentModal
        open={open}
        hideModal={hideModal}
        data={selectedFile}
      />
    </div>
  );
};
