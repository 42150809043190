import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import { TextInput, useFormContext, FormField, messages as sharedMessages } from '@tml-component-library/src';
import { MarkdownEditor } from '@tml-component-library/src/components/MarkdownEditor';
import { ConfirmationDescription } from 'components/ConfirmationDescription';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { ManageLessonFields } from '../constants';
import { MANAGE_LESSON_FIELDS_PLACEHOLDERS, messages } from '../messages';

interface ManageLessonFormProps {
  isLoading: boolean;
  onCancel: () => void;
  onDelete?: () => Promise<void>;
  leftSide: ReactNode;
}

export const ManageLessonForm = ({ onCancel, isLoading, onDelete, leftSide }: ManageLessonFormProps) => {
  const { isSubmitting, submit, values } = useFormContext();

  const lessonName = values[ManageLessonFields.NAME];

  const deleteConfirmationProps = {
    title: sharedMessages.confirmDeletion,
    description: lessonName ? <ConfirmationDescription description={messages.deleteLesson(lessonName)} /> : '',
    okText: sharedMessages.delete,
    cancelText: sharedMessages.cancel,
  };

  return (
    <>
      <div className="flex flex-col gap-2.5 p-4 border rounded-lg border-solid border-gray-200 mb-3 bg-custom-natural-gray-3 min-h-[400px]">
        <Typography.Text className="font-bold text-xs">{messages.lesson}</Typography.Text>
        <FormField
          name={ManageLessonFields.NAME}
          className="m-0"
        >
          <TextInput
            placeholder={MANAGE_LESSON_FIELDS_PLACEHOLDERS[ManageLessonFields.NAME]}
            required
          />
        </FormField>

        <FormField
          name={ManageLessonFields.CONTENT}
          className="m-0"
        >
          <MarkdownEditor placeholder={MANAGE_LESSON_FIELDS_PLACEHOLDERS[ManageLessonFields.CONTENT]} />
        </FormField>
      </div>
      <ManageEntityModalFooter
        onSave={submit}
        onCancel={onCancel}
        actionDisabled={isLoading || isSubmitting}
        onDelete={onDelete}
        deleteConfirmationProps={deleteConfirmationProps}
        leftSide={leftSide}
      />
    </>
  );
};
