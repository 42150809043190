import * as yup from 'yup';
import { validationMessages } from '@tml-component-library/src';
import { ManageQuestionFormField } from './constants';
import { messages } from './messages';

export const manageILEQuestionFormValidationSchema = yup.object().shape({
  [ManageQuestionFormField.QUESTION_TEXT]: yup.string().trim().required(validationMessages.requiredField),
});

export const manageOpenQuestionFormValidationSchema = yup.object().shape({
  [ManageQuestionFormField.QUESTION_TEXT]: yup.string().trim().required(validationMessages.requiredField),
  [ManageQuestionFormField.ANSWER]: yup.string().trim().required(validationMessages.requiredField),
});

export const manageMultipleQuestionFormValidationSchema = yup.object().shape({
  [ManageQuestionFormField.QUESTION_TEXT]: yup.string().trim().required(validationMessages.requiredField),
  [ManageQuestionFormField.ANSWER]: yup.string().trim().required(validationMessages.requiredField),
  [ManageQuestionFormField.ANSWER_OPTIONS]: yup.mixed().test({
    message: messages.emptyAnswerOptionsNotAllowed,
    test: (value) => {
      return Object.values(value as object).every((item) => Boolean(item));
    },
  }),
});
