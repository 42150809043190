import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { JWTTokens, LoginAsRequest, SignInRequest } from './types';

export const authApi = createApi({
  reducerPath: 'authentication',
  tagTypes: ['authentication'],
  baseQuery: axiosQuery({ baseUrl: '/authentication' }),
  endpoints: (builder) => ({
    signIn: builder.mutation<void, SignInRequest>({
      query: ({ skipNotAuthorizedInterceptor = true, ...body }: SignInRequest) => ({
        url: '/sign-in',
        method: 'POST',
        body,
        skipNotAuthorizedInterceptor,
      }),
    }),

    exchangeToken: builder.mutation<JWTTokens, void>({
      query: () => ({
        url: '/exchange-token',
        method: 'POST',
        skipNotAuthorizedInterceptor: true,
      }),
    }),

    loginAs: builder.mutation<JWTTokens, LoginAsRequest>({
      query: ({ role, ...params }) => ({
        url: `/authorize-as/${role}`,
        method: 'POST',
        params,
      }),
    }),
  }),
});

export const { useSignInMutation, useExchangeTokenMutation, useLoginAsMutation } = authApi;
