import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import { useCompanyDetailsQuery } from 'api/company/company.api';
import { UserAuthoritiesEntity } from 'api/user/types';
import { useAuth } from 'common/auth';
import { CompanyProfile } from 'components/CompanyProfile';
import { Routes } from 'router/routes';
import { Button, useModal } from '@tml-component-library/src';
import { resetRoleApiState } from 'api/role/role.api';
import { CompanyAdminList } from '../company-admin-list';
import { ManageCompanyModal } from '../manage-company/ManageCompanyModal';
import { useHeader } from '../../../hooks';
import { messages } from './messages';
import { useAppDispatch } from '../../../redux';

export const CompanyDetails = () => {
  const { companyId } = useParams();
  const { open, showModal, hideModal } = useModal();
  const { loginAs } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, isFetching } = useCompanyDetailsQuery(companyId!);

  useHeader(Routes.companies(), data?.details?.name, [data]);

  const onLoginIsAsCustomerAdmin = useCallback(async () => {
    try {
      await loginAs(
        {
          role: UserAuthoritiesEntity.ROLE_ADMIN,
          companyId: companyId!,
        },
        true
      );
      dispatch(resetRoleApiState());
      navigate(Routes.admin());
    } catch {
      /*
       * Global error handler catch error and show notification
       */
    }
  }, [companyId, dispatch, loginAs, navigate]);

  return (
    <>
      <div className="flex flex-col max-h-full gap-6">
        <CompanyProfile
          {...data}
          onEditClick={showModal}
          disabledEdit={isFetching || !data}
        />

        <div className="flex justify-between items-center align-middle">
          <Typography.Title
            className="!font-medium !m-0"
            level={5}
          >
            {messages.companyAdminUsers}
          </Typography.Title>

          <Button
            disabled={!companyId}
            onClick={onLoginIsAsCustomerAdmin}
          >
            {messages.logInAsAdmin}
          </Button>
        </div>

        <CompanyAdminList companyId={companyId} />
      </div>

      {data && (
        <ManageCompanyModal
          open={open}
          hide={hideModal}
          companyData={{ ...data! }}
        />
      )}
    </>
  );
};
