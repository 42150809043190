import { Tabs as AntTabs } from 'antd';
import { ReactNode } from 'react';

interface TabItem {
  key: string;
  label: string;
  children: ReactNode;
}

interface TabsProps {
  defaultActiveKey: string;
  items: TabItem[];
  onChange: (activeKey: string) => void;
  className?: string;
}

export const Tabs = ({
  defaultActiveKey,
  items,
  onChange,
  className,
}: TabsProps) => {
  return (
    <AntTabs
      className={className}
      defaultActiveKey={defaultActiveKey}
      items={items}
      onChange={onChange}
    />
  );
};
