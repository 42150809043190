import * as yup from 'yup';
import { ALPHANUMERIC_DOTS_AND_SPACES, ALPHANUMERIC_REGEX, validationMessages } from '@tml-component-library/src';
import { ACCEPTED_FILE_FORMATS, ManageDocumentFields } from './constants';

export const manageDocumentValidationSchema = yup.object().shape({
  [ManageDocumentFields.FILE_NAME]: yup
    .string()
    .trim()
    .matches(ALPHANUMERIC_REGEX, { message: validationMessages.invalidFileName, excludeEmptyString: true })
    .max(255, validationMessages.maxCharacters(255))
    .required(validationMessages.requiredField),
  [ManageDocumentFields.FILE_VERSION]: yup
    .string()
    .trim()
    .matches(ALPHANUMERIC_DOTS_AND_SPACES, { message: validationMessages.invalidFileVersion, excludeEmptyString: true })
    .max(16, validationMessages.minMaxCharacters(1, 16))
    .required(validationMessages.requiredField),
  [ManageDocumentFields.FILE_CATEGORY]: yup.string().trim().required(validationMessages.requiredField),
  [ManageDocumentFields.DEPARTMENTS]: yup.string().required(validationMessages.requiredField),
  [ManageDocumentFields.COURSE_TEMPLATE]: yup.string().required(validationMessages.requiredField),
  [ManageDocumentFields.MODULES]: yup.string().required(validationMessages.requiredField),
  [ManageDocumentFields.UPLOAD_DOCUMENT]: yup
    .mixed()
    .test({
      message: validationMessages.allowedDocumentFormats,
      test: (value) => {
        if (typeof value === 'string') {
          return Boolean(value);
        }

        const file = value as File;
        return ACCEPTED_FILE_FORMATS.includes(file.type);
      },
    })
    .required(validationMessages.requiredField),
});
