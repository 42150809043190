import { CompanyDetails } from 'features/tm-staff/company-details';
import { Meta } from '@tml-component-library/src/components/Meta';

export const CompanyDetailsPage = () => (
  <>
    <Meta title="Company Details" />

    <CompanyDetails />
  </>
);
