import { Meta } from '@tml-component-library/src';
import { EnhancedStudentRoles } from 'features/shared/student-roles-list';

export const StudentRolesPage = () => {
  return (
    <>
      <Meta title="Student Roles" />

      <EnhancedStudentRoles isStaff />
    </>
  );
};
