import { Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RoleSettingEntity } from 'api/course-template/types';
import { ComponentType } from 'api/quiz/types';
import { ManageRoleSettings } from './components/ManageRoleSettings';
import { ManageRoleSettingsView } from './components/ManageRoleSettingsView';
import { ManageRoleSettingsContext, ManageRoleSettingsContextValue } from './ManageRoleSettingsContext';

interface ManageRoleSettingsEditableViewProps {
  courseTemplateId: string;
  componentType: ComponentType;
  roleSetting: RoleSettingEntity;
  isLoading: boolean;
  isStaff?: boolean;
  onEdit?: (...args: any[]) => void;
  onSubmit?: () => Promise<void>;
}

export const ManageRoleSettingsEditableView = ({
  courseTemplateId,
  isStaff = false,
  componentType,
  isLoading,
  roleSetting,
  onEdit,
  onSubmit,
}: ManageRoleSettingsEditableViewProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const startEditing = useCallback(() => {
    setIsEditing(true);
    if (onEdit) onEdit(true);
  }, [onEdit]);

  const endEditing = useCallback(() => {
    setIsEditing(false);
    if (onEdit) onEdit(false);
  }, [onEdit]);

  /*
   * Remove editing everytime when open new role settings
   */
  useEffect(() => {
    setIsEditing(false);
    if (onEdit) onEdit(false);
  }, [roleSetting, onEdit]);

  const contextValue = useMemo<ManageRoleSettingsContextValue>(
    () => ({
      courseTemplateId,
      isStaff,
      isModule: componentType === ComponentType.MODULE || componentType === ComponentType.MODULE_PRODUCT,
      roleSetting,
      startEditing,
      endEditing,
      onSubmit,
    }),
    [componentType, isStaff, roleSetting, endEditing, startEditing, courseTemplateId, onSubmit]
  );

  return (
    <ManageRoleSettingsContext.Provider value={contextValue}>
      <Spin
        spinning={isLoading}
        className={`${isEditing ? 'pt-2.5' : ''}`}
      >
        {isEditing ? <ManageRoleSettings /> : <ManageRoleSettingsView />}
      </Spin>
    </ManageRoleSettingsContext.Provider>
  );
};
