export const messages = {
  fileName: 'File Name',
  description: 'Description',
  fileType: 'File Type',
  date: 'Date',
  version: 'Version',
  actions: 'Actions',
  deleteConfirmation: 'Delete Confirmation',
  deleteConfirmWarning: (name?: string) => `Delete the document “${name}”`,
  deleteSuccess: 'Document has been successfully deleted',
} as const;
