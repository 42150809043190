import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import { CreateProductRequest, ProductEntity, ProductListResponse, UpdateProductRequest } from './types';

export const productApi = createApi({
  reducerPath: 'product',
  tagTypes: ['productList'],
  baseQuery: axiosQuery({ baseUrl: '/product' }),
  endpoints: (builder) => ({
    productList: builder.query<ProductListResponse, void>({
      query: () => ({
        url: `/list`,
        params: { ...DEFAULT_LIST_PARAMS },
        method: 'GET',
      }),
      providesTags: ['productList'],
    }),

    createProduct: builder.mutation<ProductEntity, CreateProductRequest>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['productList'],
    }),

    updateProduct: builder.mutation<ProductEntity, UpdateProductRequest>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['productList'],
    }),

    deleteProduct: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['productList'],
    }),
  }),
});

export const { useCreateProductMutation, useUpdateProductMutation, useProductListQuery, useDeleteProductMutation } =
  productApi;
