import { Typography } from 'antd';
import React from 'react';
import { convertDateFormat, DAY_FORMAT } from '@tml-component-library/src';
import { messages } from './messages';

interface LastInteractionInfoProps {
  createdAt: string;
  updatedAt: string;
  updatedByUserName?: string;
}

export const LastInteractionInfo = ({ createdAt, updatedAt, updatedByUserName }: LastInteractionInfoProps) => {
  const isUpdated = Boolean(updatedByUserName);
  const lastInteractionMessage = isUpdated
    ? messages.updatedAt(convertDateFormat(updatedAt, DAY_FORMAT), updatedByUserName)
    : messages.createdAt(convertDateFormat(createdAt, DAY_FORMAT));

  return (
    <Typography.Text
      ellipsis={{ tooltip: lastInteractionMessage }}
      className="text-xs italic mr-2.5"
    >
      {lastInteractionMessage}
    </Typography.Text>
  );
};
