import { Modal } from '@tml-component-library/src';
import { useAssignKbMutation } from 'api/user/user.api';
import { UserEntity } from 'api/user/types';
import { KnowledgeBaseEntity } from 'api/knowledge-base/types';
import { AvailableKBList } from './components/AvailableKBList';
import { useAssignedKbList } from './hooks/useAssignedKbList';
import { usePublishedKbList } from './hooks/usePublishedKbList';
import { messages } from './messages';

type ManageStudentModalProps = {
  open: boolean;
  hideModal: () => void;
  user?: UserEntity;
};

export const AssignCourseModal = ({ user, open, hideModal }: ManageStudentModalProps) => {
  const [assignKbMutation] = useAssignKbMutation();

  const { assignedKbs, isAssignedKbsLoading } = useAssignedKbList(user?.id);

  const { knowledgeBases, isKnowledgeBasesLoading } = usePublishedKbList();

  const onKbListItemClick = ({ id }: KnowledgeBaseEntity) => {
    const userId = user?.id;

    if (userId) {
      assignKbMutation({
        userId,
        knowledgeBaseId: id,
      });
    }
  };

  const isLoading = isAssignedKbsLoading || isKnowledgeBasesLoading;

  return (
    <Modal
      title={messages.courseAssignment}
      open={open}
      onCancel={hideModal}
      width={520}
      footer={null}
      destroyOnClose
    >
      <AvailableKBList
        isLoading={isLoading}
        kbs={knowledgeBases}
        assignedKbs={assignedKbs}
        onItemClick={onKbListItemClick}
        onClose={hideModal}
        userRoleId={user?.role?.id || ''}
      />
    </Modal>
  );
};
