import { Upload, UploadProps } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile as UploadFileParam } from 'antd/lib';
import { InboxOutlined } from '../../icons';

type UploadDraggerProps = {
  uploadProps?: UploadProps;
};

export type UploadDraggerOnChangeHandler = (
  value: UploadChangeParam<UploadFileParam<any>>
) => void;

export const UploadDragger = ({ uploadProps }: UploadDraggerProps) => {
  return (
    <Upload.Dragger {...uploadProps}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </Upload.Dragger>
  );
};
