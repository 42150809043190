import { DepartmentList } from 'features/shared/department-list';
import { Meta } from '@tml-component-library/src/components/Meta';

export const DepartmentsPage = () => (
  <>
    <Meta title="Course Management" />

    <DepartmentList isStaff />
  </>
);
