import { Button } from '@tml-component-library/src';
import { messages } from '../messages';

type ManageRoleFooterProps = {
  isLoading?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  edit?: boolean;
};

export const ManageRoleFooter = ({ onCancel, onSave, isLoading, edit }: ManageRoleFooterProps) => {
  return (
    <div className="flex justify-end pt-2.5">
      {edit ? (
        <div className="flex gap-2">
          <Button
            disabled={isLoading}
            onClick={onCancel}
          >
            {messages.cancel}
          </Button>
          <Button
            type="primary"
            disabled={isLoading}
            onClick={onSave}
          >
            {messages.save}
          </Button>
        </div>
      ) : (
        <Button
          type="primary"
          disabled={isLoading}
          onClick={onCancel}
        >
          {messages.edit}
        </Button>
      )}
    </div>
  );
};
