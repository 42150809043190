import { Key, useEffect, useState } from 'react';
import { TreeProps, TreeDataNode } from 'antd';
import { EventDataNode } from 'antd/es/tree';

export interface DataNode extends TreeDataNode {
  isRoot: boolean;
  parentId?: string;
}

export interface SelectInfoProps {
  event: 'select';
  selected: boolean;
  node: EventDataNode<DataNode>;
  selectedNodes: DataNode[];
  nativeEvent: MouseEvent;
}

export const useTreeStructure = (defaultTreeKeys: Key[]) => {
  const [isRoot, setIsRoot] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>();
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  useEffect(() => {
    // In one time we should have 1 selected item and no ability to unselect
    // That is why it is triggered only once when we load the page (initial data is loaded)
    if (!selectedKeys?.length) {
      setSelectedKeys(defaultTreeKeys);
      setExpandedKeys(defaultTreeKeys);
    }
  }, [defaultTreeKeys, selectedKeys?.length]);

  const handleSelect: TreeProps['onSelect'] = (keys, { node }) => {
    // Cover custom fields in data
    // isRoot - to detect parent or child is selected
    const isCurrentRoot = (node as unknown as DataNode)?.isRoot;
    const parentId = (node as unknown as DataNode)?.parentId;

    // Expand parent in case of parent id exist - only for manual triggering onSelect
    if (parentId) {
      setExpandedKeys((expanded) => [...expanded, parentId]);
    }

    // Interrupt unselecting tree item
    if (keys.length) {
      setSelectedKeys(keys);
      setIsRoot(isCurrentRoot);
    }
  };

  const handleExpand: TreeProps['onExpand'] = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  };

  return {
    isRoot,
    expandedKeys,
    selectedKeys,
    onExpand: handleExpand,
    onSelect: handleSelect,
  };
};
