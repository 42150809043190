export const messages = {
  createNewAdmin: 'Create New Admin',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  status: 'Status',
  action: 'Action',
  edit: 'Edit',
  adminUsers: 'Admin Users',
};
