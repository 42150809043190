import { ReactNode, useCallback, useMemo } from 'react';
import { Typography } from 'antd';
import { AxiosResponse } from 'axios';
import { Modal, CustomFormProvider, useToast } from '@tml-component-library/src';
import { FormRequiredMark } from 'components/FormRequiredMark';
import { useDeleteLessonMutation, useUpdateLessonDetailsMutation } from 'api/lesson/lesson.api';
import { LessonEntity } from 'api/lesson/types';
import { ManageLessonFields } from './constants';
import { manageLessonValidationSchema } from './validation.schema';
import { ManageLessonForm } from './components/ManageLessonForm';
import { messages } from './messages';

interface ManageLessonValues {
  [ManageLessonFields.NAME]: string;
  [ManageLessonFields.CONTENT]: string;
}

interface ManageLessonModalProps {
  editHeaderTitle: string;
  open: boolean;
  hide: () => void;
  lesson: LessonEntity;
  leftSide: ReactNode;
}

export const ManageLessonModal = ({ editHeaderTitle, open, hide, lesson, leftSide }: ManageLessonModalProps) => {
  const [editLesson, { isLoading }] = useUpdateLessonDetailsMutation();
  const [deleteLesson, { isLoading: isDeleting }] = useDeleteLessonMutation();

  const { showSuccess, showError } = useToast();

  const initialValues: ManageLessonValues = useMemo(
    () => ({
      [ManageLessonFields.NAME]: lesson.name,
      [ManageLessonFields.CONTENT]: lesson.content,
    }),
    [lesson]
  );

  const onCancel = useCallback(() => hide(), [hide]);

  const onSubmit = useCallback(
    async (values: ManageLessonValues) => {
      try {
        await editLesson({
          ...lesson,
          name: values[ManageLessonFields.NAME],
          content: values[ManageLessonFields.CONTENT],
        }).unwrap();

        showSuccess(messages.success);
      } catch (error) {
        showError((error as AxiosResponse).data.error_message);
      }

      onCancel();
    },
    [onCancel, editLesson, lesson, showSuccess, showError]
  );

  const getDeleteLessonHandler = () => {
    if (!lesson) return;

    return async () => {
      await deleteLesson(lesson.id);
      hide();
    };
  };

  return (
    <Modal
      title={messages.editLesson}
      open={open}
      onCancel={onCancel}
      className="py-2.5 px-2.5"
      destroyOnClose
      footer={null}
      width={700}
    >
      <Typography.Text className="flex pb-2.5">{editHeaderTitle}</Typography.Text>
      <CustomFormProvider
        onSubmit={onSubmit}
        validationSchema={manageLessonValidationSchema}
        initialValues={initialValues}
        requiredMark={FormRequiredMark}
      >
        <ManageLessonForm
          isLoading={isLoading || isDeleting}
          onCancel={onCancel}
          onDelete={getDeleteLessonHandler()}
          leftSide={leftSide}
        />
      </CustomFormProvider>
    </Modal>
  );
};
