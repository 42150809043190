import { Typography, Card as AntCard } from 'antd';
import { ReactNode } from 'react';

interface CardProps {
  title: ReactNode;
  content: ReactNode;
  description?: ReactNode;
}

export const Card = ({ title, content, description }: CardProps) => (
  <div className="p-4 border rounded-lg border-solid border-gray-200 shadow-lg bg-custom-neutral-fill-gray">
    <Typography.Title
      className="!font-medium"
      level={3}
    >
      {title}
    </Typography.Title>
    {description && <Typography.Text className="flex pb-2.5">{description}</Typography.Text>}
    <AntCard
      className="text-center border-blue-300"
      classNames={{ body: 'flex justify-center items-center gap-2.5 !p-4 w-full' }}
    >
      {content}
    </AntCard>
  </div>
);
