import { useState } from 'react';
import { Button, Table } from '@tml-component-library/src/components';
import { FileType } from 'api/file/types';
import { useModal } from '@tml-component-library/src';
import { useComponentFileListQuery } from 'api/component-file/component-file.api';
import { ComponentFileEntity } from 'api/component-file/types';
import { messages } from './messages';
import { ManageSupplementDocumentModal } from '../manage-supplement-document/ManageSupplementDocumentModal';
import { getColumnConfig } from './configs/tableConfig';

export const SupplementDocumentList = () => {
  const { data, isLoading } = useComponentFileListQuery({
    fileType: FileType.TM_DOCUMENT,
  });

  const [selectedFile, setSelectedFile] = useState<ComponentFileEntity | undefined>();
  const { open, showModal, hideModal } = useModal();

  const onEdit = (file?: ComponentFileEntity) => {
    setSelectedFile(file);
    showModal();
  };

  return (
    <>
      <Table
        loading={isLoading}
        columns={getColumnConfig({ onEdit })}
        dataSource={data?.componentFiles || []}
        rowKey="id"
        sticky
        pagination={false}
      />

      <Button
        className="my-4"
        type="primary"
        onClick={() => onEdit()}
      >
        {messages.addDocument}
      </Button>

      <ManageSupplementDocumentModal
        open={open}
        hideModal={hideModal}
        data={selectedFile}
      />
    </>
  );
};
