import { ReactNode } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Spin, Typography } from 'antd';
import { Button, useModal } from '@tml-component-library/src';
import { Card } from 'components/Card';
import { StatusType } from 'api/knowledge-base/types';
import { useCourseDetailsQuery } from 'api/course/course.api';
import { Routes } from 'router/routes';
import { KnowledgeBaseCardTitle } from './KnowledgeBaseCardTitle';
import { KnowledgeBaseSourcesModal } from './KnowledgeBaseSourcesModal';
import { messages } from '../messages';

interface KnowledgeBaseCardProps {
  status: StatusType;
  title: string;
  knowledgeBaseId: string;
  isOpenDisabled: boolean;
  CreateButton: ReactNode;
  version: number;
  updatedAt?: string;
  errorMessage?: string;
  isCurrentVersion?: boolean;
}

export const KnowledgeBaseCard = ({
  status,
  title,
  knowledgeBaseId,
  CreateButton,
  isOpenDisabled,
  version,
  errorMessage,
  updatedAt,
  isCurrentVersion = false,
}: KnowledgeBaseCardProps) => {
  const { departmentId, courseId } = useParams();
  const { open, showModal, hideModal } = useModal();
  const { data: courseDetailsData } = useCourseDetailsQuery(courseId!);

  const isCreating =
    status === StatusType.CREATED ||
    status === StatusType.PREPARING_FILES ||
    status === StatusType.CREATING_KNOWLEDGE_BASE ||
    status === StatusType.READY_TO_GENERATE_QUESTIONS ||
    status === StatusType.CREATING_QUESTIONS;

  const isPublished = status === StatusType.PUBLISHED;
  const publishedAt = isPublished ? updatedAt : undefined;

  return (
    <Card
      title={
        <KnowledgeBaseCardTitle
          kbName={title}
          version={version}
          isPublished={isPublished}
          publishedAt={publishedAt}
          isCurrentVersion={isCurrentVersion}
        />
      }
      content={
        <div className="w-full flex justify-between items-center gap-2.5">
          <div className="flex flex-col gap-2.5 text-center w-full">
            {isCreating && (
              <div className="mt-8 h-10">
                <Spin
                  tip={messages.loading}
                  size="small"
                >
                  <div className="content" />
                </Spin>
              </div>
            )}

            <div className="flex justify-center gap-2.5 w-full">
              <Button
                ghost
                type="primary"
                onClick={showModal}
              >
                {messages.viewSourceMaterials}
              </Button>

              <NavLink to={Routes.knowledgeBaseDetails(departmentId, courseId, knowledgeBaseId)}>
                <Button
                  type="primary"
                  disabled={isCreating || isOpenDisabled}
                  {...((isPublished && isCurrentVersion) || !isPublished ? {} : { ghost: true })}
                >
                  {isPublished ? messages.open : messages.edit}
                </Button>
              </NavLink>

              {isPublished && CreateButton}
            </div>

            {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
          </div>

          <KnowledgeBaseSourcesModal
            open={open}
            version={version}
            courseName={courseDetailsData?.name}
            knowledgeBaseName={title}
            hide={hideModal}
            isCurrentVersion={isCurrentVersion}
            publishedAt={publishedAt}
          />
        </div>
      }
    />
  );
};
