import { useCallback, useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';
import dayjs from 'dayjs';
import { CustomFormProvider, Modal } from '@tml-component-library/src';
import { useManageUser } from 'common/user/useManageUser';
import { useResendInvite } from 'common/user/useResendInvite';
import { FormRequiredMark } from 'components/FormRequiredMark';
import { PhoneTypes, UserAuthoritiesEntity, UserEntity } from 'api/user/types';
import { useCreateFileMutation } from 'api/file/file.api';
import { FileType } from 'api/file/types';
import { useUploadFileByUrl } from 'hooks';
import { ManageStudentFields } from './constants';
import { messages } from './messages';
import { manageStudentValidationSchema } from './validation.schema';
import { ManageStudentForm } from './components/ManageStudentForm';
import { ManageStudentValues } from './types';
import { useAssignedKbList } from './hooks/useAssignedKbList';

type ManageStudentModalProps = {
  companyId: string;
  open: boolean;
  hideModal: () => void;
  user?: UserEntity;
};

export const ManageStudentModal = ({ companyId, user, open, hideModal }: ManageStudentModalProps) => {
  const { createUser, updateUser, deleteUser } = useManageUser();
  const [createFile] = useCreateFileMutation();
  const { resendInvite, isLoading: isResending } = useResendInvite();

  const { uploadFileByUrl } = useUploadFileByUrl();
  const { assignedKbs, isAssignedKbsLoading } = useAssignedKbList(user?.id);

  const initialValues: Partial<ManageStudentValues> = useMemo(
    () => ({
      [ManageStudentFields.FIRST_NAME]: user?.profile?.firstName,
      [ManageStudentFields.LAST_NAME]: user?.profile?.lastName,
      [ManageStudentFields.PHONE_NUMBER]: user?.profile?.phoneNumber,
      [ManageStudentFields.SECONDARY_PHONE_NUMBER]: user?.profile?.secondPhoneNumber,
      [ManageStudentFields.EMAIL]: user?.profile?.email,
      [ManageStudentFields.PHONE_NUMBER_TYPE]: user?.profile?.phoneNumberType || PhoneTypes.MOBILE,
      [ManageStudentFields.SECONDARY_PHONE_NUMBER_TYPE]: user?.profile?.secondPhoneNumberType || PhoneTypes.MOBILE,
      [ManageStudentFields.CV]: user?.cvFile?.name,
      [ManageStudentFields.START_DATE]: user?.profile?.startDate
        ? dayjs(user?.profile.startDate).toISOString()
        : undefined,
      [ManageStudentFields.LINKEDIN_URL]: user?.profile?.linkedInUrl,
      [ManageStudentFields.STUDENT_ROLE]: user?.role?.id,
      [ManageStudentFields.DEPARTMENT]: user?.department?.id,
    }),
    [user]
  );

  const onSubmit = useCallback(
    async (values: Partial<ManageStudentValues>) => {
      const generatedUUID = uuidV4();
      const wasCvValueChanged = typeof values[ManageStudentFields.CV] !== 'string';
      const cvValue = values[ManageStudentFields.CV];

      if (wasCvValueChanged) {
        await uploadFileByUrl(generatedUUID, cvValue as File);

        await createFile({
          files: [
            {
              id: generatedUUID,
              name: (cvValue as File).name,
              nameWithExtension: (cvValue as File).name,
              type: FileType.USER_CV,
            },
          ],
        });
      }

      const requestBody = {
        id: user?.id,
        companyId,
        department: {
          id: values[ManageStudentFields.DEPARTMENT],
        },
        role: {
          id: values[ManageStudentFields.STUDENT_ROLE],
        },
        cvFile: {
          id: wasCvValueChanged ? generatedUUID : user?.cvFile.id,
        },
        profile: {
          email: values[ManageStudentFields.EMAIL]!,
          firstName: values[ManageStudentFields.FIRST_NAME]!,
          lastName: values[ManageStudentFields.LAST_NAME]!,
          phoneNumber: values[ManageStudentFields.PHONE_NUMBER],
          phoneNumberType: values[ManageStudentFields.PHONE_NUMBER_TYPE],
          secondPhoneNumber: values[ManageStudentFields.SECONDARY_PHONE_NUMBER],
          secondPhoneNumberType: values[ManageStudentFields.SECONDARY_PHONE_NUMBER_TYPE],
          linkedInUrl: values[ManageStudentFields.LINKEDIN_URL],
          startDate: dayjs(values[ManageStudentFields.START_DATE]).toISOString(),
        },
        userRole: UserAuthoritiesEntity.ROLE_STUDENT,
      };

      if (user?.profile) {
        await updateUser(requestBody);
      } else {
        await createUser(requestBody);
      }

      hideModal();
    },
    [companyId, createFile, createUser, hideModal, updateUser, uploadFileByUrl, user]
  );

  const getResendInviteLinkHandler = () => {
    if (user) {
      return async () => {
        await resendInvite(user.status, user.profile.email);
      };
    }
  };

  const getDeleteUserHandler = () => {
    if (!user) return;

    return async () => {
      await deleteUser(user.id);
      hideModal();
    };
  };

  return (
    <Modal
      title={user?.profile ? messages.editStudent : messages.createNewStudent}
      open={open}
      onCancel={hideModal}
      width={650}
      footer={null}
      destroyOnClose
    >
      <CustomFormProvider
        onSubmit={onSubmit}
        validationSchema={manageStudentValidationSchema}
        initialValues={initialValues}
        requiredMark={FormRequiredMark}
      >
        <ManageStudentForm
          studentName={user?.profile?.firstName}
          onResendInviteLink={getResendInviteLinkHandler()}
          isResending={isResending}
          onCancel={hideModal}
          onDelete={getDeleteUserHandler()}
          isRoleSelectionDisabled={Boolean(user && (assignedKbs?.length || isAssignedKbsLoading))}
        />
      </CustomFormProvider>
    </Modal>
  );
};
