import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import {
  RoleListRequest,
  RoleListResponse,
  EditRoleResponse,
  EditRoleRequest,
  RoleEntity,
  RoleDetailsRequest,
} from './types';

export const roleApi = createApi({
  reducerPath: 'role',
  tagTypes: ['roleList', 'roleDetails'],
  baseQuery: axiosQuery({ baseUrl: '/role' }),
  endpoints: (builder) => ({
    roleList: builder.query<RoleListResponse, RoleListRequest>({
      query: (params) => ({
        url: `/list`,
        params: { ...DEFAULT_LIST_PARAMS, ...params },
        method: 'GET',
      }),
      providesTags: ['roleList'],
    }),

    roleDetails: builder.query<RoleEntity, RoleDetailsRequest>({
      query: ({ id, ...params }) => ({
        url: `/${id}`,
        params,
      }),
      providesTags: ['roleDetails'],
    }),

    editRole: builder.mutation<EditRoleResponse, EditRoleRequest>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['roleList', 'roleDetails'],
    }),
  }),
});

export const resetRoleApiState = () => roleApi.util?.resetApiState();

export const { useLazyRoleListQuery, useRoleListQuery, useEditRoleMutation, useRoleDetailsQuery } = roleApi;
