import * as yup from 'yup';
import {
  ALPHABETICAL_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX,
  ALPHANUMERIC_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX,
  HTTPS_REGEX,
  LINKEDIN_COMPANY_REGEX,
  validationMessages,
} from '@tml-component-library/src';
import { ManageCompanyFields } from './constants';

export const manageCompanyValidationSchema = yup.object().shape({
  [ManageCompanyFields.COMPANY_NAME]: yup
    .string()
    .trim()
    .matches(ALPHANUMERIC_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX, {
      message: validationMessages.alphanumericHyphensApostrophesDotsSpaces,
      excludeEmptyString: true,
    })
    .max(40, validationMessages.minMaxCharacters(1, 40))
    .required(validationMessages.requiredField),
  [ManageCompanyFields.COMPANY_URL]: yup
    .string()
    .trim()
    .lowercase()
    .test({
      message: validationMessages.invalidCompanyUrl,
      test: (value) => {
        const transformedValue = value?.toLowerCase();

        return !!transformedValue && HTTPS_REGEX.test(transformedValue);
      },
    })
    .max(255, validationMessages.maxCharacters(255))
    .required(validationMessages.requiredField),
  [ManageCompanyFields.INTERNAL_NOTES]: yup
    .string()
    .trim()
    .notRequired()
    .max(255, validationMessages.maxCharacters(255)),
  [ManageCompanyFields.LINKEDIN_URL]: yup
    .string()
    .trim()
    .lowercase()
    .test({
      message: validationMessages.invalidLinkedinCompanyUrl,
      test: (value) => {
        const transformedValue = value?.toLowerCase();

        return !!transformedValue && LINKEDIN_COMPANY_REGEX.test(transformedValue);
      },
    })
    .max(255, validationMessages.maxCharacters(255))
    .required(validationMessages.requiredField),
  [ManageCompanyFields.SUBSCRIPTION_START_DATE]: yup.date().notRequired(),
  [ManageCompanyFields.TM_MANAGER]: yup
    .string()
    .trim()
    .notRequired()
    .matches(ALPHABETICAL_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX, {
      message: validationMessages.alphabeticalHyphensApostrophesDotsSpaces,
      excludeEmptyString: true,
    })
    .max(80, validationMessages.maxCharacters(80)),
});
