export const UPLOAD_FILE_REQUEST_CONFIG = {
  headers: (fileType: string) => ({
    'Access-Control-Allow-Origin': '*',
    'x-ms-blob-type': 'BlockBlob',
    'Content-Type': fileType,
    'x-ms-blob-content-type': fileType,
  }),
};

export const REQUEST_LIST_SIZE = 1000000;

export const DEFAULT_LIST_PARAMS = {
  page: 0,
  size: REQUEST_LIST_SIZE,
};
