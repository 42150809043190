/*
 * FE <> BE agreements related to naming for parsing and filling string
 *   - UUID_KEY - generated file UUID by FE library
 *   - FILE_NAME_WITH_EXTENSION - file name for storage
 */
const UUID_KEY = '{FILE_UUID}';
const FILE_NAME_KEY = '{FILE_NAME_WITH_EXTENSION}';

export const fillUploadUrl = (url: string, uuid: string, name: string) =>
  url.replace(UUID_KEY, uuid).replace(FILE_NAME_KEY, name);
