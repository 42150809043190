import { PhoneTypes } from 'api/user/types';
import { FileFormats } from '@tml-component-library/src';

export enum ManageStudentFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  PHONE_NUMBER_TYPE = 'phoneNumberType',
  SECONDARY_PHONE_NUMBER = 'secondaryPhoneNumber',
  SECONDARY_PHONE_NUMBER_TYPE = 'secondaryPhoneNumberType',
  EMAIL = 'email',
  DEPARTMENT = 'department',
  STUDENT_ROLE = 'studentRole',
  START_DATE = 'startDate',
  CV = 'cv',
  LINKEDIN_URL = 'linkedinUrl',
}

export const PHONE_NUMBER_OPTIONS = [
  {
    label: 'Mobile',
    value: PhoneTypes.MOBILE,
  },
  {
    label: 'Office',
    value: PhoneTypes.OFFICE,
  },
  {
    label: 'Home',
    value: PhoneTypes.HOME,
  },
  {
    label: 'Other',
    value: PhoneTypes.OTHER,
  },
];

export const ACCEPTED_CV_FORMATS: string[] = [FileFormats.PDF];
