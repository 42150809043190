import { QuizSettingEntity } from 'api/course-template/types';
import { QuestionType } from 'api/quiz/types';

export const splitRoleSettings = (quizSettings: QuizSettingEntity[]) => {
  const openSetting = quizSettings.find(({ questionType }) => questionType === QuestionType.OPEN)!;
  const multipleSetting = quizSettings.find(({ questionType }) => questionType === QuestionType.MULTIPLE)!;
  const ILESetting = quizSettings.filter(({ questionType }) => questionType === QuestionType.ILE_ESSAY);

  return {
    openSetting,
    multipleSetting,
    ILESetting,
  };
};
