import { Meta } from '@tml-component-library/src/components/Meta';
import { CourseTemplateDetails } from 'features/shared/course-template-details';

export const CourseTemplateDetailsPage = () => (
  <>
    <Meta title="Course Template Details" />

    <CourseTemplateDetails isStaff />
  </>
);
