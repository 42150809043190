import { NavLink, useParams } from 'react-router-dom';
import { Button } from '@tml-component-library/src';
import { useCourseDetailsQuery } from 'api/course/course.api';
import { useCourseTemplateListQuery, useCreateCourseTemplateMutation } from 'api/course-template/course-template.api';
import { Card } from 'components/Card';
import { CardsLayout } from 'components/CardsLayout';
import { Routes } from 'router/routes';
import { useHeader } from 'hooks';
import { messages } from './messages';
import { CourseTemplateTitle } from './components/CourseTemplateTitle';
import { CourseTemplateStatus } from '../../../api/course-template/types';

export const CourseTemplateList = () => {
  const { departmentId, courseId } = useParams();

  const [createCourseTemplate, { isLoading: isCreating }] = useCreateCourseTemplateMutation();
  const { data: { courseTemplates } = {}, isLoading } = useCourseTemplateListQuery({
    status: CourseTemplateStatus.PUBLISH,
  });
  const { data: { name: courseName } = {} } = useCourseDetailsQuery(courseId!);

  useHeader(Routes.courses(departmentId), messages.headerTitle(courseName), [departmentId, courseName]);

  const showContent = !isLoading && Boolean(courseTemplates?.length);
  const showEmpty = !isLoading && !courseTemplates?.length;

  const handleCreateCourseTemplate = () => {
    createCourseTemplate({ courseId: courseId! });
  };

  return (
    <CardsLayout
      isLoading={isLoading}
      showEmpty={showEmpty}
      showContent={showContent}
      emptyText={messages.noCourseTemplates}
      footer={
        <Button
          type="primary"
          onClick={handleCreateCourseTemplate}
          disabled={isLoading || isCreating}
        >
          {messages.createCourseTemplate}
        </Button>
      }
    >
      {courseTemplates?.map(({ courseName: name, version, id }, index) => (
        <Card
          key={id}
          title={
            <CourseTemplateTitle
              courseTemplateName={name}
              version={version}
              isCurrentVersion={!index}
            />
          }
          content={
            <NavLink to={Routes.courseTemplatesDetails(departmentId, courseId, id)}>
              <Button
                type="primary"
                disabled={isLoading}
              >
                {messages.courseTemplatesVersions}
              </Button>
            </NavLink>
          }
        />
      ))}
    </CardsLayout>
  );
};
