import { useCallback, useMemo } from 'react';
import { CustomFormProvider, Modal } from '@tml-component-library/src';
import { PhoneTypes, UserAuthoritiesEntity, UserEntity } from 'api/user/types';
import { useManageUser } from 'common/user/useManageUser';
import { FormRequiredMark } from 'components/FormRequiredMark';
import { useResendInvite } from 'common/user/useResendInvite';
import { ManageAdminFields } from './constants';
import { messages } from './messages';
import { manageAdminValidationSchema } from './validation.schema';
import { MangeAdminForm } from './components/MangeAdminForm';

type ManageCustomerAdminValues = {
  [ManageAdminFields.FIRST_NAME]: string;
  [ManageAdminFields.LAST_NAME]: string;
  [ManageAdminFields.PHONE_NUMBER]: string;
  [ManageAdminFields.SECONDARY_PHONE_NUMBER]: string;
  [ManageAdminFields.COMPANY_ROLE]: string;
  [ManageAdminFields.EMAIL]: string;
  [ManageAdminFields.PHONE_NUMBER_TYPE]: string;
  [ManageAdminFields.SECONDARY_PHONE_NUMBER_TYPE]: string;
};

type ManageAdminModalProps = {
  companyId: string;
  open: boolean;
  hideModal: () => void;
  adminUser?: UserEntity;
};

export const ManageAdminModal = ({ companyId, adminUser, open, hideModal }: ManageAdminModalProps) => {
  const { deleteUser, isDeleting, isCreatingUser, isUpdatingUser, updateUser, createUser } = useManageUser();
  const { resendInvite, isLoading: isResending } = useResendInvite();

  const initialValues: Partial<ManageCustomerAdminValues> = useMemo(
    () => ({
      [ManageAdminFields.FIRST_NAME]: adminUser?.profile?.firstName,
      [ManageAdminFields.LAST_NAME]: adminUser?.profile?.lastName,
      [ManageAdminFields.PHONE_NUMBER]: adminUser?.profile?.phoneNumber,
      [ManageAdminFields.SECONDARY_PHONE_NUMBER]: adminUser?.profile?.secondPhoneNumber,
      [ManageAdminFields.COMPANY_ROLE]: adminUser?.profile?.title,
      [ManageAdminFields.EMAIL]: adminUser?.profile?.email,
      [ManageAdminFields.PHONE_NUMBER_TYPE]: adminUser?.profile?.phoneNumberType || PhoneTypes.MOBILE,
      [ManageAdminFields.SECONDARY_PHONE_NUMBER_TYPE]: adminUser?.profile?.secondPhoneNumberType || PhoneTypes.MOBILE,
    }),
    [adminUser?.profile]
  );

  const onSubmit = useCallback(
    async (values: Partial<ManageCustomerAdminValues>) => {
      const requestBody = {
        id: adminUser?.id,
        companyId,
        profile: {
          title: values[ManageAdminFields.COMPANY_ROLE],
          firstName: values[ManageAdminFields.FIRST_NAME]!,
          lastName: values[ManageAdminFields.LAST_NAME]!,
          email: values[ManageAdminFields.EMAIL]!,
          phoneNumber: values[ManageAdminFields.PHONE_NUMBER],
          phoneNumberType: values[ManageAdminFields.PHONE_NUMBER_TYPE],
          secondPhoneNumber: values[ManageAdminFields.SECONDARY_PHONE_NUMBER],
          secondPhoneNumberType: values[ManageAdminFields.SECONDARY_PHONE_NUMBER_TYPE],
        },
        userRole: UserAuthoritiesEntity.ROLE_ADMIN,
      };

      if (adminUser) {
        await updateUser(requestBody);
      } else {
        await createUser(requestBody);
      }

      hideModal();
    },
    [hideModal, updateUser, createUser, adminUser, companyId]
  );

  const getResendInviteHandler = () => {
    if (adminUser) {
      return async () => {
        await resendInvite(adminUser?.status, adminUser?.profile.email);
      };
    }
  };

  const getDeleteUserHandler = () => {
    if (!adminUser) return;

    return async () => {
      await deleteUser(adminUser.id);
      hideModal();
    };
  };

  return (
    <Modal
      title={adminUser ? messages.editAdmin : messages.createNewAdmin}
      open={open}
      className="py-5 px-6"
      onCancel={hideModal}
      destroyOnClose
      footer={null}
    >
      <CustomFormProvider
        validationSchema={manageAdminValidationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        requiredMark={FormRequiredMark}
      >
        <MangeAdminForm
          adminName={adminUser?.profile?.firstName}
          isResending={isResending}
          onResendInviteLink={getResendInviteHandler()}
          onDelete={getDeleteUserHandler()}
          onCancel={hideModal}
          actionDisabled={adminUser?.profile ? isUpdatingUser || isDeleting : isCreatingUser}
        />
      </CustomFormProvider>
    </Modal>
  );
};
