import { CourseTemplateRoleEntity, CourseTemplateTopicEntity } from 'api/course-template/types';
import { Button, useModal } from '@tml-component-library/src';
import { ManageKbDocumentsModal } from 'features/company-admin/manage-kb-documents';
import { ComponentType } from 'api/quiz/types';
import { ComponentSettingsModal } from './ComponentSettingsModal';
import { messages } from '../messages';

interface ContentFooterProps {
  courseTemplateId: string;
  fetchCourseTemplate: () => Promise<void>;
  role?: CourseTemplateRoleEntity;
  topic?: CourseTemplateTopicEntity;
}

export const TopicFooter = ({ courseTemplateId, fetchCourseTemplate, topic, role }: ContentFooterProps) => {
  const {
    showModal: showManageDocumentsModal,
    hideModal: hideManageDocumentsModal,
    open: openManageDocumentsModal,
  } = useModal();
  const {
    showModal: showTopicSettingsModal,
    hideModal: hideTopicSettingsModal,
    open: openTopicSettingsModal,
  } = useModal();

  const currentRoleSettingId = topic?.settings?.find((setting) => setting.courseTemplateRoleId === role!.id)?.id;

  return (
    <div className="sticky bottom-0 flex gap-2.5 w-full justify-center bg-custom-natural-gray-3 z-10 pt-2.5 pb-8">
      <Button
        size="small"
        type="primary"
        onClick={showManageDocumentsModal}
      >
        {messages.manageDocuments}
      </Button>

      <Button
        size="small"
        type="primary"
        onClick={showTopicSettingsModal}
      >
        {messages.topicSettings}
      </Button>

      {topic && (
        <ManageKbDocumentsModal
          open={openManageDocumentsModal}
          hideModal={hideManageDocumentsModal}
          topicId={topic!.id}
        />
      )}

      {Boolean(role) && Boolean(currentRoleSettingId) && (
        <ComponentSettingsModal
          componentId={topic!.id}
          open={openTopicSettingsModal}
          hideModal={hideTopicSettingsModal}
          courseTemplateId={courseTemplateId}
          fetchCourseTemplate={fetchCourseTemplate}
          role={role!}
          currentRoleSettingId={currentRoleSettingId!}
          componentType={ComponentType.TOPIC}
        />
      )}
    </div>
  );
};
