export enum Status {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface CompanyDetailsEntity {
  name?: string;
  internalName?: string;
  companyUrl?: string;
  linkedInUrl?: string;
  domain?: string;
  internalNotes?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface CompanyLogoEntity {
  fileId: string;
  nameWithExtension: string;
  downloadLink: string;
}

export interface CompanyEntity {
  id: string;
  userId?: string;
  tmAccountManager: string;
  subscriptionStartDate: string;
  companyLogoFileId: string;
  lastActivityAt: string;
  status: Status;
  numberOfStudent: number;
  createdAt: string;
  updatedAt: string;
  details: CompanyDetailsEntity;
  logo: CompanyLogoEntity;
}

export interface CompanyListResponse {
  totalCompanies: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  companies: CompanyEntity[];
}

export interface ManageCompanyRequest extends Partial<CompanyEntity> {}

export interface ManageCompanyResponse extends CompanyEntity {}
