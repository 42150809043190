import { useParams } from 'react-router-dom';
import { CardsLayout } from 'components/CardsLayout';
import { useDepartmentDetailsQuery } from 'api/department/department.api';
import { useKnowledgeBaseListQuery } from 'api/knowledge-base/knowledge-base.api';
import { StatusType } from 'api/knowledge-base/types';
import { Routes } from 'router/routes';
import { useHeader } from 'hooks';
import { KnowledgeBaseCard } from './components/KnowledgeBaseCard';
import { CreateKnowledgeBaseButton } from './components/CreateKnowledgeBaseButton';
import { messages } from './messages';
import { KnowledgeBaseInitialCard } from './components/KnowledgeBaseInitialCard';

export const KnowledgeBaseList = () => {
  const { courseId, departmentId } = useParams();

  const { data: { knowledgeBases = [] } = {}, isLoading: isKnowledgeBaseLoading } = useKnowledgeBaseListQuery(
    {
      courseId,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: { name: departmentName } = {} } = useDepartmentDetailsQuery({ id: departmentId! });

  useHeader(Routes.companyCourses(departmentId), messages.courseVersions(departmentName!), [departmentName]);

  const showContent = !isKnowledgeBaseLoading;
  const showEmpty = !isKnowledgeBaseLoading && !knowledgeBases.length;

  const CreateButton = <CreateKnowledgeBaseButton />;

  /*
   * API agreements - last version on the top (should be sorted DESC - 3,2,1 by version)
   * to find last published version in one iteration
   */
  const currentVersionId = knowledgeBases?.find(({ status }) => status === StatusType.PUBLISHED)?.id;

  return (
    <CardsLayout
      isLoading={isKnowledgeBaseLoading}
      showEmpty={false}
      showContent={showContent}
      emptyText={messages.noCourseVersions}
    >
      {showEmpty ? (
        <KnowledgeBaseInitialCard courseId={courseId!} />
      ) : (
        knowledgeBases.map(({ id, name, errorMessage, status, version, updatedAt }, index) => {
          // API agreements - last version on the top (first array element)
          const isLatestVersion = index === 0;
          const isCurrentVersion = id === currentVersionId;

          return (
            <KnowledgeBaseCard
              status={status}
              key={id}
              CreateButton={isLatestVersion && isCurrentVersion && CreateButton}
              version={version}
              knowledgeBaseId={id}
              title={name}
              isOpenDisabled={isKnowledgeBaseLoading}
              errorMessage={errorMessage}
              updatedAt={updatedAt}
              isCurrentVersion={isCurrentVersion}
            />
          );
        })
      )}
    </CardsLayout>
  );
};
