import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import {
  ActivateUserResponse,
  CreateUserResponse,
  ResendInvitationRequest,
  UpdateUserResponse,
  UserListRequest,
  UserListResponse,
  UserEntity,
  AssignedKnowledgeBase,
  AssignKbRequest,
  ManageUserRequest,
} from './types';

export const userApi = createApi({
  reducerPath: 'user',
  tagTypes: ['userList', 'userKbList'],
  baseQuery: axiosQuery({ baseUrl: '/user' }),
  endpoints: (builder) => ({
    userSelf: builder.query<UserEntity, void>({
      query: () => ({
        url: '/self',
      }),
    }),

    resendInvitation: builder.mutation<void, ResendInvitationRequest>({
      query: (body: ResendInvitationRequest) => ({
        url: '/resend-invitation',
        method: 'POST',
        body,
      }),
    }),

    activateUser: builder.mutation<ActivateUserResponse, void>({
      query: () => ({
        url: '/activate',
        method: 'POST',
        skipNotAuthorizedInterceptor: true,
      }),
    }),

    userList: builder.query<UserListResponse, UserListRequest>({
      query: (params) => ({
        url: '/list',
        params: { ...DEFAULT_LIST_PARAMS, ...params },
      }),
      providesTags: ['userList'],
    }),

    createUser: builder.mutation<CreateUserResponse, ManageUserRequest>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['userList'],
    }),

    updateUser: builder.mutation<UpdateUserResponse, ManageUserRequest>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['userList'],
    }),

    deleteUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['userList'],
    }),

    userAssignedKbList: builder.query<AssignedKnowledgeBase[], string>({
      query: (userId) => ({
        url: `/${userId}/assigned-kbs`,
      }),
      providesTags: ['userKbList'],
    }),

    assignKb: builder.mutation<void, AssignKbRequest>({
      query: (body) => ({
        url: '/assign-kb',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['userKbList', 'userList'],
    }),
  }),
});

export const {
  useUserSelfQuery,
  useLazyUserSelfQuery,
  useResendInvitationMutation,
  useActivateUserMutation,
  useUserListQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useLazyUserAssignedKbListQuery,
  useAssignKbMutation,
} = userApi;
