import { useState } from 'react';
import { Typography } from 'antd';
import { useModal } from '@tml-component-library/src';
import { Button } from '@tml-component-library/src/components';
import { UserAuthoritiesEntity, UserEntity } from 'api/user/types';
import { UserListTable } from 'common/user-list-table';
import { useUser } from 'common/user/useUser';
import { ManageStudentModal } from '../manage-student/ManageStudentModal';
import { AssignCourseModal } from '../manage-student/AssignCourseModal';
import { getColumnConfig } from './configs/tableConfig';
import { messages } from './messages';

export const StudentList = () => {
  const [selectedUser, setSelectedUser] = useState<UserEntity | undefined>();
  const { data, isLoading } = useUser();
  const { showModal: showEditModal, hideModal: hideEditModal, open: openEdit } = useModal();
  const { showModal: showAssignModal, hideModal: hideAssignModal, open: openAssign } = useModal();

  const showAssignStudentModal = (userProfile?: UserEntity) => {
    setSelectedUser(userProfile);
    showAssignModal();
  };

  const showManageStudentModal = (userProfile?: UserEntity) => {
    setSelectedUser(userProfile);
    showEditModal();
  };

  const onHideModal = () => {
    setSelectedUser(undefined);
    hideEditModal();
    hideAssignModal();
  };

  return (
    <>
      <Typography.Title
        className="mt-6 !font-medium"
        level={5}
      >
        {messages.studentUsers}
      </Typography.Title>

      <UserListTable
        companyId={data?.companyId}
        columns={getColumnConfig({ onEdit: showManageStudentModal, onAssign: showAssignStudentModal })}
        userRole={UserAuthoritiesEntity.ROLE_STUDENT}
        isInitialLoading={isLoading}
      />

      <Button
        className="my-4"
        type="primary"
        onClick={() => showManageStudentModal()}
      >
        {messages.createNewStudent}
      </Button>

      {data?.companyId && (
        <>
          <ManageStudentModal
            companyId={data?.companyId}
            open={openEdit}
            hideModal={onHideModal}
            user={selectedUser}
          />
          <AssignCourseModal
            open={openAssign}
            hideModal={onHideModal}
            user={selectedUser}
          />
        </>
      )}
    </>
  );
};
