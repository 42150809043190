import { Meta } from '@tml-component-library/src';
import { AdminList } from 'features/company-admin/admin-list';
import { StudentList } from 'features/company-admin/student-list';

export const UserManagementPage = () => {
  return (
    <>
      <Meta title="User Management" />

      <div className="flex flex-col max-h-full overflow-auto">
        <AdminList />

        <StudentList />
      </div>
    </>
  );
};
