import * as yup from 'yup';
import {
  ALPHANUMERIC_HYPHENS_APOSTROPHES_DOTS_SPACES_SLASHES_REGEX,
  validationMessages,
} from '@tml-component-library/src';
import { ManageRoleFields } from './constants';

export const manageRoleValidationSchema = yup.object().shape({
  [ManageRoleFields.TITLE_ABBREVIATED]: yup.string().trim().required(validationMessages.requiredField),
  [ManageRoleFields.TITLE_FULL]: yup
    .string()
    .trim()
    .matches(ALPHANUMERIC_HYPHENS_APOSTROPHES_DOTS_SPACES_SLASHES_REGEX, {
      message: validationMessages.alphanumericHyphensApostrophesDotsSpacesSlashes,
      excludeEmptyString: true,
    })
    .max(80, validationMessages.minMaxCharacters(1, 80))
    .required(validationMessages.requiredField),
  [ManageRoleFields.DESCRIPTION]: yup.string().trim().required(validationMessages.requiredField),
});
