export const LINKEDIN_PROFILE_REGEX =
  /^(https?:\/\/)?((www|[\w]{2,3})\.)?linkedin\.com\/in\/[A-Za-z0-9-]+(\/[A-Za-z0-9-]+)*(\/)?(\?.*)?$/;

export const ALPHABETICAL_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX =
  /^[A-Za-z\-'.\s]+$/;

export const PHONE_REGEX =
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ALPHANUMERIC_REGEX = /^[a-z\d\-_\s]+[^\\/*?"<>|[\]$]?$/i;

export const ALPHANUMERIC_DOTS_AND_SPACES = /^[A-Za-z0-9.\s]+$/;

export const ALPHANUMERIC_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX =
  /^[A-Za-z0-9\-'.\s]+$/;

export const ALPHANUMERIC_HYPHENS_APOSTROPHES_DOTS_SPACES_SLASHES_REGEX =
  /^[A-Za-z0-9\-'/ ]*$/;

export const HTTPS_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([/\-.][a-z0-9]+)*\.[a-z]{1,62}(:[0-9]{1,5})?(\/([^<>"{}|\\^`]*))?$/i;

export const LINKEDIN_COMPANY_REGEX =
  /^(https?:\/\/)((www|[\w]{2,3})\.)linkedin\.com\/company\/[A-Za-z0-9-]+(\/[A-Za-z0-9-]+)*(\/)?(\?.*)?$/;
