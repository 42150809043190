import { ManageLessonFields } from './constants';

export const messages = {
  save: 'Save',
  edit: 'Edit',
  cancel: 'Cancel',
  success: 'Student Lesson is successfully updated',
  editLesson: 'Edit Lesson',
  lesson: 'Lesson',
  deleteLesson: (name: string) => `Delete the lesson “${name}”`,
};

export const MANAGE_LESSON_FIELDS_PLACEHOLDERS = {
  [ManageLessonFields.NAME]: 'Enter Lesson Name',
  [ManageLessonFields.CONTENT]: 'Enter Lesson Content',
};
