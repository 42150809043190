import { StorageService } from 'shared';

class KnowledgeBaseRepository {
  private readonly client = StorageService.getLocalStorageService();

  private readonly keys = {
    hidden: 'knowledge-base.hidden',
  };

  public isNotificationHidden(postfix: string) {
    return Boolean(this.client.get(`${this.keys.hidden}-${postfix}`));
  }

  public setNotificationHidden(value: boolean, postfix: string) {
    return this.client.set(`${this.keys.hidden}-${postfix}`, value);
  }
}

export const knowledgeBaseRepository = new KnowledgeBaseRepository();
