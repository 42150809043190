import { Meta } from '@tml-component-library/src';
import { CourseTemplateList } from 'features/tm-staff/course-template-list';

export const CourseTemplatesPage = () => (
  <>
    <Meta title="Course Templates" />

    <CourseTemplateList />
  </>
);
