import { useParams } from 'react-router-dom';
import { useCourseDetailsQuery } from 'api/course/course.api';
import { useDepartmentDetailsQuery } from 'api/department/department.api';
import { useHeader } from 'hooks';
import { Routes } from 'router/routes';
import { Sidebar } from './components/Sidebar';
import { Content } from './components/Content';
import { messages } from './messages';
import { CourseTemplateDetailsProvider } from './CourseTemplateDetailsProvider';

interface CourseTemplateDetailsProps {
  isStaff?: boolean;
}

export const CourseTemplateDetails = ({ isStaff = false }: CourseTemplateDetailsProps) => {
  const { departmentId, courseId, templateId } = useParams();

  const { data: { name: courseName } = {} } = useCourseDetailsQuery(courseId!);
  const { data: { name: departmentName } = {} } = useDepartmentDetailsQuery({ id: departmentId! });

  const headerRoute = isStaff ? Routes.courseTemplates(departmentId, courseId) : Routes.companyCourses(departmentId);
  const headerTitle = isStaff
    ? messages.headerTitle(courseName, departmentName)
    : messages.companyHeaderTitle(courseName, departmentName);

  useHeader(headerRoute, headerTitle, [headerRoute, headerTitle]);

  return (
    <CourseTemplateDetailsProvider templateId={templateId!}>
      <div className="grid grid-cols-[minmax(280px,_1fr)_2.7fr] gap-x-2 h-full pb-6">
        <Sidebar />

        <Content isStaff={isStaff} />
      </div>
    </CourseTemplateDetailsProvider>
  );
};
