import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { antTheme, ToastProvider } from '@tml-component-library/src';
import { router } from './router/router';
import { store } from './redux';
import './@tml-component-library/src/styles/index.css';
import { applyAxiosInterceptors, applyAxiosSettings } from './configs';
import { AuthProvider } from './common/auth/AuthProvider';

applyAxiosSettings();
applyAxiosInterceptors();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ConfigProvider theme={antTheme}>
      <AuthProvider>
        <ToastProvider>
          <RouterProvider router={router} />
        </ToastProvider>
      </AuthProvider>
    </ConfigProvider>
  </Provider>
);
