import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DropdownMenu } from '@tml-component-library/src/components/DropdownMenu';
import { Empty, Spin, Typography } from 'antd';
import { useLessonListQuery } from 'api/lesson/lesson.api';
import { LevelType } from 'api/types';
import { Lesson } from './Lesson';
import { messages } from '../messages';

interface LessonsProps {
  editHeaderTitle: string;
  topicId: string;
  level: LevelType;
  roleId: string;
  isPublished: boolean;
  regenerationVersion?: number;
}

export const Lessons = ({
  editHeaderTitle,
  topicId,
  roleId,
  level,
  isPublished,
  regenerationVersion,
}: LessonsProps) => {
  const { kbId } = useParams();
  const lessonsContainerRef = useRef<HTMLDivElement | null>(null);
  const [selectedLessonId, setSelectedLessonId] = useState<string>('');

  const { data, isLoading } = useLessonListQuery({
    knowledgeBaseId: kbId,
    courseTemplatesTopicId: topicId,
    courseTemplatesRoleId: roleId,
    level,
    regenerationVersion,
  });

  const lessons = data?.lessons;

  const jumpToLesson = (lessonId: string) => {
    const lessonOffsetTop = document.getElementById(lessonId)?.offsetTop || 0;
    const containerOffsetTop = lessonsContainerRef?.current?.offsetTop || 0;

    setSelectedLessonId(lessonId);

    lessonsContainerRef.current?.scroll({
      top: lessonOffsetTop - containerOffsetTop,
      behavior: 'smooth',
    });
  };

  const menuOptions =
    lessons?.map(({ id }, i) => ({
      value: id,
      label: messages.lessonOrdinalNumber(i + 1),
    })) || [];

  const content = lessons?.length ? (
    <div
      ref={lessonsContainerRef}
      className={`flex flex-col gap-6 min-h-60 overflow-y-auto ${isPublished ? 'mb-4' : 'mb-20'}`}
    >
      {lessons.map((lesson, index) => (
        <Lesson
          disableEditing={isPublished}
          key={lesson.id}
          ordinalNumber={index + 1}
          editHeaderTitle={editHeaderTitle}
          lesson={lesson}
          level={level}
          regenerationVersion={regenerationVersion}
        />
      ))}
    </div>
  ) : (
    <Empty
      className="mt-8"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={messages.noLessons}
    />
  );

  return (
    <>
      <div className="my-2 flex justify-end gap-2.5">
        <Typography.Text className="font-bold break-normal">{messages.jumpTo}</Typography.Text>
        <DropdownMenu
          className="whitespace-nowrap"
          defaultSelected={menuOptions[0]?.value}
          value={selectedLessonId}
          onSelect={jumpToLesson}
          items={menuOptions}
          /* eslint-disable-next-line react/no-unstable-nested-components */
          dropdownRender={(menus) => <div className="max-h-[200px] overflow-y-scroll">{menus}</div>}
          autoAdjustOverflow
        />
      </div>

      {!isLoading ? (
        content
      ) : (
        <Spin
          className="flex justify-center items-center w-full h-full"
          spinning={isLoading}
        />
      )}
    </>
  );
};
