import { TextInput, useFormContext, FormField } from '@tml-component-library/src';
import { ManageRoleFields } from '../constants';
import { MANAGE_ROLE_FIELDS_LABELS, MANAGE_ROLE_FIELDS_PLACEHOLDERS } from '../messages';
import { ManageRoleFooter } from './ManageRoleFooter';

interface ManageRoleFormProps {
  isLoading: boolean;
  onCancel: () => void;
}

export const ManageRoleForm = ({ onCancel, isLoading }: ManageRoleFormProps) => {
  const { isSubmitting, submit } = useFormContext();

  return (
    <>
      <div className="flex gap-2.5 pb-2.5">
        <FormField
          name={ManageRoleFields.TITLE_ABBREVIATED}
          className="w-2/6 m-0"
          label={MANAGE_ROLE_FIELDS_LABELS[ManageRoleFields.TITLE_ABBREVIATED]}
        >
          <TextInput
            placeholder={MANAGE_ROLE_FIELDS_PLACEHOLDERS[ManageRoleFields.TITLE_ABBREVIATED]}
            required
            disabled
          />
        </FormField>

        <FormField
          name={ManageRoleFields.TITLE_FULL}
          className="w-4/6 m-0"
          label={MANAGE_ROLE_FIELDS_LABELS[ManageRoleFields.TITLE_FULL]}
        >
          <TextInput
            disabled={isLoading}
            placeholder={MANAGE_ROLE_FIELDS_PLACEHOLDERS[ManageRoleFields.TITLE_FULL]}
          />
        </FormField>
      </div>

      <FormField
        name={ManageRoleFields.DESCRIPTION}
        className="m-0"
        label={MANAGE_ROLE_FIELDS_LABELS[ManageRoleFields.DESCRIPTION]}
      >
        <TextInput.TextArea
          disabled={isLoading}
          placeholder={MANAGE_ROLE_FIELDS_PLACEHOLDERS[ManageRoleFields.DESCRIPTION]}
          rows={4}
        />
      </FormField>

      <ManageRoleFooter
        edit
        onSave={submit}
        onCancel={onCancel}
        isLoading={isLoading || isSubmitting}
      />
    </>
  );
};
