import { Button, Divider, Form, Select, Typography } from 'antd';
import { FormField, TextInput, useFormContext } from '@tml-component-library/src';
import { DeleteOutlined, PlusCircleOutlined } from '@tml-component-library/src/icons';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { QuestionType } from 'api/quiz/types';
import { AMOUNT_QUESTION_OPTIONS, ManageRoleSettingsFields } from '../constants';
import { messages } from '../messages';
import { useManageRoleSettingsContext } from '../ManageRoleSettingsContext';

interface ManageRoleFormProps {
  isLoading: boolean;
}

export const ManageRoleSettingsForm = ({ isLoading }: ManageRoleFormProps) => {
  const { isSubmitting, submit } = useFormContext();
  const { isModule, endEditing } = useManageRoleSettingsContext();

  return (
    <>
      <div className="flex flex-col">
        <div className="p-2 border rounded-lg border-solid border-custom-natural-gray-5 bg-custom-neutral-gray-1">
          <Typography.Text className="flex font-bold pb-2.5">{messages.quizRequirements}</Typography.Text>
          <div className="flex items-center py-1">
            <FormField
              name={ManageRoleSettingsFields[QuestionType.OPEN]}
              noStyle
            >
              <Select
                className="min-w-14 mr-2.5"
                options={AMOUNT_QUESTION_OPTIONS}
              />
            </FormField>
            <Typography.Text>{messages.open}</Typography.Text>
          </div>
          <div className="flex items-center pt-1">
            <FormField
              name={ManageRoleSettingsFields[QuestionType.MULTIPLE]}
              noStyle
            >
              <Select
                className="min-w-14 mr-2.5"
                options={AMOUNT_QUESTION_OPTIONS}
              />
            </FormField>
            <Typography.Text>{messages.multiple}</Typography.Text>
          </div>
        </div>

        <Divider className="my-2.5" />

        <Form.List name={ManageRoleSettingsFields.ILE_ESSAY}>
          {(fields, { remove, add }) => (
            <div className={`flex flex-col gap-2.5 ${isModule && 'hidden'}`}>
              {fields.map(({ key, name, ...field }) => (
                <div
                  className="flex items-center gap-2.5 p-2 border rounded-lg border-solid border-custom-natural-gray-5 bg-custom-neutral-gray-1"
                  key={key}
                >
                  <div className="flex flex-col gap-2 w-full">
                    <Typography.Text>{messages.interactiveLearningExercise}</Typography.Text>

                    <FormField
                      noStyle
                      {...field}
                      name={[name, 'requirement']}
                    >
                      <TextInput.TextArea
                        rows={4}
                        placeholder={messages.enterInteractiveLearningExercise}
                      />
                    </FormField>
                  </div>

                  <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => remove(name)}
                  />
                </div>
              ))}
              <Button
                className="w-fit"
                size="small"
                onClick={() => add()}
                icon={<PlusCircleOutlined />}
              >
                {messages.addILE}
              </Button>
            </div>
          )}
        </Form.List>

        <Divider className={`my-2.5 ${isModule && 'hidden'}`} />
      </div>

      <ManageEntityModalFooter
        onSave={submit}
        onCancel={endEditing}
        actionDisabled={isLoading || isSubmitting}
      />
    </>
  );
};
