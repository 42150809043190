import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { HeaderBack } from 'components/HeaderBack';
import { NavigationLayoutContext } from 'common/navigation-layout/NavigationLayout';

export const useHeader = <Dependency,>(route?: string, title?: string, dependencies: Dependency[] = []) => {
  const { onSetHeader } = useOutletContext<NavigationLayoutContext>();

  useEffect(
    () =>
      onSetHeader(
        <HeaderBack
          routePath={route}
          name={title}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  );
};
