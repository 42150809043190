import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import { CompanyEntity, CompanyListResponse, ManageCompanyRequest, ManageCompanyResponse } from './types';

export const companyApi = createApi({
  reducerPath: 'company',
  tagTypes: ['companyList', 'companyDetails'],
  baseQuery: axiosQuery({ baseUrl: '/company' }),
  endpoints: (builder) => ({
    companyList: builder.query<CompanyListResponse, void>({
      query: () => ({
        url: '/list',
        params: { ...DEFAULT_LIST_PARAMS },
      }),
      providesTags: ['companyList'],
    }),

    createCompany: builder.mutation<ManageCompanyResponse, ManageCompanyRequest>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['companyList'],
    }),

    editCompany: builder.mutation<ManageCompanyResponse, ManageCompanyRequest>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['companyList', 'companyDetails'],
    }),

    companyDetails: builder.query<CompanyEntity, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ['companyDetails'],
    }),
  }),
});

export const {
  useCompanyListQuery,
  useCreateCompanyMutation,
  useEditCompanyMutation,
  useCompanyDetailsQuery,
  useLazyCompanyDetailsQuery,
} = companyApi;
