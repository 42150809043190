export const messages = {
  addProduct: 'Add Product',
  editProduct: 'Edit Product',
  productName: 'Product Name',
  description: 'Description',
  enterProductName: 'Enter product name',
  enterProductDescription: 'Enter product description',
  addProductWarning:
    'Adding a new company product to this table will automatically add a new product module to your course. Please make sure to review your course settings for adjustments related to learning about your new product entry. ',
  deleteProduct: (name: string) => `Delete the product “${name}”`,

  productCreationSuccess: 'Product has been successfully added',
  productUpdatingSuccess: 'Product has been successfully updated',

  deleteProductSuccess: (productName: string) => `Product ${productName} is successfully deleted`,
} as const;
