export const messages = {
  companyDocuments: 'Company Documents',
  addDocument: 'Add a Supplement Document',
  module: 'Module',
  fileName: 'File Name',
  description: 'Description',
  department: 'Department',
  course: 'Course',
  fileType: 'File Type',
  version: 'Version',
  action: 'Action',
  edit: 'Edit',
  customizableTemplate: 'Customizable Template',
  additionalLessons: 'Additional Lessons',
  exercises: 'Exercises',
};
