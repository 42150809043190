import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Divider, Typography } from 'antd';
import { ComponentType } from 'api/quiz/types';
import { Modal, messages as sharedMessages } from '@tml-component-library/src';
import { useLazyRoleSettingDetailsQuery } from 'api/course-template/course-template.api';
import { CourseTemplateRoleEntity } from 'api/course-template/types';
import { ModuleTopic } from 'features/shared/manage-module-topic/ModuleTopic';
import { ManageRoleSettingsEditableView } from 'features/shared/manage-role-settings/ManageRoleSettingsEditableView';
import { messages } from '../messages';

interface TopicSettingsModalProps {
  courseTemplateId: string;
  componentId: string;
  open: boolean;
  hideModal: () => void;
  role: CourseTemplateRoleEntity;
  currentRoleSettingId: string;
  fetchCourseTemplate: () => Promise<void>;
  componentType: ComponentType.MODULE | ComponentType.TOPIC;
}

type StyleVariant = 'filled' | 'outlined';

const stylesConfig: Record<StyleVariant, string> = {
  filled: 'bg-custom-natural-gray-3',
  outlined: '',
};

export const ComponentSettingsModal = ({
  courseTemplateId,
  componentId,
  open,
  role,
  hideModal,
  currentRoleSettingId,
  fetchCourseTemplate,
  componentType,
}: TopicSettingsModalProps) => {
  const [getRoleSettingDetails, { data: roleSetting, isFetching }] = useLazyRoleSettingDetailsQuery();

  const [topicBlockStyleVariant, setTopicBlockStyleVariant] = useState<StyleVariant>('outlined');
  const [roleSettingsBlockStyleVariant, setRoleSettingsBlockStyleVariant] = useState<StyleVariant>('outlined');

  const isModule = componentType === ComponentType.MODULE;

  const onTopicEdit = useCallback((isEditing: boolean) => {
    if (isEditing) {
      setTopicBlockStyleVariant('filled');
    } else {
      setTopicBlockStyleVariant('outlined');
    }
  }, []);

  const onRoleSettingsEdit = useCallback((isEditing: boolean) => {
    if (isEditing) {
      setRoleSettingsBlockStyleVariant('filled');
    } else {
      setRoleSettingsBlockStyleVariant('outlined');
    }
  }, []);

  useEffect(() => {
    if (currentRoleSettingId) getRoleSettingDetails(currentRoleSettingId);
  }, [currentRoleSettingId, getRoleSettingDetails]);

  return (
    <Modal
      title={isModule ? messages.moduleSettings : messages.topicSettings}
      open={open}
      className="py-5 px-6"
      onCancel={hideModal}
      destroyOnClose
      footer={null}
      width={700}
    >
      <div className="flex flex-col gap-2.5">
        {!isModule && (
          <Alert
            className="-mx-6" // Negative margin indent closed to Modal px-6 padding style
            message={messages.editingTopicWarning}
            type="warning"
            showIcon
          />
        )}

        <div
          className={`${!isModule && 'p-4 rounded-lg border border-solid border-custom-natural-gray-5'} ${stylesConfig[topicBlockStyleVariant]}`}
        >
          <ModuleTopic
            id={componentId}
            staff={false}
            componentType={componentType}
            onEdit={onTopicEdit}
          />
        </div>

        {!isModule && <Divider className="m-0" />}

        <div>
          <Typography.Title
            level={5}
            className="!font-medium"
          >
            {messages.requirementsForSpecificStudentRole(role.titleAbbreviated)}
          </Typography.Title>

          {roleSetting && (
            <div
              className={`px-4 pb-4 pt-1.5 rounded-lg border border-solid border-custom-natural-gray-5 ${stylesConfig[roleSettingsBlockStyleVariant]}`}
            >
              <ManageRoleSettingsEditableView
                courseTemplateId={courseTemplateId}
                componentType={componentType}
                roleSetting={roleSetting}
                onEdit={onRoleSettingsEdit}
                onSubmit={fetchCourseTemplate}
                isLoading={isFetching}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-7 w-full flex flex-row-reverse">
        <Button onClick={hideModal}>{sharedMessages.close}</Button>
      </div>
    </Modal>
  );
};
