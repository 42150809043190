import * as yup from 'yup';
import { ALPHANUMERIC_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX, validationMessages } from '@tml-component-library/src';
import { ManageCompanyProductFields } from './constants';

export const manageCompanyProductValidationSchema = yup.object().shape({
  [ManageCompanyProductFields.PRODUCT_NAME]: yup
    .string()
    .trim()
    .matches(ALPHANUMERIC_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX, {
      message: validationMessages.alphanumericHyphensApostrophesDotsSpaces,
      excludeEmptyString: true,
    })
    .max(40, validationMessages.minMaxCharacters(1, 40))
    .required(validationMessages.requiredField),
  [ManageCompanyProductFields.DESCRIPTION]: yup.string().trim().required(validationMessages.requiredField),
});
