import { Typography } from 'antd';
import { ReactNode } from 'react';

interface SupplementEditTooltipItemProps {
  stepNumber: number;
  icon: ReactNode;
  text: string;
}

export const SupplementEditTooltipItem = ({ stepNumber, icon, text }: SupplementEditTooltipItemProps) => {
  return (
    <div className="w-44 p-4 flex flex-col gap-2.5 items-center bg-custom-natural-gray-3 border-solid border border-custom-natural-gray-5 rounded-lg border-box">
      <Typography.Title
        className="!m-0"
        level={2}
      >
        {stepNumber}
      </Typography.Title>

      <div className="text-6xl text-custom-neutral-gray-icon">{icon}</div>

      <Typography.Paragraph className="!m-0 text-center text-base">{text}</Typography.Paragraph>
    </div>
  );
};

export interface SupplementEditTooltipProps {
  tooltips: Omit<SupplementEditTooltipItemProps, 'stepNumber'>[];
}

export const SupplementEditTooltip = ({ tooltips }: SupplementEditTooltipProps) => {
  return (
    <div className="flex justify-between">
      {tooltips.map((tooltip, index) => (
        <SupplementEditTooltipItem
          key={tooltip.text}
          stepNumber={index + 1}
          {...tooltip}
        />
      ))}
    </div>
  );
};
