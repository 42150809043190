import axios from 'axios';
import * as humps from 'humps';
import { EventType } from '@tml-component-library/src';
import { authRepository } from 'common/auth/auth.repository';
import { AppConfig } from './app.config';
import { Routes } from '../router/routes';

export const applyAxiosSettings = () => {
  axios.defaults.baseURL = AppConfig.apiBaseUrl;
  axios.defaults.withCredentials = false;
  axios.defaults.headers.common = {
    'Access-Control-Allow-Origin': '*',
  };
};

const redirectToSignIn = () => {
  authRepository.clear();
  window.location.href = Routes.signIn();
};

export const applyAxiosInterceptors = () => {
  axios.interceptors.request.use(
    async (request) => {
      const { accessToken } = authRepository.getAuthTokens();

      request.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';

      return {
        ...request,
        data: humps.decamelizeKeys(request.data),
        params: humps.decamelizeKeys(request.params),
      };
    },
    (error) => {
      throw error;
    }
  );

  axios.interceptors.response.use(
    (response) => ({
      ...response,
      data: humps.camelizeKeys(response.data),
    }),
    async (error) => {
      const originalRequest = error.config;
      const { skipNotAuthorizedInterceptor, skipErrorToast } = originalRequest || {};

      /*
       * Catch 401 error status - related to unauthorized users
       * skipNotAuthorizedInterceptor flag - skip error handling for authorized requests
       */
      const isUnauthorized = error.response.status === 401;

      if (isUnauthorized && !skipNotAuthorizedInterceptor) {
        redirectToSignIn();
      }

      /*
       * Catch 499 error status - related access token expiration
       * _retry flag
       * skipNotAuthorizedInterceptor flag - skip error handling for authorized requests
       */
      const isTokenExpired = error.response.status === 499 && !originalRequest._retry;

      if (isTokenExpired && !skipNotAuthorizedInterceptor) {
        originalRequest._retry = true;

        try {
          const { refreshToken } = authRepository.getAuthTokens();

          /*
           * Remove tokens to avoid sending via API
           */
          authRepository.clearAuthTokens();

          /*
           * Get new tokens
           */
          const { data: updatedTokens } = await axios.post('/authentication/refresh-token', {
            refreshToken,
          });

          authRepository.setAuthTokens(updatedTokens);

          /*
           * Retry the original request with the new access token
           */
          originalRequest.headers.Authorization = updatedTokens.accessToken
            ? `Bearer ${updatedTokens.accessToken}`
            : '';

          return await axios(originalRequest);
        } catch (e) {
          redirectToSignIn();
        }
      }

      if (
        !isTokenExpired &&
        !isUnauthorized &&
        !skipNotAuthorizedInterceptor &&
        error.response.status >= 400 &&
        !skipErrorToast
      ) {
        const event = new CustomEvent<string>(EventType.REQUEST_ERROR, {
          detail: error?.response.data.error_message || error?.response.data.error,
        });
        document.dispatchEvent(event);
      }

      throw error;
    }
  );
};
