import axios, { AxiosError, AxiosRequestConfig } from 'axios';

interface AxiosQuery extends AxiosRequestConfig {
  body?: any;
  skipNotAuthorizedInterceptor?: boolean;
}

export const axiosQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, data: requestData, body, ...rest }: AxiosQuery) => {
    try {
      const { data, ...meta } = await axios({
        url: baseUrl + url,
        data: body || requestData,
        ...rest,
      });

      return { data, meta };
    } catch (axiosError) {
      const { response, message } = axiosError as AxiosError;

      return {
        error: {
          status: response?.status,
          data: response?.data || message,
        },
      };
    }
  };
