import { Divider } from 'antd';
import { Status } from 'api/company/types';
import {
  CustomFormInputLabel,
  Datepicker,
  TextInput,
  useFormContext,
  FormField,
  messages as sharedMessages,
} from '@tml-component-library/src';
import { ConfirmationDescription } from 'components/ConfirmationDescription';
import { ManageCompanyFields } from '../constants';
import { MANAGE_COMPANY_FIELDS_LABELS, messages } from '../messages';
import { ManageCompanyModalFooter } from './ManageCompanyModalFooter';

type ManageCompanyFormProps = {
  handleCancel: () => void;
  handleDisable?: () => void;
  status?: Status;
  companyName?: string;
};

export const ManageCompanyForm = ({ handleDisable, handleCancel, status, companyName }: ManageCompanyFormProps) => {
  const { isSubmitting, submit } = useFormContext();

  const disableConfirmProps = {
    title: messages.disable,
    description: <ConfirmationDescription description={messages.disableCompany(companyName)} />,
    okText: messages.disable,
    cancelText: sharedMessages.cancel,
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-x-2 gap-y-2.5">
        <FormField
          name={ManageCompanyFields.COMPANY_NAME}
          className="m-0"
          label={MANAGE_COMPANY_FIELDS_LABELS[ManageCompanyFields.COMPANY_NAME]}
          required
        >
          <TextInput
            placeholder="Enter Company Name"
            required
          />
        </FormField>

        <div />

        <FormField
          name={ManageCompanyFields.COMPANY_URL}
          className="m-0"
          label={MANAGE_COMPANY_FIELDS_LABELS[ManageCompanyFields.COMPANY_URL]}
          required
        >
          <TextInput placeholder="https://" />
        </FormField>

        <FormField
          name={ManageCompanyFields.LINKEDIN_URL}
          className="m-0"
          label={MANAGE_COMPANY_FIELDS_LABELS[ManageCompanyFields.LINKEDIN_URL]}
          required
        >
          <TextInput placeholder="https://www.linkedin.com/company/" />
        </FormField>

        <FormField
          name={ManageCompanyFields.TM_MANAGER}
          className="m-0"
          label={MANAGE_COMPANY_FIELDS_LABELS[ManageCompanyFields.TM_MANAGER]}
        >
          <TextInput placeholder="Enter TM Staff Name" />
        </FormField>

        <FormField
          name={ManageCompanyFields.SUBSCRIPTION_START_DATE}
          className="m-0"
          label={MANAGE_COMPANY_FIELDS_LABELS[ManageCompanyFields.SUBSCRIPTION_START_DATE]}
        >
          <Datepicker className="w-full" />
        </FormField>

        <FormField
          name={ManageCompanyFields.INTERNAL_NOTES}
          className="col-span-full m-0"
          label={MANAGE_COMPANY_FIELDS_LABELS[ManageCompanyFields.INTERNAL_NOTES]}
        >
          <TextInput.TextArea placeholder="Add notes for internal reference" />
        </FormField>
      </div>

      <Divider className="my-2.5" />

      <CustomFormInputLabel
        content="Required fields"
        required
      />

      <div className="mt-3">
        <ManageCompanyModalFooter
          disableConfirmationProps={disableConfirmProps}
          onSave={submit}
          onCancel={handleCancel}
          isLoading={isSubmitting}
          onDisable={handleDisable}
          status={status}
        />
      </div>
    </div>
  );
};
