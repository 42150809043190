import { ListParams, ListResponse } from 'utils';
import { LevelType } from '../types';

export enum QuestionType {
  OPEN = 'OPEN',
  ILE_ESSAY = 'ILE_ESSAY',
  MULTIPLE = 'MULTIPLE',
  // PUZZLE = 'PUZZLE',
}

export enum ComponentType {
  MODULE = 'MODULE',
  MODULE_PRODUCT = 'MODULE_PRODUCT',
  TOPIC = 'TOPIC',
  TOPIC_PRODUCT = 'TOPIC_PRODUCT',
  LESSON = 'LESSON',
  MODULE_QUIZ = 'MODULE_QUIZ',
  TOPIC_QUIZ = 'TOPIC_QUIZ',
  LESSON_QUIZ = 'LESSON_QUIZ',
  COMPLETE_COURSE = 'COMPLETE_COURSE',
}

export interface QuestionOptionsEntity {
  [key: string]: string;
}

export interface QuestionEntity {
  version: number;
  questionId: string;
  componentId: string;
  componentType: ComponentType;
  questionType: QuestionType;
  questionText: string;
  correctAnswer: string;
  questionOptions: QuestionOptionsEntity;
  createdAt: string;
  updatedAt: string;
  updatedByUserName?: string;
}

export interface QuestionListResponse extends ListResponse {
  totalQuestions: number;
  questions: QuestionEntity[];
}

export interface QuestionListRequest extends ListParams {
  knowledgeBaseId: string;
  courseTemplatesRoleId?: string;
  isLatest?: boolean;
  componentType?: ComponentType;
  componentId?: string;
  level?: LevelType;
  questionType?: string;
  regenerationVersion?: number;
}

export interface UpdateQuestionRequest {
  id: string;
  questionText: string;
  correctAnswer: string;
  questionOptions?: QuestionOptionsEntity;
}
