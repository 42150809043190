import { useCallback, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { CustomFormProvider, useToast } from '@tml-component-library/src';
import { FormRequiredMark } from 'components/FormRequiredMark';
import { useEditRoleMutation } from 'api/role/role.api';
import { RoleEntity } from 'api/role/types';
import { ManageRoleFields } from './constants';
import { manageRoleValidationSchema } from './validation.schema';
import { ManageRoleForm } from './components/ManageRoleForm';
import { messages } from './messages';

interface ManageRoleValues {
  [ManageRoleFields.TITLE_ABBREVIATED]: string;
  [ManageRoleFields.TITLE_FULL]: string;
  [ManageRoleFields.DESCRIPTION]: string;
}

interface ManageCompanyProps {
  role: RoleEntity;
  onCancel: () => void;
}

export const ManageRole = ({ role, onCancel }: ManageCompanyProps) => {
  const [editRole, { isLoading }] = useEditRoleMutation();

  const { showSuccess, showError } = useToast();

  const initialValues: ManageRoleValues = useMemo(
    () => ({
      [ManageRoleFields.TITLE_ABBREVIATED]: role.titleAbbreviated,
      [ManageRoleFields.TITLE_FULL]: role.titleFull,
      [ManageRoleFields.DESCRIPTION]: role.description,
    }),
    [role]
  );

  const onSubmit = useCallback(
    async (values: ManageRoleValues) => {
      const wasTitleChanged = role.titleAbbreviated !== values[ManageRoleFields.TITLE_ABBREVIATED];

      try {
        await editRole({
          id: role!.id,
          titleFull: values[ManageRoleFields.TITLE_FULL],
          titleAbbreviated: wasTitleChanged ? values[ManageRoleFields.TITLE_ABBREVIATED] : undefined,
          description: values[ManageRoleFields.DESCRIPTION],
        }).unwrap();

        showSuccess(messages.success);
      } catch (error) {
        showError((error as AxiosResponse).data.error_message);
      }

      onCancel();
    },
    [role, onCancel, editRole, showSuccess, showError]
  );

  return (
    <CustomFormProvider
      onSubmit={onSubmit}
      validationSchema={manageRoleValidationSchema}
      initialValues={initialValues}
      requiredMark={FormRequiredMark}
    >
      <ManageRoleForm
        isLoading={isLoading}
        onCancel={onCancel}
      />
    </CustomFormProvider>
  );
};
