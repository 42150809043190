import { Meta } from '@tml-component-library/src/components/Meta';
import { CourseTemplateDetails } from 'features/shared/course-template-details';

export const CompanyCourseSettingsPage = () => (
  <>
    <Meta title="Course Settings" />

    <CourseTemplateDetails />
  </>
);
