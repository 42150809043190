import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import {
  KnowledgeBaseListResponse,
  KnowledgeBaseListRequest,
  KnowledgeBaseEntity,
  KnowledgeBaseDetailsRequest,
  UpdateKnowledgeBaseRequest,
  CreateKnowledgeBaseRequest,
  RegenerateKnowledgeBaseComponentsRequest,
} from './types';

export const knowledgeBaseApi = createApi({
  reducerPath: 'knowledge-base',
  tagTypes: ['knowledgeBaseDetails', 'knowledgeBaseList'],
  baseQuery: axiosQuery({ baseUrl: '/kb' }),
  endpoints: (builder) => ({
    knowledgeBaseList: builder.query<KnowledgeBaseListResponse, KnowledgeBaseListRequest>({
      query: (params) => ({
        url: `/list`,
        params: { ...DEFAULT_LIST_PARAMS, ...params },
      }),
      providesTags: ['knowledgeBaseList'],
    }),

    knowledgeBaseDetails: builder.query<KnowledgeBaseEntity, KnowledgeBaseDetailsRequest>({
      query: ({ id }) => ({
        url: `/${id}`,
      }),
      providesTags: ['knowledgeBaseDetails'],
    }),

    updateKnowledgeBase: builder.mutation<KnowledgeBaseEntity, UpdateKnowledgeBaseRequest>({
      query: ({ id, command }) => ({
        url: `/${id}/command/${command}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['knowledgeBaseDetails', 'knowledgeBaseList'],
    }),

    createKnowledgeBase: builder.mutation<KnowledgeBaseEntity, CreateKnowledgeBaseRequest>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
        skipErrorToast: true,
      }),
      invalidatesTags: ['knowledgeBaseDetails', 'knowledgeBaseList'],
    }),

    regenerateKnowledgeBaseComponents: builder.mutation<void, RegenerateKnowledgeBaseComponentsRequest>({
      query: (body) => ({
        url: '/components',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['knowledgeBaseDetails', 'knowledgeBaseList'],
    }),
  }),
});

export const {
  useKnowledgeBaseListQuery,
  useKnowledgeBaseDetailsQuery,
  useUpdateKnowledgeBaseMutation,
  useCreateKnowledgeBaseMutation,
  useRegenerateKnowledgeBaseComponentsMutation,
} = knowledgeBaseApi;
