import { TableProps } from 'antd';
import { Table, useToast } from '@tml-component-library/src';
import { useComponentFileListQuery, useUnlinkComponentFileMutation } from 'api/component-file/component-file.api';
import { FileType } from 'api/file/types';
import { ComponentFileEntity } from 'api/component-file/types';
import { getDocumentsConfig, getSupplementsConfig } from './configs/tableConfig';
import { messages } from './messages';
import { KnowledgeBaseFileListActions } from './types';

type SupportedFileTypes = FileType.TM_DOCUMENT | FileType.COMPANY_DOCUMENT;

interface KnowledgeBaseFileListProps {
  componentId?: string;
  fileType: SupportedFileTypes;
}

const tableConfig: Record<
  SupportedFileTypes,
  (actions: KnowledgeBaseFileListActions) => TableProps<ComponentFileEntity>['columns']
> = {
  [FileType.TM_DOCUMENT]: getSupplementsConfig,
  [FileType.COMPANY_DOCUMENT]: getDocumentsConfig,
};

export const KnowledgeBaseFileList = ({ fileType, componentId }: KnowledgeBaseFileListProps) => {
  const [unlinkComponentFile] = useUnlinkComponentFileMutation();
  const { data, isFetching } = useComponentFileListQuery({
    fileType,
    componentId,
  });
  const { showSuccess } = useToast();

  const tableActions = {
    onUnlinkFile:
      fileType === FileType.TM_DOCUMENT
        ? undefined
        : async (id: string) => {
            await unlinkComponentFile(id).unwrap();
            showSuccess(messages.deleteSuccess);
          },
  };

  const configGetter = tableConfig[fileType];

  return (
    <div className="h-full overflow-y-auto">
      <Table
        loading={isFetching}
        columns={configGetter(tableActions)}
        dataSource={isFetching ? [] : data?.componentFiles}
        rowKey="id"
        sticky
        pagination={false}
      />
    </div>
  );
};
