export const messages = {
  loading: 'Generating Lessons & Questions',
  open: 'Open',
  edit: 'Edit',
  unpublished: 'Unpublished',
  courseVersions: (departmentName: string) => `Course Versions ${departmentName ? `(${departmentName})` : ''}`,
  noCourseVersions: 'No Course Versions',
  listOfFiles: 'List of Files',
  createKnowledgeBase: 'Create New Version',
  viewSourceMaterials: 'View Source Materials',
  companySettings: 'Company Settings',
  youCanManageFiles: 'You can manage these files in the Company Documents Table in the',
  knowledgeBaseSourceMaterials: (kbName: string) => `${kbName} Source Materials`,
  publishedAt: (date: string) => `Published ${date}`,
  currentVersion: 'Current Version',
  kbVersion: (version: number) => `(v${version}.0)`,
  noVersion: '(no version)',
  companyDocuments: 'Company Documents',
};
