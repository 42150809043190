import { Meta } from '@tml-component-library/src/components/Meta';
import { CoursesList } from 'features/shared/courses-list';

export const CoursesPage = () => (
  <>
    <Meta title="Courses" />

    <CoursesList isStaff />
  </>
);
