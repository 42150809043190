import { useNavigate } from 'react-router-dom';
import { Routes } from 'router/routes';
import { useCourseTemplateListQuery } from 'api/course-template/course-template.api';
import { CourseTemplateStatus } from 'api/course-template/types';

/*
 * Open last course template version in Admin panel
 */
export const useCourseSettings = (departmentId: string) => {
  const navigate = useNavigate();

  const { data: { courseTemplates } = {}, isLoading: isCourseTemplateLoading } = useCourseTemplateListQuery({
    status: CourseTemplateStatus.PUBLISH,
  });

  const getClickCourseSettingsHandler = (courseId: string) => async () => {
    if (courseTemplates && courseTemplates.length > 0) {
      const sortedTemplates = [...courseTemplates].sort((a, b) => b.version - a.version);

      navigate(Routes.companyCourseTemplatesDetails(departmentId, courseId, sortedTemplates[0].id));
    }
  };

  const isEmptyCourseTemplatesList = Boolean(courseTemplates && courseTemplates?.length === 0);

  return {
    getClickCourseSettingsHandler,
    isCourseSettingsLoading: isCourseTemplateLoading,
    isCourseSettingsButtonDisabled: isEmptyCourseTemplatesList,
  };
};
