import { useLazyModuleListQuery } from 'api/module/module.api';

export const useSelectModule = () => {
  const [fetchModuleListQuery, { data: moduleListData, isFetching: isFetchingModuleList }] = useLazyModuleListQuery();

  const fetchModuleList = (courseId: string) => fetchModuleListQuery({ courseId }).unwrap();

  const options = moduleListData?.modules?.map((module) => ({
    label: module.name,
    value: module.id,
  }));

  return {
    fetchModuleList,
    options,
    isFetchingModuleList,
  };
};
