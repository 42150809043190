import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import { ModuleEntity, ModuleListRequest, ModuleListResponse, ModuleRequest } from './types';

export const moduleApi = createApi({
  reducerPath: 'module',
  tagTypes: ['moduleList', 'moduleDetails'],
  baseQuery: axiosQuery({ baseUrl: '/module' }),
  endpoints: (builder) => ({
    moduleList: builder.query<ModuleListResponse, ModuleListRequest>({
      query: (params) => ({
        url: '/list',
        params: { ...DEFAULT_LIST_PARAMS, ...params },
      }),
      providesTags: ['moduleList'],
    }),

    moduleDetails: builder.query<ModuleEntity, ModuleRequest>({
      query: ({ id, ...params }) => ({
        url: `/${id}`,
        params,
      }),
      providesTags: ['moduleDetails'],
    }),

    updateModuleDetails: builder.mutation<ModuleEntity, ModuleEntity>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['moduleDetails', 'moduleList'],
    }),
  }),
});

export const { useLazyModuleListQuery, useLazyModuleDetailsQuery } = moduleApi;
