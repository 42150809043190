export const Routes = {
  root: () => '/',
  signIn: () => '/sign-in',
  signInSuccess: () => '/sign-in-success',
  signInError: () => '/sign-in-error',

  // TM staff routes
  staff: () => '/staff',
  companies: () => '/staff/companies',
  companyDetails: (id = ':companyId') => `/staff/companies/${id}`,
  kbSettings: () => '/staff/kb-settings',
  supplements: () => '/staff/supplements',
  manageAccess: () => '/staff/manage-access',
  courseManagement: () => '/staff/departments',
  courses: (departmentId = ':departmentId') => `/staff/departments/${departmentId}/courses`,
  courseTemplates: (departmentId = ':departmentId', courseId = ':courseId') =>
    `/staff/departments/${departmentId}/courses/${courseId}/templates`,
  courseTemplatesDetails: (departmentId = ':departmentId', courseId = ':courseId', templateId = ':templateId') =>
    `/staff/departments/${departmentId}/courses/${courseId}/templates/${templateId}`,
  roles: (departmentId = ':departmentId') => `/staff/departments/${departmentId}/roles`,
  // TM staff routes

  // Customer admin routes
  admin: () => '/admin',
  companyDepartments: () => '/admin/departments',
  companyCourses: (departmentId = ':departmentId') => `/admin/departments/${departmentId}/courses`,
  companyRoles: (departmentId = ':departmentId') => `/admin/departments/${departmentId}/roles`,
  companyCourseTemplatesDetails: (departmentId = ':departmentId', courseId = ':courseId', templateId = ':templateId') =>
    `/admin/departments/${departmentId}/courses/${courseId}/templates/${templateId}`,
  knowledgeBases: (departmentId = ':departmentId', courseId = ':courseId') =>
    `/admin/departments/${departmentId}/course/${courseId}`,
  knowledgeBaseDetails: (departmentId = ':departmentId', courseId = ':courseId', kbId = ':kbId') =>
    `/admin/departments/${departmentId}/course/${courseId}/kb/${kbId}`,
  companySettings: () => '/admin/company-settings',
  usersManagement: () => '/admin/user-management',
  // Customer admin routes
};
