import { createContext, useContext, ReactNode, useMemo } from 'react';
import { CourseTemplateModuleEntity, CourseTemplateTopicEntity } from 'api/course-template/types';

interface ManageKbDocumentProviderProps {
  topicId: string;
  children: ReactNode;
  topicData?: CourseTemplateTopicEntity;
  moduleData?: CourseTemplateModuleEntity;
}

interface ManageKbDocumentProviderValue {
  topicId: string;
  topicData?: CourseTemplateTopicEntity;
  moduleData?: CourseTemplateModuleEntity;
}

export const ManageKbDocumentContext = createContext({} as ManageKbDocumentProviderValue);

export const ManageKbDocumentProvider = ({
  children,
  topicId,
  topicData,
  moduleData,
}: ManageKbDocumentProviderProps) => {
  const value = useMemo(
    () => ({
      topicId,
      topicData,
      moduleData,
    }),
    [topicId, topicData, moduleData]
  );

  return <ManageKbDocumentContext.Provider value={value}>{children}</ManageKbDocumentContext.Provider>;
};

export const useManageKbDocumentContext = () => useContext<ManageKbDocumentProviderValue>(ManageKbDocumentContext);
