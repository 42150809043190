import React, { useState } from 'react';
import { Button } from '@tml-component-library/src/components';
import { useModal } from '@tml-component-library/src';
import { UserListTable } from 'common/user-list-table';
import { UserAuthoritiesEntity, UserEntity } from 'api/user/types';
import { ManageAdminModal } from '../../shared/manage-admin/ManageAdminModal';
import { messages } from './messages';
import { getColumnConfig } from './configs/tableConfig';

interface CompanyAdminListProps {
  companyId?: string;
}

export const CompanyAdminList = ({ companyId }: CompanyAdminListProps) => {
  const [selectedUser, setSelectedUser] = useState<UserEntity | undefined>();
  const { open, showModal, hideModal } = useModal();

  const showManageAdminModal = (userProfile?: UserEntity) => {
    setSelectedUser(userProfile);
    showModal();
  };
  const onHideModal = () => {
    setSelectedUser(undefined);
    hideModal();
  };

  return (
    <div className="flex flex-col overflow-auto">
      <UserListTable
        companyId={companyId}
        columns={getColumnConfig({ onEdit: showManageAdminModal })}
        userRole={UserAuthoritiesEntity.ROLE_ADMIN}
      />

      <Button
        className="my-4"
        type="primary"
        onClick={() => showManageAdminModal()}
      >
        {messages.createNewAdminMember}
      </Button>

      {companyId && (
        <ManageAdminModal
          companyId={companyId!}
          open={open}
          hideModal={onHideModal}
          adminUser={selectedUser}
        />
      )}
    </div>
  );
};
