import { LevelType } from 'api/types';
import { CourseTemplateModuleEntity, CourseTemplateTopicEntity, RoleSettingEntity } from 'api/course-template/types';
import { KnowledgeBaseCommand, StatusType } from 'api/knowledge-base/types';
import { Badge } from '@tml-component-library/src';
import { TreeIconColors } from '../constants';
import { messages } from '../messages';

export const LEVEL_ANCHOR = {
  [LevelType.PROGRESSIVE]: 'isProgressiveLessonsExist',
  [LevelType.COMPLEX]: 'isComplexLessonsExist',
  [LevelType.STANDARD]: 'isStandardLessonsExist',
};

const getTopicBadgeColor = (topic: CourseTemplateTopicEntity, roleId: string, level: LevelType) => {
  const levelAnchor = LEVEL_ANCHOR[level] as keyof RoleSettingEntity;

  // Find needed variable from role settings related to isLessonsExist by selected level
  const isLessonsExist = topic?.settings?.find(({ courseTemplateRoleId: id }) => id === roleId)?.[levelAnchor];

  return isLessonsExist ? TreeIconColors.FULL : TreeIconColors.NO;
};

const getModuleBadgeColor = (module: CourseTemplateModuleEntity, roleId: string, level: LevelType) => {
  const levelAnchor = LEVEL_ANCHOR[level] as keyof RoleSettingEntity;

  // prepare array of isLessonsExist values from role settings by selected level
  const isLessonsExistArray = module.topics.map(
    ({ settings }) => settings?.find(({ courseTemplateRoleId: id }) => id === roleId)?.[levelAnchor]
  );

  switch (true) {
    case isLessonsExistArray.every((val) => val):
      return TreeIconColors.FULL;
    case isLessonsExistArray.every((val) => !val):
      return TreeIconColors.NO;
    default:
      return TreeIconColors.PARTLY;
  }
};

export const prepareTreeData = (
  level: LevelType = LevelType.PROGRESSIVE,
  modules?: CourseTemplateModuleEntity[],
  roleId?: string
) =>
  modules?.map((module) => ({
    ...module,
    title: <span className="font-bold">{module.name}</span>,
    ...(roleId && level
      ? {
          icon: (
            <Badge
              dot
              status={getModuleBadgeColor(module, roleId, level)}
            />
          ),
        }
      : {}),
    key: module.id,
    isRoot: true,
    selectable: true,
    children: module?.topics?.map((topic) => ({
      ...topic,
      key: topic.id,
      title: topic.name,
      ...(roleId && level
        ? {
            icon: (
              <Badge
                dot
                status={getTopicBadgeColor(topic, roleId, level)}
              />
            ),
          }
        : {}),
    })),
  }));

export type TreeData = ReturnType<typeof prepareTreeData>;

export const getKnowledgeBaseActionProps = (
  onClick: (command: KnowledgeBaseCommand) => () => void,
  showRegenerationModal: () => void,
  status?: StatusType
) => {
  switch (status) {
    case StatusType.CREATED:
    case StatusType.PREPARING_FILES:
    case StatusType.CREATING_KNOWLEDGE_BASE:
    case StatusType.READY_TO_GENERATE_QUESTIONS:
    case StatusType.CREATING_QUESTIONS:
      return {
        text: messages.generateQuestions,
        disabled: true,
        showButton: true,
      };
    case StatusType.READY_PUBLISH:
      return {
        onClick: showRegenerationModal,
        onClickPublish: onClick(KnowledgeBaseCommand.PUBLISH_KNOWLEDGE_BASE),
        textPublish: messages.publishKnowledgeBase,
        text: messages.regenerateLessons,
        showButton: true,
      };
    case StatusType.PUBLISHED:
      return {
        textPublish: messages.publishKnowledgeBase,
        text: messages.generateLessons,
        disabled: true,
        showButton: true,
      };
    default:
      return {
        showButton: false,
      };
  }
};

export const getAlertMessageProps = (status: StatusType, updatedAt?: string) => {
  switch (status) {
    case StatusType.CREATING_KNOWLEDGE_BASE:
      return {
        showAlert: true,
        message: messages.lessonsCreation,
        type: 'warning',
      };

    case StatusType.CREATING_QUESTIONS:
      return {
        showAlert: true,
        message: messages.questionsCreation,
        type: 'warning',
      };

    case StatusType.PUBLISHED:
      return {
        showAlert: true,
        message: messages.knowledgeBasePublished(updatedAt!),
        type: 'success',
      };

    default:
      return {
        showAlert: false,
      };
  }
};
