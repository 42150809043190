import { useCallback } from 'react';
import { useResendInvitationMutation } from 'api/user/user.api';
import { useSignInMutation } from 'api/auth/auth.api';
import { UserStatus } from 'api/user/types';

export const useResendInvite = () => {
  const [resendInviteMutation, { isLoading: isResending }] = useResendInvitationMutation();
  const [signInMutation, { isLoading: isSignIn }] = useSignInMutation();

  const resendInvite = useCallback(
    (userStatus: UserStatus, email: string) => {
      if (userStatus === UserStatus.PENDING) {
        return resendInviteMutation({ email }).unwrap();
      }
      return signInMutation({ email, skipNotAuthorizedInterceptor: false }).unwrap();
    },
    [resendInviteMutation, signInMutation]
  );

  return {
    isLoading: isResending || isSignIn,
    resendInvite,
  };
};
