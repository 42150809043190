// @ts-expect-error: No declaration file for module
import { BLOCK_BUTTONS as DRAFT_BLOCK_BUTTONS } from 'medium-draft';

export const EXTENDED_STYLE_ITEMS = {
  UNDERLINE: {
    open: function open() {
      return '++';
    },

    close: function close() {
      return '++';
    },
  },
};

export const BLOCK_BUTTONS = [
  {
    label: 'H1',
    style: 'header-one',
    icon: 'header',
    description: 'Heading 1',
  },
  {
    label: 'H2',
    style: 'header-two',
    icon: 'header',
    description: 'Heading 2',
  },
  {
    label: 'H3',
    style: 'header-three',
    icon: 'header',
    description: 'Heading 3',
  },
  {
    label: 'H4',
    style: 'header-four',
    icon: 'header',
    description: 'Heading 4',
  },
  {
    label: 'H5',
    style: 'header-five',
    icon: 'header',
    description: 'Heading 5',
  },
  {
    label: 'UL',
    style: 'unordered-list-item',
    icon: 'list-ul',
    description: 'Unordered List',
  },
  {
    label: 'OL',
    style: 'ordered-list-item',
    icon: 'list-ol',
    description: 'Ordered List',
  },
].concat(DRAFT_BLOCK_BUTTONS);

export const TOOLBAR_CONFIG = {
  block: [
    'header-one',
    'header-two',
    'header-three',
    'header-four',
    'header-five',
    'ordered-list-item',
    'unordered-list-item',
  ],
  inline: ['BOLD', 'ITALIC', 'UNDERLINE', 'hyperlink'],
};
