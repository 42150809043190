import * as yup from 'yup';
import {
  ALPHABETICAL_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
  validationMessages,
} from '@tml-component-library/src';
import { ManageAdminFields } from './constants';

export const manageAdminValidationSchema = yup.object().shape({
  [ManageAdminFields.FIRST_NAME]: yup
    .string()
    .trim()
    .matches(ALPHABETICAL_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX, {
      message: validationMessages.alphabeticalHyphensApostrophesDotsSpaces,
      excludeEmptyString: true,
    })
    .max(40, validationMessages.minMaxCharacters(1, 40))
    .required(validationMessages.requiredField),
  [ManageAdminFields.LAST_NAME]: yup
    .string()
    .trim()
    .matches(ALPHABETICAL_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX, {
      message: validationMessages.alphabeticalHyphensApostrophesDotsSpaces,
      excludeEmptyString: true,
    })
    .max(40, validationMessages.minMaxCharacters(1, 40))
    .required(validationMessages.requiredField),
  [ManageAdminFields.COMPANY_ROLE]: yup
    .string()
    .trim()
    .max(40, validationMessages.minMaxCharacters(1, 40))
    .required(validationMessages.requiredField),
  [ManageAdminFields.PHONE_NUMBER]: yup.string().trim().notRequired().matches(PHONE_REGEX, {
    message: validationMessages.invalidPhoneNumber,
    excludeEmptyString: true,
  }),
  [ManageAdminFields.SECONDARY_PHONE_NUMBER]: yup.string().trim().notRequired().matches(PHONE_REGEX, {
    message: validationMessages.invalidPhoneNumber,
    excludeEmptyString: true,
  }),
  [ManageAdminFields.EMAIL]: yup
    .string()
    .trim()
    .email(validationMessages.invalidEmail)
    .matches(EMAIL_REGEX, { message: validationMessages.invalidEmail, excludeEmptyString: true })
    .max(255, validationMessages.maxCharacters(255))
    .required(validationMessages.requiredField),
});
