import { TableProps } from 'antd';
import { Button } from '@tml-component-library/src';
import { DAY_FORMAT } from '@tml-component-library/src/shared/constants';
import { convertDateFormat } from '@tml-component-library/src/utils/convertDateFormat';
import { ComponentFileEntity } from 'api/component-file/types';
import { messages } from '../messages';

export type DocumentListActions = {
  onEdit: (file?: ComponentFileEntity) => void;
};

export const getColumnConfig = (actions: DocumentListActions): TableProps<ComponentFileEntity>['columns'] => [
  {
    title: messages.fileName,
    dataIndex: ['file', 'name'],
    width: 250,
    sorter: (a, b) => a?.file?.name.localeCompare(b?.file?.name),
  },
  {
    title: messages.description,
    dataIndex: ['file', 'description'],
    width: 300,
    sorter: (a, b) => a?.file?.description.localeCompare(b?.file?.description),
  },
  {
    title: messages.department,
    dataIndex: ['department', 'name'],
    width: 130,
    render: (_, { department }) => department?.name,
    sorter: (a, b) => a?.department?.name.localeCompare(b?.department?.name),
  },
  {
    title: messages.course,
    dataIndex: ['course', 'name'],
    width: 130,
    render: (_, { course }) => course?.name,
    sorter: (a, b) => a?.course?.name.localeCompare(b?.course?.name),
  },
  {
    title: messages.module,
    dataIndex: ['module', 'name'],
    width: 130,
    render: (_, { module }) => module?.name,
    sorter: (a, b) => a?.module?.name?.localeCompare(b.module?.name),
  },
  {
    title: messages.topic,
    dataIndex: ['topic', 'name'],
    width: 240,
    render: (_, { topic }) => topic?.name,
    sorter: (a, b) => a?.topic?.name?.localeCompare(b.topic?.name),
  },
  {
    title: messages.date,
    dataIndex: ['file', 'updatedAt'],
    width: 120,
    render: (_, { file }) => convertDateFormat(file?.updatedAt, DAY_FORMAT),
    sorter: (a, b) => a?.file?.updatedAt.localeCompare(b?.file?.updatedAt),
  },
  {
    title: messages.version,
    dataIndex: ['file', 'version'],
    width: 100,
    sorter: (a, b) => a?.file?.version.localeCompare(b?.file?.version),
  },
  {
    title: messages.action,
    width: 110,
    fixed: 'right',
    render: (_, file) => (
      <Button
        type="link"
        className="pl-0"
        onClick={() => actions.onEdit(file)}
      >
        {messages.edit}
      </Button>
    ),
  },
];
