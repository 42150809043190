import { useCallback } from 'react';
import { useLazyRoleListQuery } from 'api/role/role.api';
import { useDepartmentListQuery } from 'api/department/department.api';

export const useKBStructureEntities = () => {
  const { data: departmentListData, isLoading: isFetchingDepartments } = useDepartmentListQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [fetchRoleListQuery, { isLoading: isFetchingRoles, data: rolesListData }] = useLazyRoleListQuery({
    pollingInterval: 0,
  });

  const fetchRoleList = useCallback(
    async (departmentId: string) => {
      await fetchRoleListQuery({
        departmentId,
      }).unwrap();
    },
    [fetchRoleListQuery]
  );

  return {
    isFetchingDepartments,
    isFetchingRoles,
    departmentListData,
    fetchRoleList,
    rolesListData,
  };
};
