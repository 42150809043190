import { createContext, ReactNode, useMemo } from 'react';
import { useUserSelfQuery } from '../../api/user/user.api';
import { UserEntity } from '../../api/user/types';

type UserContextType = {
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
  data?: UserEntity;
};

type UserProviderType = {
  children: ReactNode;
};

export const UserContext = createContext({} as UserContextType);
export const UserProvider = ({ children }: UserProviderType) => {
  const { data, isError, isFetching, isLoading } = useUserSelfQuery();

  const contextValue = useMemo(
    () => ({
      isError,
      data,
      isFetching,
      isLoading,
    }),
    [isError, data, isFetching, isLoading]
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
