import { useCallback } from 'react';
import { useCreateCompanyMutation, useEditCompanyMutation } from 'api/company/company.api';
import { ManageCompanyRequest, Status } from 'api/company/types';

export const useManageCompany = () => {
  const [createCompanyMutation, { isLoading: isCreating }] = useCreateCompanyMutation();
  const [editCompanyMutation, { isLoading: isEditing }] = useEditCompanyMutation();

  const createCompany = useCallback(
    (body: ManageCompanyRequest) => createCompanyMutation(body).unwrap(),
    [createCompanyMutation]
  );

  const editCompany = useCallback(
    (body: ManageCompanyRequest) => editCompanyMutation(body).unwrap(),
    [editCompanyMutation]
  );

  const disableCompany = useCallback(
    (body: ManageCompanyRequest) => editCompanyMutation({ ...body, status: Status.INACTIVE }).unwrap(),
    [editCompanyMutation]
  );

  return {
    createCompany,
    isCreating,
    disableCompany,
    isEditing,
    editCompany,
  };
};
