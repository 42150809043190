import { Dropdown, DropdownProps, MenuProps, Typography, Space } from 'antd';
import { useMemo } from 'react';
import { DownOutlined } from '../../icons';

interface DropdownMenuProps extends DropdownProps {
  items: {
    label: string;
    value: string;
  }[];
  defaultSelected: string;
  value: string;
  onSelect: (value: any) => void;
}

export const DropdownMenu = ({
  items,
  value,
  defaultSelected,
  onSelect,
  ...dropdownProps
}: DropdownMenuProps) => {
  const menuItems: MenuProps['items'] = useMemo(() => {
    return (
      items?.map((item) => {
        return {
          key: item.value,
          label: item.label,
        };
      }) || []
    );
  }, [items]);

  const handleSelect: MenuProps['onSelect'] = ({ key }) => {
    onSelect(key);
  };

  const selectedItem = menuItems.find((item) =>
    value ? item?.key === value : item?.key === defaultSelected
  );

  return (
    <Dropdown
      {...dropdownProps}
      menu={{
        items: menuItems,
        selectable: true,
        defaultSelectedKeys: [defaultSelected],
        onSelect: handleSelect,
      }}
    >
      <Typography.Link>
        <Space>
          <>
            {(selectedItem as any)?.label}
            <DownOutlined />
          </>
        </Space>
      </Typography.Link>
    </Dropdown>
  );
};
