import { ManageRoleFields } from './constants';

export const messages = {
  save: 'Save',
  edit: 'Edit',
  cancel: 'Cancel',
  success: 'Student Role is successfully updated',
};

export const MANAGE_ROLE_FIELDS_LABELS = {
  [ManageRoleFields.TITLE_ABBREVIATED]: 'Role Title (Abbreviated)',
  [ManageRoleFields.TITLE_FULL]: 'Role Title (Full)',
  [ManageRoleFields.DESCRIPTION]: 'Role Description',
};

export const MANAGE_ROLE_FIELDS_PLACEHOLDERS = {
  [ManageRoleFields.TITLE_ABBREVIATED]: 'Enter Abbreviated Name',
  [ManageRoleFields.TITLE_FULL]: 'Enter Full Name',
  [ManageRoleFields.DESCRIPTION]: 'Enter Description',
};
