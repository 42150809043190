import { useCallback, useEffect, useState, useTransition } from 'react';
import { useParams } from 'react-router-dom';
import { useHeader, useTreeStructure } from 'hooks';
import { commonMessages } from '@tml-component-library/src';
import { useLazyCourseTemplateDetailsQuery } from 'api/course-template/course-template.api';
import { useKnowledgeBaseDetailsQuery, useUpdateKnowledgeBaseMutation } from 'api/knowledge-base/knowledge-base.api';
import { KnowledgeBaseCommand, StatusType } from 'api/knowledge-base/types';
import { LevelType } from 'api/types';
import {
  getDefaultTreeKeys,
  getModuleById,
  getModuleByTopicId,
  getTopicById,
} from 'features/shared/course-template-details/utils';
import { Routes } from 'router/routes';
import { Sidebar } from './components/Sidebar';
import { Content } from './components/Content';
import { KnowledgeBaseCreationSteps } from './components/KnowledgeBaseCreationSteps';
import { useRoles } from './hooks/useRoles';
import { prepareTreeData } from './utils';
import { NotificationModal } from './components/NotificationModal';

// TODO: rewrite to use context
export const KnowledgeBaseDetails = () => {
  const [isPending, startTransition] = useTransition();
  const [treeData, setTreeData] = useState<ReturnType<typeof prepareTreeData>>();
  const [defaultKeys, setDefaultKeys] = useState<ReturnType<typeof getDefaultTreeKeys>>([]);

  const { kbId, departmentId, courseId } = useParams();

  const [getCourseTemplate, { data: courseTemplate, isLoading: isCourseTemplateLoading }] =
    useLazyCourseTemplateDetailsQuery();
  const { data: knowledgeBase, isLoading: isKBLoading } = useKnowledgeBaseDetailsQuery({
    id: kbId!,
  });
  const [updateKnowledgeBase, { isLoading }] = useUpdateKnowledgeBaseMutation();

  const templateId = knowledgeBase?.courseTemplateId;

  const fetchCourseTemplate = useCallback(async () => {
    if (templateId && kbId) await getCourseTemplate({ id: templateId, knowledgeBaseId: kbId }).unwrap();
  }, [templateId, getCourseTemplate, kbId]);

  useEffect(() => {
    fetchCourseTemplate();
  }, [fetchCourseTemplate]);

  const knowledgeBaseName = commonMessages.defaultKnowledgeBaseTitle(
    knowledgeBase?.name,
    knowledgeBase?.version,
    knowledgeBase?.status === StatusType.PUBLISHED
  );

  useHeader(Routes.knowledgeBases(departmentId, courseId), isKBLoading ? '' : knowledgeBaseName, [
    knowledgeBaseName,
    isKBLoading,
    departmentId,
    courseId,
  ]);

  const handleUpdateKnowledgeBase = (command: KnowledgeBaseCommand) => () => {
    updateKnowledgeBase({ command, id: kbId! });
  };

  const roles = courseTemplate?.roles;
  const modules = courseTemplate?.modules;

  const { role, onRoleChange } = useRoles(roles);
  const [level, setLevel] = useState<LevelType>(LevelType.PROGRESSIVE);

  const handleLevelChange = (value: LevelType) => setLevel(value);

  const roleId = role?.id;

  const updateTreeData = () => {
    startTransition(() => {
      const newTreeData = prepareTreeData(level, modules, roleId);
      const newDefaultKeys = getDefaultTreeKeys(modules);

      setTreeData(newTreeData);
      setDefaultKeys(newDefaultKeys);
    });
  };

  useEffect(() => {
    updateTreeData();
  }, [modules, level, roleId]);

  const { selectedKeys, onSelect, isRoot, onExpand, expandedKeys } = useTreeStructure(defaultKeys);

  const [id] = (selectedKeys as string[]) || [];
  const module = isRoot ? getModuleById(id, modules) : getModuleByTopicId(id, modules);
  const topic = isRoot ? undefined : getTopicById(id, getModuleByTopicId(id, modules));

  return (
    <div className="grid grid-cols-[minmax(280px,_1fr)_2.7fr_150px] gap-x-6 h-full">
      <Sidebar
        role={role}
        roles={roles}
        onRoleChange={onRoleChange}
        selectedKeys={selectedKeys}
        onTreeSelect={onSelect}
        treeData={treeData}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        isLoading={isKBLoading || isCourseTemplateLoading}
        knowledgeBaseStatus={knowledgeBase?.status}
      />

      <Content
        level={level}
        onLevelChange={handleLevelChange}
        courseTemplateId={templateId || ''}
        isLoading={isKBLoading || isCourseTemplateLoading || isPending}
        isModule={isRoot}
        role={role}
        module={module}
        topic={topic}
        onSelect={onSelect}
        knowledgeBaseName={knowledgeBaseName}
        knowledgeBaseStatus={knowledgeBase?.status}
        updatedAt={knowledgeBase?.updatedAt}
        fetchCourseTemplate={fetchCourseTemplate}
        regenerationVersion={knowledgeBase?.regenerationVersion}
      />

      <KnowledgeBaseCreationSteps
        knowledgeBaseId={knowledgeBase?.id || ''}
        courseTemplateId={templateId || ''}
        isLoading={isLoading || isKBLoading || isCourseTemplateLoading}
        currentStatus={knowledgeBase?.status || StatusType.CREATED}
        onUpdateKnowledgeBase={handleUpdateKnowledgeBase}
      />

      {knowledgeBase?.status === StatusType.READY_PUBLISH && <NotificationModal knowledgeBase={knowledgeBase} />}
    </div>
  );
};
