import { Avatar, AvatarProps, Typography } from 'antd';
import { UserOutlined } from '../../icons';

type UserLabelProps = {
  avatarProps: AvatarProps;
  username: string;
  email: string;
};

export const UserLabel = ({ avatarProps, username, email }: UserLabelProps) => {
  return (
    <div className="flex flex-col justify-center items-center gap-2">
      <Avatar {...{ icon: <UserOutlined />, ...avatarProps }} />
      <div className="w-52 flex flex-col gap-0.2 justify-center items-center overflow-hidden">
        <Typography.Text
          ellipsis={{ tooltip: username }}
          className="w-full text-center text-custom-natural-gray-5"
        >
          {username}
        </Typography.Text>
        <Typography.Text
          ellipsis={{ tooltip: email }}
          className="w-full text-center text-custom-natural-gray-5"
        >
          {email}
        </Typography.Text>
      </div>
    </div>
  );
};
