import { Divider } from 'antd';
import {
  CustomFormInputLabel,
  TextInput,
  UploadDragger,
  UploadDraggerOnChangeHandler,
  UploadedList,
  useFormContext,
  FormField,
  messages as sharedMessages,
} from '@tml-component-library/src';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { ACCEPTED_FILE_FORMATS, ManageKbDocumentFields } from '../constants';
import { messages } from '../messages';

interface ManageDocumentFormProps {
  onCancel?: () => void;
}

export const ManageKbDocumentForm = ({ onCancel }: ManageDocumentFormProps) => {
  const { values, setFieldValue, isSubmitting, submit } = useFormContext();

  const onChangeFile: UploadDraggerOnChangeHandler = ({ fileList }) => {
    const value = fileList[0]?.originFileObj;
    setFieldValue(ManageKbDocumentFields.UPLOAD_DOCUMENT, value);
  };

  const isThereInitialFileValue =
    values[ManageKbDocumentFields.UPLOAD_DOCUMENT] &&
    typeof values[ManageKbDocumentFields.UPLOAD_DOCUMENT] === 'string';

  const fileName = typeof values.document === 'string' ? values.document : (values.document as File)?.name;

  return (
    <div className="p-4 bg-custom-natural-gray-3 border border-solid border-custom-natural-gray-5 rounded-lg">
      <div className="flex flex-col gap-2.5">
        <div className="flex flex-row gap-2.5">
          <FormField
            name={ManageKbDocumentFields.FILE_NAME}
            className="w-full m-0"
            label={messages.fileName}
            required
          >
            <TextInput
              placeholder={messages.enterDocumentName}
              required
            />
          </FormField>

          <FormField
            name={ManageKbDocumentFields.FILE_VERSION}
            className="w-full m-0"
            label={messages.fileVersion}
            required
          >
            <TextInput placeholder={messages.enterDocumentVersion} />
          </FormField>
        </div>

        <FormField
          name={ManageKbDocumentFields.FILE_DESCRIPTION}
          className="w-full m-0"
          label={messages.description}
          required
        >
          <TextInput.TextArea
            placeholder={messages.addDescription}
            rows={4}
          />
        </FormField>
      </div>

      <FormField
        className="mt-2.5"
        name={ManageKbDocumentFields.UPLOAD_DOCUMENT}
        label={messages.uploadDocument}
        {...(!isThereInitialFileValue ? { required: true } : {})}
      >
        {!isThereInitialFileValue ? (
          <UploadDragger
            uploadProps={{
              beforeUpload: () => false,
              multiple: false,
              maxCount: 1,
              accept: ACCEPTED_FILE_FORMATS.join(','),
              onChange: onChangeFile,
              withCredentials: true,
            }}
          />
        ) : (
          <UploadedList name={fileName} />
        )}
      </FormField>

      <Divider className="my-2.5" />

      <CustomFormInputLabel
        content={sharedMessages.requiredFields}
        required
      />

      <div className="mt-3">
        <ManageEntityModalFooter
          actionDisabled={isSubmitting}
          onCancel={onCancel}
          saveText={messages.upload}
          onSave={submit}
        />
      </div>
    </div>
  );
};
