import { CustomFormProvider, Modal, useToast } from '@tml-component-library/src';
import { useCallback, useMemo } from 'react';
import { ProductEntity } from 'api/product/types';
import { useCreateProductMutation, useDeleteProductMutation, useUpdateProductMutation } from 'api/product/product.api';
import { resetCourseTemplateApiState } from 'api/course-template/course-template.api';
import { FormRequiredMark } from 'components/FormRequiredMark';
import { ManageCompanyProductForm } from './components';
import { manageCompanyProductValidationSchema } from './validation.schema';
import { ManageCompanyProductFields } from './constants';
import { messages } from './messages';
import { useAppDispatch } from '../../../redux';

interface ManageCompanyStudentLearningDetailsProps {
  hideModal: () => void;
  open: boolean;
  data?: ProductEntity;
}

interface ManageCompanyProductFormValues {
  [ManageCompanyProductFields.PRODUCT_NAME]: string;
  [ManageCompanyProductFields.DESCRIPTION]: string;
}

export const ManageCompanyProduct = ({ open, data, hideModal }: ManageCompanyStudentLearningDetailsProps) => {
  const [createProductMutation] = useCreateProductMutation();
  const [updateProductMutation] = useUpdateProductMutation();
  const [deleteProductMutation] = useDeleteProductMutation();

  const dispatch = useAppDispatch();
  const { showSuccess } = useToast();

  const handleSubmit = async (values: ManageCompanyProductFormValues) => {
    const requestBody = {
      name: values[ManageCompanyProductFields.PRODUCT_NAME],
      description: values[ManageCompanyProductFields.DESCRIPTION],
    };

    if (data) {
      await updateProductMutation({ ...requestBody, id: data.id }).unwrap();
      showSuccess(messages.productUpdatingSuccess);
    } else {
      await createProductMutation(requestBody).unwrap();
      showSuccess(messages.productCreationSuccess);
    }

    dispatch(resetCourseTemplateApiState());
    hideModal();
  };

  const initialValue = useMemo(() => {
    return {
      [ManageCompanyProductFields.PRODUCT_NAME]: data?.name || '',
      [ManageCompanyProductFields.DESCRIPTION]: data?.description || '',
    };
  }, [data]);

  const handleDelete = useCallback(() => {
    if (!data) return;

    return async () => {
      await deleteProductMutation(data.id).unwrap();
      showSuccess(messages.deleteProductSuccess(data.name));

      dispatch(resetCourseTemplateApiState());
      hideModal();
    };
  }, [data, deleteProductMutation, dispatch, hideModal, showSuccess]);

  return (
    <Modal
      title={data ? messages.editProduct : messages.addProduct}
      open={open}
      className="py-5 px-6"
      onCancel={hideModal}
      footer={null}
      destroyOnClose
    >
      <CustomFormProvider
        onSubmit={handleSubmit}
        requiredMark={FormRequiredMark}
        validationSchema={manageCompanyProductValidationSchema}
        initialValues={initialValue}
      >
        <ManageCompanyProductForm
          productName={data?.name}
          warningMessage={data ? '' : messages.addProductWarning}
          onCancel={hideModal}
          onDelete={handleDelete()}
        />
      </CustomFormProvider>
    </Modal>
  );
};
