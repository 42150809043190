export const messages = {
  basic: 'Basic',
  standard: 'Standard',
  progressive: 'Progressive',
  complex: 'Complex',
  advanced: 'Advanced',
  levels: 'Learning Levels',
  noLessons: 'No Lessons',
  studentRole: 'Student Role',
  courseStructure: 'Course Structure',
  uploadDocuments: 'Upload Additional Documents',
  requestChanges: 'Request Changes',
  generateLessons: 'Generate Lessons',
  regenerateLessons: 'Regenerate Lessons',
  generatingKnowledgeBase: 'Generating Knowledge Base',
  generateQuestions: 'Generate Questions',
  generatingQuestions: 'Generating Questions',
  publishKnowledgeBase: 'Publish Version',
  publishedKnowledgeBase: 'Published Knowledge Base',
  openQuestion: 'Open',
  ILEQuestion: 'Interactive Learning Exercise',
  multipleQuestion: 'Multiple',
  puzzleQuestion: 'Puzzle',
  lessons: 'Lessons',
  topics: 'Topics',
  topic: 'Topic Quiz',
  module: 'Module Quiz',
  questions: 'Questions',
  noQuestions: 'No Questions',
  manageDocuments: 'Manage Documents',
  topicSettings: 'Topic Settings',
  moduleSettings: 'Module Settings',
  editLesson: 'Edit Lesson',
  editQuestion: 'Edit',
  editHeaderTitle: (level: string, moduleName?: string, topicName?: string) =>
    `${moduleName ? `${moduleName} > ` : ''}${topicName} (Learning Level: ${level})`,

  lessonsCreation: 'Lesson creation in progress...',
  questionsCreation: 'Question creation in progress...',
  knowledgeBasePublishing: 'Course publishing in progress...',
  knowledgeBasePublished: (date: string) => `Course published ${date}`,

  newVersionCreated: 'Congrats! A new course version with a new knowledge base has been created.',
  emptyLessons: 'The course lessons are currently empty.',
  startFillingTheCourse: `
    Start filling this course by pressing the
    "Generate Lessons" Button
  `,

  requirementsForSpecificStudentRole: (roleName: string) => `Requirements for Student Role: ${roleName}`,
  editingTopicWarning: 'Editing a Topic’s settings may change lessons and questions upon regeneration.',

  studentRoles: 'Student Roles',
  rolesAreManaged: 'Roles are managed department-wide, editing these will affect all courses within a department.',

  showQuestions: 'Show Questions',
  hideQuestions: 'Hide Questions',

  jumpTo: 'Jump to:',
  lessonOrdinalNumber: (number: number) => `Lesson ${number}`,

  updated: 'Updated',
  updatedAt: (date: string, userName?: string) => `Updated ${date} by ${userName || ''}`,
  createdAt: (date: string) => `Created ${date}`,

  courseGenerationComplete: 'Course generation is now complete with\nlessons, questions and quizzes.',
  pleaseReview: 'Please review and edit as needed.\nPress the “Publish” button\n to get your course published.',
  okImReady: "Okay, I'm ready to review",
} as const;
