import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useCourseTemplateDetailsQuery } from 'api/course-template/course-template.api';
import {
  CourseTemplateDetailsResponse,
  CourseTemplateModuleEntity,
  CourseTemplateTopicEntity,
} from 'api/course-template/types';

type CourseTemplateComponent = CourseTemplateTopicEntity | CourseTemplateModuleEntity;

interface CourseTemplateDetailsContextValue {
  courseTemplateDetailsData?: CourseTemplateDetailsResponse;
  isLoading: boolean;
  isFetching: boolean;
  selectedComponent?: CourseTemplateComponent;
  selectComponent: (component: CourseTemplateComponent) => void;
  refetchCourseTemplateDetails?: () => Promise<void>;
}

interface CourseTemplateDetailsProviderProps {
  templateId: string;
  children: ReactNode;
}

const CourseTemplateDetailsContext = createContext({} as CourseTemplateDetailsContextValue);

export const CourseTemplateDetailsProvider = ({ templateId, children }: CourseTemplateDetailsProviderProps) => {
  const [selectedComponent, setSelectedComponent] = useState<CourseTemplateComponent>();

  const { data, isLoading, isFetching, refetch } = useCourseTemplateDetailsQuery(
    {
      id: templateId!,
    },
    { refetchOnMountOrArgChange: true }
  );

  const selectComponent = useCallback((component: CourseTemplateComponent) => {
    setSelectedComponent(component);
  }, []);

  const refetchCourseTemplateDetails = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const contextValue: CourseTemplateDetailsContextValue = useMemo(
    () => ({
      courseTemplateDetailsData: data,
      isFetching,
      isLoading,
      selectComponent,
      selectedComponent,
      refetchCourseTemplateDetails,
    }),
    [data, isFetching, isLoading, selectComponent, selectedComponent, refetchCourseTemplateDetails]
  );

  return <CourseTemplateDetailsContext.Provider value={contextValue}>{children}</CourseTemplateDetailsContext.Provider>;
};

export const useCourseTemplateDetailsContext = () => useContext(CourseTemplateDetailsContext);
