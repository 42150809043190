export enum FileType {
  TM_DOCUMENT = 'TM_DOCUMENT',
  COMPANY_DOCUMENT = 'COMPANY_DOCUMENT',
  COMPANY_LOGO = 'COMPANY_LOGO',
  USER_CV = 'USER_CV',
}

export enum FileCategory {
  CUSTOMIZABLE_TEMPLATE = 'CUSTOMIZABLE_TEMPLATE',
  ADDITIONAL_LESSONS = 'ADDITIONAL_LESSONS',
  EXERCISES = 'EXERCISES',
}

export interface FileEntity {
  id: string;
  companyId: string;
  name: string;
  nameWithExtension: string;
  size: number;
  extension: string;
  description: string;
  author: string;
  presenter: string;
  version: string;
  type: FileType;
  category: FileCategory;
  storagePath: string;
  downloadLink: string;
  createdAt: string;
  updatedAt: string;
  relatedFiles: FileEntity[];
}

export interface FileListResponse {
  totalFiles: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  files: FileEntity[];
}

export interface FileListRequest {
  fileType: FileType;
  companyId?: string;
  page?: number;
  size?: number;
}

export interface CreateFileEntity {
  id?: string;
  name: string;
  nameWithExtension: string;
  version: string;
  description?: string;
  author?: string;
  presenter?: string;
  type?: FileType;
  category?: FileCategory;
}

export interface CreateFileRequest {
  files: Partial<CreateFileEntity>[];
}

export interface UpdateFileRequest extends Omit<CreateFileEntity, 'id'> {
  id: string;
}

export interface FileUploadUrlResponse {
  uploadFilesUrl: string;
}
