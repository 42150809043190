export const messages = {
  courseChanges: 'Course Changes',
  legend: 'Legend:',
  moduleLegend: 'Select Modules / Topics you want to regenerate',
  currentlyEmpty: 'Currently empty',
  partiallyEmpty: 'Partially empty',
  contentComplete: 'Content complete',
  selectAll: 'Select All',
  unselectAll: 'Unselect All',
  selectedItemsCount: (count: number = 0) => `${count} Selected`,
  regenerateSelected: 'Regenerate Selected',
  learnHow: 'Learn how Course Modules are related',
  kbRegenerationStarted: 'Lesson regeneration has been successfully started',
  noContent: 'No content',
  contentUpToDate: 'Content up to date',
} as const;
