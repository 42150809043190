import { Button } from 'antd';
import { messages } from '../messages';

interface ManageKbDocumentsModalFooterProps {
  hideModal: () => void;
}

export const ManageKbDocumentsModalFooter = ({ hideModal }: ManageKbDocumentsModalFooterProps) => {
  return (
    <div className="flex justify-end">
      <Button onClick={hideModal}>{messages.done}</Button>
    </div>
  );
};
