import { Typography } from 'antd';
import dayjs from 'dayjs';
import { DAY_FORMAT } from '@tml-component-library/src';
import { messages } from '../messages';

type FontSize = 'medium' | 'large';

type KnowledgeBaseCardTitleProps = {
  kbName: string;
  version: number;
  isPublished: boolean;
  publishedAt?: string;
  isCurrentVersion?: boolean;
  fontSize?: FontSize;
};

const fontSizeClasses = {
  large: {
    courseNameText: 'text-2xl font-medium',
    courseInfo: 'text-base',
    versionText: 'text-base italic',
  },
  medium: {
    versionText: 'text-sm',
    courseNameText: 'text-sm font-normal',
    courseInfo: 'text-sm',
  },
};

export const KnowledgeBaseCardTitle = ({
  kbName,
  version,
  isPublished,
  publishedAt,
  isCurrentVersion = false,
  fontSize = 'large',
}: KnowledgeBaseCardTitleProps) => {
  const { courseInfo, courseNameText, versionText } = fontSizeClasses[fontSize];

  return (
    <>
      <Typography.Text className={`${courseNameText}`}>{kbName}</Typography.Text>{' '}
      <Typography.Text className={`${versionText}`}>{messages.kbVersion(version)}</Typography.Text>
      {isPublished ? (
        <Typography.Text className={`${courseInfo} italic font-normal`}>
          {' '}
          - {messages.publishedAt(dayjs(publishedAt).format(DAY_FORMAT))}
        </Typography.Text>
      ) : (
        <Typography.Text className={`${courseInfo} italic font-normal text-orange-400`}>
          {' '}
          - {messages.unpublished}
        </Typography.Text>
      )}
      {isCurrentVersion && (
        <Typography.Text className={`${courseInfo} italic font-normal text-custom-base-blue-6`}>
          {' '}
          - {messages.currentVersion}
        </Typography.Text>
      )}
    </>
  );
};
