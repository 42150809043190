import { Meta } from '@tml-component-library/src/components/Meta';
import { CoursesList } from 'features/shared/courses-list';

export const CompanyCoursesPage = () => (
  <>
    <Meta title="Courses" />

    <CoursesList />
  </>
);
