import { Button, useModal } from '@tml-component-library/src';
import { CourseTemplateModuleEntity, CourseTemplateRoleEntity } from 'api/course-template/types';
import { ComponentType } from 'api/quiz/types';
import { ComponentSettingsModal } from './ComponentSettingsModal';
import { messages } from '../messages';

interface ModuleFooterProps {
  courseTemplateId: string;
  fetchCourseTemplate: () => Promise<void>;
  role?: CourseTemplateRoleEntity;
  module?: CourseTemplateModuleEntity;
}

export const ModuleFooter = ({ courseTemplateId, fetchCourseTemplate, module, role }: ModuleFooterProps) => {
  const {
    showModal: showModuleSettingsModal,
    hideModal: hideModuleSettingsModal,
    open: openModuleSettingsModal,
  } = useModal();

  const currentRoleSettingId = module?.settings?.find((setting) => setting.courseTemplateRoleId === role!.id)?.id;

  return (
    <div className="sticky bottom-0 flex gap-2.5 w-full justify-center bg-custom-natural-gray-3 z-10 pt-2.5 pb-8">
      <Button
        size="small"
        type="primary"
        onClick={showModuleSettingsModal}
        disabled={!role || !currentRoleSettingId}
      >
        {messages.moduleSettings}
      </Button>

      {Boolean(role) && Boolean(currentRoleSettingId) && (
        <ComponentSettingsModal
          componentId={module!.id}
          open={openModuleSettingsModal}
          hideModal={hideModuleSettingsModal}
          courseTemplateId={courseTemplateId}
          fetchCourseTemplate={fetchCourseTemplate}
          role={role!}
          currentRoleSettingId={currentRoleSettingId!}
          componentType={ComponentType.MODULE}
        />
      )}
    </div>
  );
};
