import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { Modal, useToast } from '@tml-component-library/src';
import { useCourseTemplateDetailsQuery } from 'api/course-template/course-template.api';
import {
  useRegenerateKnowledgeBaseComponentsMutation,
  useUpdateKnowledgeBaseMutation,
} from 'api/knowledge-base/knowledge-base.api';
import { KnowledgeBaseCommand, KnowledgeBaseComponent } from 'api/knowledge-base/types';
import { Routes } from 'router/routes';
import { messages } from './messages';
import { ManageRegenerationKnowledgeBaseForm, Legend } from './components';

interface ManageRegenerationKnowledgeBaseModalProps {
  courseTemplateId: string;
  knowledgeBaseId: string;
  open: boolean;
  hideModal: () => void;
}

export const ManageRegenerationKnowledgeBaseModal = ({
  courseTemplateId,
  knowledgeBaseId,
  open,
  hideModal,
}: ManageRegenerationKnowledgeBaseModalProps) => {
  const { showSuccess } = useToast();
  const { departmentId, courseId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: courseTemplateDetailsData } = useCourseTemplateDetailsQuery({ id: courseTemplateId!, knowledgeBaseId });
  const [regenerateKnowledgeBaseComponentMutation] = useRegenerateKnowledgeBaseComponentsMutation();
  const [updateKnowledgeBaseMutation] = useUpdateKnowledgeBaseMutation();

  const regenerateKnowledgeBaseComponents = useCallback(
    async (components: KnowledgeBaseComponent[]) => {
      try {
        setIsSubmitting(true);

        await regenerateKnowledgeBaseComponentMutation({
          knowledgeBaseId,
          components,
        }).unwrap();

        await updateKnowledgeBaseMutation({
          id: knowledgeBaseId,
          command: KnowledgeBaseCommand.REGENERATE_KNOWLEDGE_BASE,
        }).unwrap();

        setIsSubmitting(false);

        showSuccess(messages.kbRegenerationStarted);

        hideModal();

        navigate(Routes.knowledgeBases(departmentId, courseId));
      } catch (err) {
        setIsSubmitting(false);

        throw err;
      }
    },
    [
      regenerateKnowledgeBaseComponentMutation,
      knowledgeBaseId,
      updateKnowledgeBaseMutation,
      showSuccess,
      hideModal,
      navigate,
      departmentId,
      courseId,
    ]
  );

  return (
    <Modal
      title={messages.courseChanges}
      open={open}
      className="py-5 px-6"
      onCancel={hideModal}
      destroyOnClose
      footer={null}
      width={700}
    >
      <div className="flex flex-col gap-2.5">
        <Legend />

        {courseTemplateDetailsData && (
          <ManageRegenerationKnowledgeBaseForm
            onRegenerate={regenerateKnowledgeBaseComponents}
            data={courseTemplateDetailsData}
            onCancel={hideModal}
            isSubmitting={isSubmitting}
          />
        )}
      </div>
    </Modal>
  );
};
