import React, { ReactNode, useMemo } from 'react';
import { Divider, Typography } from 'antd';
import { FormField, messages as sharedMessages, TextInput, useFormContext } from '@tml-component-library/src';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { LastInteractionInfo } from 'components/LastInteractionInfo';
import { ConfirmationDescription } from 'components/ConfirmationDescription';
import { QuestionEntity, QuestionType } from 'api/quiz/types';
import { QUESTION_TYPE_TITLES } from 'shared/messages';
import { messages } from '../messages';
import { ManageQuestionFormField } from '../constants';
import { MultipleQuestionAnswers } from './MultipleQuestionAnswerInput';

interface ManageQuestionFormProps {
  actionDisabled: boolean;
  onDelete: () => Promise<void>;
  onCancel: () => void;
  question: QuestionEntity;
}

export const ManageQuestionForm = ({ actionDisabled, onDelete, onCancel, question }: ManageQuestionFormProps) => {
  const { isSubmitting, initialValues, submit, values } = useFormContext();
  const { questionOptions, questionType } = question;

  const componentsByType: Record<QuestionType, { answerInput: ReactNode }> = useMemo(() => {
    return {
      [QuestionType.MULTIPLE]: {
        answerInput: (
          <MultipleQuestionAnswers
            options={questionOptions}
            initialValues={initialValues[ManageQuestionFormField.ANSWER]}
          />
        ),
      },
      [QuestionType.ILE_ESSAY]: {
        answerInput: null,
      },
      [QuestionType.OPEN]: {
        answerInput: (
          <FormField
            className="mb-0"
            name={ManageQuestionFormField.ANSWER}
            required
          >
            <TextInput.TextArea
              autoSize={{ maxRows: 8 }}
              style={{ resize: 'none' }}
            />
          </FormField>
        ),
      },
    };
  }, [questionOptions, initialValues]);

  const { answerInput } = componentsByType[questionType];

  const questionName = values[ManageQuestionFormField.QUESTION_TEXT];

  const deleteConfirmationProps = {
    title: sharedMessages.confirmDeletion,
    description: questionName ? (
      <ConfirmationDescription description={messages.deleteQuestionConfirmation(questionName)} />
    ) : (
      ''
    ),
    okText: sharedMessages.delete,
    cancelText: sharedMessages.cancel,
  };

  return (
    <>
      <div className="p-4 min-h-[400px] bg-custom-natural-gray-3 border border-solid rounded-lg border-custom-natural-gray-5">
        <div className="p-4 flex flex-col w-full bg-white rounded-lg border border-solid border-custom-natural-gray-5">
          <div>
            <Typography.Text className="py-1">
              <Typography.Text className="font-bold">Question</Typography.Text> (Type:{' '}
              {QUESTION_TYPE_TITLES[questionType]})
            </Typography.Text>
            <Divider className="my-1" />
            <FormField
              name={ManageQuestionFormField.QUESTION_TEXT}
              required
            >
              <TextInput.TextArea rows={4} />
            </FormField>
          </div>

          {answerInput && (
            <div>
              <Typography.Text className="font-bold">Answer</Typography.Text>

              <Divider className="my-1" />

              {answerInput}
            </div>
          )}
        </div>
      </div>

      <div className="mt-3">
        <ManageEntityModalFooter
          leftSide={
            <LastInteractionInfo
              createdAt={question.createdAt}
              updatedAt={question.updatedAt}
              updatedByUserName={question?.updatedByUserName}
            />
          }
          onDelete={onDelete}
          deleteConfirmationProps={deleteConfirmationProps}
          onSave={submit}
          actionDisabled={isSubmitting || actionDisabled}
          onCancel={onCancel}
        />
      </div>
    </>
  );
};
