import { NavLink } from 'react-router-dom';
import { Button } from '@tml-component-library/src';
import { messages } from 'components/HeaderBack/messages';

interface CompanyDetailsHeaderProps {
  routePath?: string;
  name?: string;
}

export const HeaderBack = ({ routePath, name }: CompanyDetailsHeaderProps) => (
  <div className="flex items-center gap-4">
    {routePath && (
      <NavLink to={routePath}>
        <Button
          size="small"
          type="primary"
          ghost
        >
          {messages.back}
        </Button>
      </NavLink>
    )}

    {name}
  </div>
);
