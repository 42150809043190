import { FileOutlined } from '../../icons';
import { Button, ButtonProps } from '../Button';

interface UploadedFileProps {
  name: string;
  buttonText?: string;
  buttonProps?: ButtonProps;
  onClick?: () => void;
}

export const UploadedList = ({
  name,
  buttonText,
  onClick,
  buttonProps,
}: UploadedFileProps) => (
  <div className="flex items-center justify-between p-1.5 border border-solid rounded-lg border-slate-200">
    <div className="flex items-center gap-2.5">
      <span className="text-custom-neutral-gray-icon text-2xl leading-6">
        <FileOutlined />
      </span>
      {name}
    </div>
    {buttonText && (
      <Button
        size="small"
        type="link"
        {...buttonProps}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    )}
  </div>
);
