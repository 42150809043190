import { Badge } from '@tml-component-library/src';
import { Typography } from 'antd';
import {
  CourseTemplateDetailsResponse,
  CourseTemplateModuleEntity,
  CourseTemplateTopicEntity,
  RoleSettingEntity,
} from 'api/course-template/types';
import { TreeIconColors } from 'features/company-admin/knowledge-base-details/constants';
import { LEVEL_ANCHOR } from 'features/company-admin/knowledge-base-details/utils';
import { TreeItem } from '../types';

const checkTopicLessonsExisting = (topic: CourseTemplateTopicEntity) => {
  const levelAnchor = Object.values(LEVEL_ANCHOR) as (keyof RoleSettingEntity)[];
  const isAllLevelInRoleExists = topic?.settings?.every((setting) => levelAnchor.every((anchor) => setting[anchor]));
  const isSomeLevelInRoleExists = isAllLevelInRoleExists
    ? false
    : topic?.settings?.some((setting) => levelAnchor.some((anchor) => setting[anchor]));

  return {
    isAllLevelInRoleExists,
    isSomeLevelInRoleExists,
  };
};

const getTopicContentState = (topic: CourseTemplateTopicEntity) => {
  const { isAllLevelInRoleExists, isSomeLevelInRoleExists } = checkTopicLessonsExisting(topic);

  switch (true) {
    case isAllLevelInRoleExists:
      return 'FULL';

    case isSomeLevelInRoleExists:
      return 'PARTLY';

    default:
      return 'NO';
  }
};

const getModuleContentState = (module: CourseTemplateModuleEntity) => {
  const checkResult = module.topics.map(checkTopicLessonsExisting);

  switch (true) {
    case checkResult.every((val) => val.isAllLevelInRoleExists):
      return 'FULL';
    case checkResult.some((val) => val.isSomeLevelInRoleExists || val.isAllLevelInRoleExists):
      return 'PARTLY';
    default:
      return 'NO';
  }
};

export const prepareTreeData = (data: CourseTemplateDetailsResponse) => {
  return {
    tree: data.modules?.map((module) => {
      const topics = module?.topics?.map((topic) => {
        const topicContentState = getTopicContentState(topic);

        return {
          ...topic,
          key: topic.id,
          title: <Typography.Text>{topic.name}</Typography.Text>,
          icon: (
            <Badge
              dot
              status={TreeIconColors[topicContentState]}
            />
          ),
        };
      });

      const moduleContentState = getModuleContentState(module);

      return {
        ...module,
        title: <Typography.Text className="!font-bold">{module.name}</Typography.Text>,
        icon: (
          <Badge
            dot
            status={TreeIconColors[moduleContentState]}
          />
        ),
        key: module.id,
        isRoot: true,
        children: topics,
      };
    }),

    allKeys: data.modules.reduce((acc, module) => {
      const topicKeys = module.topics.map((topic) => ({
        id: topic.id,
        isRoot: false,
      }));

      acc = acc.concat([...topicKeys, { id: module.id, isRoot: true }]);

      return acc;
    }, [] as TreeItem[]),
  };
};
