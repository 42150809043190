export const messages = {
  createNewStudent: 'Create New Student',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  role: 'Role',
  startDate: 'Start Date',
  courses: 'Courses',
  status: 'Status',
  action: 'Action',
  actions: 'Actions',
  assign: 'Assign',
  edit: 'Edit',
  studentUsers: 'Student Users',
};
