import { Meta } from '@tml-component-library/src';
import { CompanySettings } from 'features/company-admin/company-settings';

export const CompanySettingsPage = () => {
  return (
    <>
      <Meta title="User Management" />

      <CompanySettings />
    </>
  );
};
