import { useCallback, useEffect, useState } from 'react';
import { useLazyModuleListQuery } from 'api/module/module.api';
import { useLazyTopicListQuery } from 'api/topic/topic.api';
import { ModuleEntity } from 'api/module/types';

export interface TopicOption {
  moduleId: string;
  moduleName: string;
  topics: {
    moduleId: string;
    label: string;
    value: string;
  }[];
}

export const useSelectTopic = () => {
  const [fetchModuleListQuery, { data: moduleListData, isFetching: isFetchingModuleList }] = useLazyModuleListQuery();
  const [fetchTopicListQuery, { isFetching: isFetchingTopicList }] = useLazyTopicListQuery();
  const [topicOptions, setTopicOptions] = useState<TopicOption[]>();

  const fetchModuleList = (courseId: string) => fetchModuleListQuery({ courseId }).unwrap();

  const createTopicOptions = useCallback(
    async (modules: ModuleEntity[] = []) => {
      if (!modules.length) return;

      const result = await Promise.all(
        modules.map(async (module) => {
          const topicListResponse = await fetchTopicListQuery({ moduleId: module.id }).unwrap();

          const topics =
            topicListResponse?.topics.map((topic) => ({
              moduleId: module.id,
              label: topic.name,
              value: topic.id,
            })) || [];

          return {
            moduleId: module.id,
            moduleName: module.name,
            topics,
          };
        })
      );

      setTopicOptions(result.filter((item) => item.topics.length));
    },
    [fetchTopicListQuery, setTopicOptions]
  );

  useEffect(() => {
    if (!moduleListData) return;

    createTopicOptions(moduleListData.modules);
  }, [moduleListData, createTopicOptions]);

  return {
    isFetching: isFetchingModuleList || isFetchingTopicList,
    topicOptions,
    fetchModuleList,
  };
};
