import { apiList } from '../redux/rootReducer';
import { useAppDispatch } from '../redux';

type APIArray = Array<(typeof apiList)[number]>;

export const useClearAPIsCache = (apiArray: APIArray = apiList) => {
  const dispatch = useAppDispatch();

  const handleClearAPIsCache = () => {
    apiArray.forEach((api) => {
      dispatch(api.util.resetApiState());
    });
  };

  return {
    onClearApisCache: handleClearAPIsCache,
  };
};
