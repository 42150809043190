import { TableProps } from 'antd';
import { Button } from '@tml-component-library/src';
import { CloudDownloadOutlined } from '@tml-component-library/src/icons';
import { ComponentFileEntity } from 'api/component-file/types';
import { messages } from '../messages';
import { CATEGORY_TYPES } from '../../../../shared/messages';

export type DocumentListActions = {
  onEdit: (file?: ComponentFileEntity) => void;
};

export const getColumnConfig = (actions: DocumentListActions): TableProps<ComponentFileEntity>['columns'] => [
  {
    title: messages.department,
    dataIndex: ['department', 'name'],
    width: '15%',
    render: (_, { department }) => department?.name,
    sorter: (a, b) => a?.department?.name.localeCompare(b?.department?.name),
  },
  {
    title: messages.course,
    dataIndex: ['course', 'name'],
    width: '20%',
    render: (_, { course }) => course?.name,
    sorter: (a, b) => a?.course?.name.localeCompare(b?.course?.name),
  },
  {
    title: messages.module,
    dataIndex: ['module', 'name'],
    width: '15%',
    render: (_, { module }) => module?.name,
    sorter: (a, b) => a?.module?.name?.localeCompare(b.module?.name),
  },
  {
    title: messages.fileName,
    dataIndex: ['file', 'name'],
    width: '20%',
    sorter: (a, b) => a?.file?.name.localeCompare(b?.file?.name),
  },
  {
    title: messages.fileType,
    dataIndex: ['file', 'category'],
    width: '10%',
    sorter: (a, b) => a?.file?.category.localeCompare(b?.file?.category),
    render: (_, { file }) => CATEGORY_TYPES[file?.category],
  },
  {
    title: messages.version,
    dataIndex: ['file', 'version'],
    width: '10%',
    sorter: (a, b) => a?.file?.version.localeCompare(b?.file?.version),
  },
  {
    title: messages.action,
    width: '10%',
    render: (_, componentFile) => (
      <div className="flex gap-4 items-center">
        <Button
          icon={<CloudDownloadOutlined />}
          type="primary"
          size="small"
          download={componentFile?.file?.name}
          href={componentFile?.file?.downloadLink}
          target="_blank"
          rel="noreferrer"
          ghost
        />

        <Button
          type="link"
          className="pl-0"
          onClick={() => actions.onEdit(componentFile)}
        >
          {messages.edit}
        </Button>
      </div>
    ),
  },
];
