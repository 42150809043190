import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { Modal, CustomFormProvider } from '@tml-component-library/src';
import { FormRequiredMark } from 'components/FormRequiredMark';
import { CompanyEntity } from 'api/company/types';
import { messages } from './messages';
import { ManageCompanyFields } from './constants';
import { useManageCompany } from './useManageCompany';
import { manageCompanyValidationSchema } from './validation.schema';
import { ManageCompanyForm } from './components/ManageCompanyForm';

type ManageCompanyValues = {
  [ManageCompanyFields.COMPANY_NAME]: string;
  [ManageCompanyFields.COMPANY_URL]: string;
  [ManageCompanyFields.INTERNAL_NOTES]: string;
  [ManageCompanyFields.LINKEDIN_URL]: string;
  [ManageCompanyFields.SUBSCRIPTION_START_DATE]: string;
  [ManageCompanyFields.TM_MANAGER]: string;
};

type ManageCompanyProps = {
  open: boolean;
  hide: () => void;
  companyData?: CompanyEntity;
};

export const ManageCompanyModal = ({ open, hide, companyData }: ManageCompanyProps) => {
  const { createCompany, disableCompany, editCompany } = useManageCompany();

  const initialValues: Partial<ManageCompanyValues> = useMemo(
    () => ({
      [ManageCompanyFields.COMPANY_NAME]: companyData?.details?.name,
      [ManageCompanyFields.COMPANY_URL]: companyData?.details?.companyUrl,
      [ManageCompanyFields.LINKEDIN_URL]: companyData?.details?.linkedInUrl,
      [ManageCompanyFields.INTERNAL_NOTES]: companyData?.details?.internalNotes,
      [ManageCompanyFields.SUBSCRIPTION_START_DATE]: companyData?.subscriptionStartDate
        ? dayjs(companyData.subscriptionStartDate).toISOString()
        : undefined,
      [ManageCompanyFields.TM_MANAGER]: companyData?.tmAccountManager,
    }),
    [companyData]
  );

  const onSubmit = useCallback(
    async (values: Partial<ManageCompanyValues>) => {
      const submitValues = {
        ...companyData,
        tmAccountManager: values[ManageCompanyFields.TM_MANAGER],
        subscriptionStartDate: values[ManageCompanyFields.SUBSCRIPTION_START_DATE]
          ? dayjs(values[ManageCompanyFields.SUBSCRIPTION_START_DATE]).toISOString()
          : undefined,
        details: {
          ...companyData?.details,
          companyUrl: values[ManageCompanyFields.COMPANY_URL],
          internalNotes: values[ManageCompanyFields.INTERNAL_NOTES],
          linkedInUrl: values[ManageCompanyFields.LINKEDIN_URL],
          name: values[ManageCompanyFields.COMPANY_NAME],
        },
      };

      if (companyData) {
        await editCompany(submitValues);
      } else {
        await createCompany(submitValues);
      }

      hide();
    },
    [companyData, hide, createCompany, editCompany]
  );

  const onCancel = () => {
    hide();
  };

  const getDisableHandler = () => {
    if (companyData) {
      return async () => {
        await disableCompany(companyData);
        onCancel();
      };
    }
  };

  return (
    <Modal
      title={companyData ? messages.editCompany : messages.newCompany}
      open={open}
      onCancel={onCancel}
      className="py-5 px-6"
      destroyOnClose
      footer={null}
    >
      <CustomFormProvider
        onSubmit={onSubmit}
        validationSchema={manageCompanyValidationSchema}
        initialValues={initialValues}
        requiredMark={FormRequiredMark}
      >
        <ManageCompanyForm
          companyName={companyData?.details?.name}
          handleDisable={getDisableHandler()}
          handleCancel={onCancel}
          status={companyData?.status}
        />
      </CustomFormProvider>
    </Modal>
  );
};
