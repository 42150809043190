import { Meta } from '@tml-component-library/src';
import { EnhancedStudentRoles } from 'features/shared/student-roles-list';

export const CompanyStudentRolesPage = () => (
  <>
    <Meta title="Student Roles" />

    <EnhancedStudentRoles />
  </>
);
