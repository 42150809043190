import { ManageDocumentFields } from './constants';

export const messages = {
  addDocument: 'Add Document',
  editDocument: 'Edit Document',
  save: 'Save',
  cancel: 'Cancel',
  requiredFields: 'Required fields',
  fileName: 'File Name',
  fileVersion: 'File Version',
  description: 'Description',
  departments: 'Departments',
  uploadDocument: 'Upload Document',
  enterFileName: 'Enter file name',
  enterFileVersion: 'Enter file version',
  enterDescription: 'Add a description',
  select: 'Select',
  fetchingCoursesError: 'Fetching courses has been failed.',
  course: 'Course',
  moduleTopics: 'Modules & Topic',
};

export const MANAGE_DOCUMENT_FIELD_LABELS = {
  [ManageDocumentFields.FILE_NAME]: messages.fileName,
  [ManageDocumentFields.FILE_VERSION]: messages.fileVersion,
  [ManageDocumentFields.DESCRIPTION]: messages.description,
  [ManageDocumentFields.DEPARTMENT]: messages.departments,
  [ManageDocumentFields.UPLOAD_DOCUMENT]: messages.uploadDocument,
  [ManageDocumentFields.COURSE]: messages.course,
  [ManageDocumentFields.TOPIC]: messages.moduleTopics,
};

export const MANAGE_DOCUMENT_FIELD_PLACEHOLDERS = {
  [ManageDocumentFields.FILE_NAME]: messages.enterFileName,
  [ManageDocumentFields.FILE_VERSION]: messages.enterFileVersion,
  [ManageDocumentFields.DESCRIPTION]: messages.enterDescription,
  [ManageDocumentFields.DEPARTMENT]: messages.select,
  [ManageDocumentFields.COURSE]: messages.select,
  [ManageDocumentFields.TOPIC]: messages.select,
};
