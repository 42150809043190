export const validationMessages = {
  invalidLinkedinCompanyUrl:
    'Please enter company LinkedIn profile URL that starts with https://www.linkedin.com/company/ followed by the company name or ID',
  invalidLinkedinProfileUrl: 'Please enter personal LinkedIn profile URL',
  invalidCompanyUrl:
    'Please enter a valid Company URL starting with http://, https://, http://www., or https://www.',
  requiredField: 'This field can’t be empty',
  onlySpaces: "This field can't contain only spaces",
  requiredSelection: 'Please make a selection',
  maxCharacters: (max: number) =>
    `Please ensure your input is no longer than ${max} characters`,
  minMaxCharacters: (min: number, max: number) =>
    `Please ensure your input is ${min}-${max} characters long`,
  alphanumericHyphensApostrophesDotsSpaces:
    "Please ensure your input includes only letters (A-Z, a-z), numbers, hyphens (-), apostrophes ('), spaces and dots (.)",
  alphanumericHyphensApostrophesDotsSpacesSlashes:
    "Please ensure your input includes only letters (A-Z, a-z), numbers, hyphens (-), apostrophes ('), slashes (/) and spaces",
  alphabeticalHyphensApostrophesDotsSpaces:
    "Please ensure your input includes only letters (A-Z, a-z), hyphens (-), apostrophes ('), dots (.) and spaces",
  invalidFormat: 'Invalid format',
  invalidEmail: 'Please enter Email in format yourname@example.com',
  invalidPhoneNumber:
    'Please enter your input in the format: +1 (XXX) XXX-XXXX',
  enterValidFormat: (name: string) => `Please enter a valid ${name}`,
  invalidFileName:
    'Please ensure your input does not include special characters \\ / * ? " < > | [ ] $.',
  invalidFileVersion:
    'Please ensure your input includes only alphanumeric characters (A-Z, a-z, 0-9), dots and spaces. Other special characters are not allowed',
  onlyLetters: 'Allowed using only letters',
  removeSpaces: 'Extra space characters',
  allowedOnlyPDF: 'Please ensure the file you upload is in .pdf format',
  allowedDocumentFormats:
    'Please ensure the file you upload is in one of the supported formats:.pdf, doc,.docx, .txt, or .pptx',
};

export const commonMessages = {
  defaultKnowledgeBaseTitle: (
    name: string = 'Knowledge Base',
    version: number = 1,
    isPublished: boolean = false
  ) => `${name} (v${version}.0) - ${isPublished ? 'Published' : 'Unpublished'}`,
  close: 'Close',
  fileName: 'File Name',
  description: 'Description',
  date: 'Date',
  version: 'Version',
};

export const messages = {
  resumeUpload: 'Resume Upload',
  replace: 'Replace',
  cancel: 'Cancel',
  save: 'Save',
  exit: 'Exit',
  somethingWentWrong: 'Something went wrong. Please try again later',
  requiredFields: 'Required fields',
  edit: 'Edit',
  add: 'Add',
  close: 'Close',
  delete: 'Delete',
  confirmDeletion: 'Confirm Deletion',
};
