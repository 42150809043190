import { useCallback } from 'react';
import { useCreateUserMutation, useDeleteUserMutation, useUpdateUserMutation } from 'api/user/user.api';
import { ManageUserRequest } from 'api/user/types';

export const useManageUser = () => {
  const [createUserMutation, { data: createdUserData, isLoading: isCreatingUser }] = useCreateUserMutation();
  const [updateUserMutation, { data: updatedUserData, isLoading: isUpdatingUser }] = useUpdateUserMutation();
  const [deleteUserMutation, { isLoading: isDeleting }] = useDeleteUserMutation();

  const createUser = useCallback((body: ManageUserRequest) => createUserMutation(body).unwrap(), [createUserMutation]);

  const updateUser = useCallback((body: ManageUserRequest) => updateUserMutation(body).unwrap(), [updateUserMutation]);

  const deleteUser = useCallback((userId: string) => deleteUserMutation(userId).unwrap(), [deleteUserMutation]);

  return {
    isCreatingUser,
    isUpdatingUser,
    createUser,
    updateUser,
    updatedUserData,
    createdUserData,
    deleteUser,
    isDeleting,
  };
};
