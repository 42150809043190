import { Empty, Spin } from 'antd';
import { ReactNode } from 'react';
import { messages } from './messages';

interface CardsLayoutProps {
  showEmpty: boolean;
  isLoading: boolean;
  showContent: boolean;
  children: ReactNode;
  emptyText?: ReactNode;
  footer?: ReactNode;
}

export const CardsLayout = ({
  isLoading,
  showEmpty,
  showContent,
  children,
  footer,
  emptyText = messages.emptyData,
}: CardsLayoutProps) => (
  <div className="flex flex-col max-h-full gap-6 overflow-auto -mx-6 px-6">
    {isLoading && <Spin className="m-6" />}

    {showContent && children}

    {showEmpty && (
      <>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={emptyText}
        />
        {footer}
      </>
    )}
  </div>
);
