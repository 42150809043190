import { RocketFilled, ReadOutlined, ExperimentFilled } from '@tml-component-library/src/icons';
import { Routes } from 'router/routes';
import { NavigationLayout } from 'common/navigation-layout';
import { messages } from './messages';

const SUPER_ADMIN_NAVIGATION_OPTIONS = [
  {
    key: Routes.companies(),
    icon: <RocketFilled />,
    label: messages.companies,
  },
  {
    key: Routes.courseManagement(),
    icon: <ExperimentFilled />,
    label: messages.courseManagement,
  },
  {
    key: Routes.supplements(),
    icon: <ReadOutlined />,
    label: messages.supplements,
  },
  // TODO: Uncomment when pages will be implemented
  // {
  //   key: Routes.kbSettings(),
  //   icon: <ExperimentOutlined />,
  //   label: messages.kbSettings,
  // },
  // {
  //   key: Routes.manageAccess(),
  //   icon: <TeamOutlined />,
  //   label: messages.manageAccess,
  // },
];

export const StaffHome = () => <NavigationLayout items={SUPER_ADMIN_NAVIGATION_OPTIONS} />;
