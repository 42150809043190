import { Key, useEffect, useMemo } from 'react';
import { Tree, Typography } from 'antd';
import { CaretDownOutlined } from '@tml-component-library/src/icons';
import { messages } from '../messages';
import { useCourseTemplateDetailsContext } from '../CourseTemplateDetailsProvider';
import { getDefaultTreeKeys, prepareTreeData, getTopicById, getModuleById, getModuleByTopicId } from '../utils';
import { useTreeStructure } from '../../../../hooks';

export const Sidebar = () => {
  const { courseTemplateDetailsData, selectComponent, isLoading } = useCourseTemplateDetailsContext();

  const { defaultKeys, treeData } = useMemo(
    () => ({
      treeData: prepareTreeData(courseTemplateDetailsData?.modules),
      defaultKeys: getDefaultTreeKeys(courseTemplateDetailsData?.modules),
    }),
    [courseTemplateDetailsData]
  );

  const { selectedKeys, onSelect, isRoot } = useTreeStructure(defaultKeys);

  useEffect(() => {
    const [id] = (selectedKeys as string[]) || [];

    const selectedComponent = isRoot
      ? getModuleById(id, courseTemplateDetailsData?.modules)
      : getTopicById(id, getModuleByTopicId(id, courseTemplateDetailsData?.modules));

    selectComponent(selectedComponent!);
  }, [isRoot, selectedKeys, selectComponent, courseTemplateDetailsData]);

  return (
    <div className="overflow-hidden relative">
      <div className="flex flex-col h-full overflow-auto relative pt-0">
        <div className="sticky top-0 bg-white z-10">
          <Typography.Title
            className="!font-medium"
            level={4}
          >
            {messages.courseStructure}
          </Typography.Title>
        </div>

        {!isLoading && (
          <Tree
            key={courseTemplateDetailsData?.id}
            blockNode
            showLine
            switcherIcon={<CaretDownOutlined />}
            defaultExpandedKeys={[treeData?.[0].key as Key]}
            defaultSelectedKeys={defaultKeys}
            selectedKeys={selectedKeys}
            onSelect={onSelect}
            treeData={treeData}
          />
        )}
      </div>
    </div>
  );
};
