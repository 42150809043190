import { TreeProps, Typography } from 'antd';
import { CourseTemplateTopicEntity } from 'api/course-template/types';
import { SelectInfoProps } from 'hooks';

interface TopicsProps {
  onSelect: TreeProps['onSelect'];
  topics?: CourseTemplateTopicEntity[];
  isPublished: boolean;
}

export const Topics = ({ topics, onSelect, isPublished }: TopicsProps) => (
  <div
    className={`flex flex-col gap-2.5 h-full w-full p-4 mt-4 overflow-y-auto border rounded-lg border-solid border-gray-200 ${isPublished ? 'mb-4' : 'mb-20'}`}
  >
    {topics?.map(({ id, name, moduleId }) => (
      <Typography.Link
        key={id}
        className="font-bold text-base"
        onClick={() => onSelect?.([id], { node: { isRoot: false, parentId: moduleId } } as SelectInfoProps)}
      >
        {name}
      </Typography.Link>
    ))}
  </div>
);
