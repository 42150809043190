import { ReactNode } from 'react';
import { Button, ButtonProps } from '@tml-component-library/src';
import { messages } from './messages';

type ManageStudentFooterProps = {
  actionDisabled?: boolean;
  cancelText?: string;
  onCancel?: () => void;
  onSave?: () => Promise<void>;
  onEdit?: () => void;
  onDelete?: () => Promise<void>;
  deleteConfirmationProps?: ButtonProps['confirmProps'];
  saveText?: string;
  leftSide?: ReactNode;
  editSaveLoading?: boolean;
};

export const ManageEntityModalFooter = ({
  onCancel,
  onSave,
  onEdit,
  onDelete,
  actionDisabled,
  saveText = messages.save,
  cancelText = messages.cancel,
  deleteConfirmationProps,
  leftSide,
  editSaveLoading,
}: ManageStudentFooterProps) => {
  return (
    <div className="flex justify-between">
      <div className="flex gap-2.5 items-center">
        {onDelete && (
          <Button
            disabled={actionDisabled}
            onClick={onDelete}
            confirmProps={deleteConfirmationProps}
            danger
          >
            {messages.delete}
          </Button>
        )}
        {leftSide}
      </div>
      <div className="flex gap-2">
        {onCancel && (
          <Button
            disabled={actionDisabled}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        )}
        {onSave && (
          <Button
            type="primary"
            disabled={actionDisabled}
            onClick={onSave}
            loading={editSaveLoading}
          >
            {saveText}
          </Button>
        )}
        {onEdit && (
          <Button
            type="primary"
            disabled={actionDisabled}
            onClick={onEdit}
            loading={editSaveLoading}
          >
            {messages.edit}
          </Button>
        )}
      </div>
    </div>
  );
};
