import { Radio as AntRadio, RadioChangeEvent } from 'antd';
import { useState } from 'react';

type RadioOption<Value> = {
  label: string;
  value: Value;
};

type RadioGroupProps<Value> = {
  defaultValue: Value;
  options: RadioOption<Value>[];
  onChange: (value: Value) => void;
};

export const RadioGroup = <Value extends unknown>({
  defaultValue,
  options,
  onChange,
}: RadioGroupProps<Value>) => {
  const [value, setValue] = useState<Value>();

  const handleChange = (event: RadioChangeEvent) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <AntRadio.Group
      className="w-full flex justify-between"
      onChange={handleChange}
      value={value}
      defaultValue={defaultValue}
    >
      {options.map(({ label, value: optionValue }) => {
        return (
          <AntRadio.Button
            key={label}
            className="w-full text-center"
            value={optionValue}
          >
            {label}
          </AntRadio.Button>
        );
      })}
    </AntRadio.Group>
  );
};
