import { theme, Typography } from 'antd';
import { Button, convertDateFormat, DAY_FORMAT } from '@tml-component-library/src';
import { KnowledgeBaseEntity } from 'api/knowledge-base/types';
import { AssignedKnowledgeBase } from 'api/user/types';
import { messages } from '../messages';
import { useRoleDetailsQuery } from '../../../../api/role/role.api';

interface AvailableKbListItemProps {
  knowledgeBase: KnowledgeBaseEntity;
  showAssignButton: boolean;
  disabled: boolean;
  onAssign: () => void;
  userRoleId: string;
  assignedKb?: AssignedKnowledgeBase;
}

export const AvailableKbListItem = ({
  knowledgeBase,
  showAssignButton,
  disabled,
  onAssign,
  assignedKb,
  userRoleId,
}: AvailableKbListItemProps) => {
  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const { data: { titleAbbreviated } = {} } = useRoleDetailsQuery({ id: userRoleId });

  return (
    <div
      key={knowledgeBase?.id}
      className="flex gap-2.5 items-center justify-between"
    >
      <div className="flex gap-2 overflow-hidden w-full">
        <Typography.Text>•</Typography.Text>

        <div className="flex flex-col w-full truncate">
          <Typography.Text className="text-sm text-nowrap truncate">
            {messages.knowledgeBaseName(knowledgeBase?.name, knowledgeBase?.version, titleAbbreviated || '')}
          </Typography.Text>

          <Typography.Text className="text-xs text-nowrap truncate">
            {assignedKb
              ? `${messages.assigned} ${convertDateFormat(assignedKb?.kbAssignedAt, DAY_FORMAT)}`
              : `${messages.created} ${convertDateFormat(knowledgeBase?.createdAt, DAY_FORMAT)}`}
          </Typography.Text>
        </div>
      </div>

      {showAssignButton && (
        <Button
          style={{
            padding: '0 1rem',
            borderRadius: borderRadiusLG,
          }}
          ghost
          size="small"
          type="primary"
          onClick={onAssign}
          disabled={disabled}
        >
          {messages.assign}
        </Button>
      )}
    </div>
  );
};
