import { colors } from './colors';

const antColors = require('@ant-design/colors');

export const tailwindTheme = {
  extend: {
    fontFamily: {
      roboto: ['Roboto', 'sans-serif'],
      gelasio: ['Gelasio', 'sans-serif'],
    },
    colors: {
      ant: { ...antColors },
      custom: { ...colors },
    },
    keyframes: {
      wiggle: {
        '0%': {
          bottom: '-100px',
          position: 'absolute',
        },
        '99%': {
          bottom: 0,
          position: 'absolute',
        },
        '100%': { bottom: 0, position: 'static' },
      },
    },
    animation: {
      wiggle: 'wiggle 0.3s ease-out',
    },
  },
  screens: {
    'laptop-small': '1280px',
    laptop: '1440px',
    desktop: '1920px',
  },
};
