export const messages = {
  enterCompanyName: 'Enter company name',
  enterDomainIndustry: 'Enter domain / industry',
  internalCompanyName: 'Name of Company (as used in internal communication)',
  domainIndustry: 'Domain / Industry',
  editCompanyDetails: 'Edit Company Details',
  addCompanyDetails: 'Add Company Details',
  successSave: 'Company Details have been saved',
  successUpdate: 'Company Details have been updated',
  errorUpdate: "Company Details haven't been updated",
} as const;
