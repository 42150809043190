import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { once } from 'lodash';
import { useExchangeTokenMutation, useSignInMutation } from 'api/auth/auth.api';
import { useActivateUserMutation } from 'api/user/user.api';
import { LoginAsRequest, SignInRequest } from 'api/auth/types';
import { authRepository } from './auth.repository';
import { useLoginAs } from './hooks/useLoginAs';

interface AuthProviderProps {
  children: ReactNode;
}

type Callback = (status: ExchangeTokenStatuses) => void;

interface AuthContextType {
  isAuthorized: boolean;
  signInLoading: boolean;
  exchangeTokenLoading: boolean;
  activateUserLoading: boolean;
  signIn: (data: SignInRequest) => Promise<void>;
  exchangeToken: (token: string, callback: Callback, isActivated: boolean) => void;
  signOut: () => void;
  loginAs: (params: LoginAsRequest, access: boolean) => Promise<void>;
  isEnabledAccessToCompany: boolean;
}

export enum ExchangeTokenStatuses {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  TOKEN_NOT_EXIST = 'TOKEN_NOT_EXIST',
}

export const AuthContext = createContext<AuthContextType>({
  isAuthorized: false,
} as AuthContextType);

const defaultIsHasSession = authRepository.isHasSession();

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [signInQuery, { isLoading: signInLoading }] = useSignInMutation();
  const [exchangeTokenQuery, { isLoading: exchangeTokenLoading }] = useExchangeTokenMutation();
  const [activateUserQuery, { isLoading: activateUserLoading }] = useActivateUserMutation();
  const { loginAs, isEnabledAccessToCompany, clearAccessToCompany } = useLoginAs();

  const [isAuthorized, setIsAuthorized] = useState(defaultIsHasSession);

  const signIn = useCallback((body: SignInRequest) => signInQuery(body).unwrap(), [signInQuery]);

  const signOut = useCallback(() => {
    authRepository.clear();
    setIsAuthorized(false);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const exchangeToken = useCallback(
    once(async (oneTimeToken: string, callback: Callback, isActivated: boolean) => {
      clearAccessToCompany();
      authRepository.setAuthTokens({
        accessToken: oneTimeToken,
        refreshToken: '',
      });

      try {
        let tokens;

        if (isActivated) {
          tokens = await exchangeTokenQuery().unwrap();
        } else {
          tokens = await activateUserQuery().unwrap();
        }

        authRepository.setHasSession(true);
        setIsAuthorized(true);
        authRepository.setAuthTokens(tokens);
        callback(ExchangeTokenStatuses.SUCCESS);
      } catch (error) {
        authRepository.clear();
        setIsAuthorized(false);
        callback(ExchangeTokenStatuses.ERROR);
      }
    }),
    [exchangeTokenQuery, activateUserQuery, clearAccessToCompany]
  );

  const context = useMemo(
    () => ({
      signIn,
      signOut,
      signInLoading,
      exchangeTokenLoading,
      activateUserLoading,
      exchangeToken,
      isAuthorized,
      loginAs,
      isEnabledAccessToCompany,
    }),
    [
      signIn,
      signOut,
      signInLoading,
      exchangeTokenLoading,
      activateUserLoading,
      exchangeToken,
      isAuthorized,
      loginAs,
      isEnabledAccessToCompany,
    ]
  );

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};
