import { Divider, Typography } from 'antd';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { TopicEntity } from 'api/topic/types';
import { ModuleEntity } from 'api/module/types';

export interface ModuleTopicViewProps {
  isModule: boolean;
  isStaff: boolean;
  data?: TopicEntity | ModuleEntity;
  onEdit: () => void;
}

export const ModuleTopicView = ({ isStaff, isModule, data, onEdit }: ModuleTopicViewProps) => {
  const { name, description } = data || {};
  /*
   * Show for staff member
   * Disable editing Module for customer admin member
   */
  const isEditable = !isModule || isStaff;

  return (
    <>
      {name && (
        <Typography.Title
          className="!font-bold break-normal"
          level={5}
        >
          {name}
        </Typography.Title>
      )}

      {description && <Typography.Text className="break-normal">{description}</Typography.Text>}

      <Divider className={`mt-2.5 ${isEditable ? 'mb-2.5' : 'mb-5'}`} />

      {isEditable && <ManageEntityModalFooter onEdit={onEdit} />}
    </>
  );
};
