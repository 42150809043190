import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { Modal, Button } from '@tml-component-library/src';
import { useUser } from 'common/user/useUser';
import { KnowledgeBaseEntity } from 'api/knowledge-base/types';
import { knowledgeBaseRepository } from '../knowledgeBase.repository';
import { messages } from '../messages';

interface NotificationModalProps {
  knowledgeBase?: KnowledgeBaseEntity;
}

export const NotificationModal = ({ knowledgeBase }: NotificationModalProps) => {
  const { data } = useUser();
  const [isNotificationHidden, setIsNotificationHidden] = useState<boolean>(true);

  const uniqNotificationPostfix = `${data?.companyId}-${knowledgeBase?.id}-${knowledgeBase?.version}-${knowledgeBase?.regenerationVersion}`;

  useEffect(() => {
    if (data && knowledgeBase) {
      const isHidden = Boolean(knowledgeBaseRepository.isNotificationHidden(uniqNotificationPostfix));

      setIsNotificationHidden(isHidden);
    }
  }, [data, knowledgeBase, uniqNotificationPostfix]);

  const hideNotificationModal = () => {
    knowledgeBaseRepository.setNotificationHidden(true, uniqNotificationPostfix);
    setIsNotificationHidden(true);
  };

  return (
    <Modal
      title={null}
      open={!isNotificationHidden}
      onCancel={hideNotificationModal}
      width={520}
      footer={null}
      destroyOnClose
    >
      <div className="mt-10 flex flex-col justify-center gap-8">
        <div className="px-4 flex flex-col gap-5">
          <Typography.Title
            className="!mb-0 font-medium text-center whitespace-pre-wrap"
            level={3}
          >
            {messages.courseGenerationComplete}
          </Typography.Title>
          <Typography.Paragraph className="!mb-0 text-center whitespace-pre-wrap text-xl">
            {messages.pleaseReview}
          </Typography.Paragraph>
        </div>

        <div className="w-full flex justify-center">
          <Button
            type="primary"
            onClick={hideNotificationModal}
          >
            {messages.okImReady}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
