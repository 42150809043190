import { Key } from 'react';
import { CourseTemplateModuleEntity } from 'api/course-template/types';

export const prepareTreeData = (modules?: CourseTemplateModuleEntity[]) =>
  modules?.map((module) => ({
    ...module,
    title: <span className="font-bold">{module.name}</span>,
    key: module.id,
    isRoot: true,
    selectable: true,
    children: module?.topics?.map((topic) => ({
      ...topic,
      key: topic.id,
      title: topic.name,
    })),
  }));

export type TreeData = ReturnType<typeof prepareTreeData>;

export const getDefaultTreeKeys = (modules?: CourseTemplateModuleEntity[]) => (modules?.[0]?.id ? [modules[0].id] : []);

export const getModuleByTopicId = (id: Key, modules?: CourseTemplateModuleEntity[]) =>
  modules?.find((module) => module.topics.some(({ id: topicId }) => topicId === id));

export const getModuleById = (id: Key, modules?: CourseTemplateModuleEntity[]) =>
  modules?.find((module) => module.id === id);

export const getTopicById = (id: Key, module?: CourseTemplateModuleEntity) =>
  module?.topics.find(({ id: topicId }) => topicId === id);
