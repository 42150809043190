import { Radio, RadioChangeEvent } from 'antd';
import { CourseTemplateRoleEntity } from 'api/course-template/types';

interface SidebarRolesProps {
  onChange: (event: RadioChangeEvent) => void;
  roleId?: string;
  roles?: CourseTemplateRoleEntity[];
}

export const SidebarRoles = ({ onChange, roleId, roles }: SidebarRolesProps) => (
  <div className="flex justify-between">
    <Radio.Group
      className="pb-2.5"
      onChange={onChange}
      defaultValue={roleId}
    >
      {roles?.map(({ id: value, titleAbbreviated }) => (
        <Radio.Button
          key={value}
          value={value}
        >
          {titleAbbreviated}
        </Radio.Button>
      ))}
    </Radio.Group>
  </div>
);
