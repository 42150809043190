import { useState } from 'react';
import { Typography } from 'antd';
import { ManageRoleFooter } from 'features/shared/manage-role/components/ManageRoleFooter';
import { ManageRole } from 'features/shared/manage-role/ManageRole';
import { RoleEntity } from 'api/role/types';

interface ManageCompanyProps {
  role: RoleEntity;
}

export const StudentRoleCard = ({ role }: ManageCompanyProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing((edit) => !edit);
  };

  return (
    <div
      className={`p-4 rounded-lg border-solid border-[1px] ${
        isEditing ? 'border-custom-base-blue-6 bg-custom-natural-gray-3' : 'border-custom-natural-gray-5'
      }`}
    >
      {isEditing ? (
        <ManageRole
          role={role}
          onCancel={handleEditClick}
        />
      ) : (
        <>
          <div className="flex flex-col">
            <Typography.Title level={5}>
              {role?.titleAbbreviated} ({role?.titleFull})
            </Typography.Title>
            <Typography.Text>{role?.description}</Typography.Text>
          </div>
          <ManageRoleFooter onCancel={handleEditClick} />
        </>
      )}
    </div>
  );
};
