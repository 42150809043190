import { useNavigate } from 'react-router';
import { Modal, Button, messages as sharedMessages } from '@tml-component-library/src';
import { Routes } from 'router/routes';
import { ReactNode } from 'react';
import { messages } from '../../../shared/courses-list/messages';

interface CourseCreationRequirementsModalProps {
  open: boolean;
  hideModal: () => void;
  content: ReactNode;
}

export const KbCreationRequirementsModal = ({ open, hideModal, content }: CourseCreationRequirementsModalProps) => {
  const navigate = useNavigate();

  const navigateToCompanySettings = () => {
    navigate(Routes.companySettings());
    hideModal();
  };

  return (
    <Modal
      title={messages.courseCreationRequirement}
      open={open}
      className="py-5 px-6"
      width={630}
      onCancel={hideModal}
      footer={null}
      destroyOnClose
    >
      <div>
        <div>{content}</div>

        <div className="w-full flex justify-end gap-2">
          <Button onClick={hideModal}>{sharedMessages.close}</Button>
          <Button
            type="primary"
            onClick={navigateToCompanySettings}
          >
            {messages.goToCompanySettings}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
