import { Divider } from 'antd';
import { TextInput, useFormContext, FormField } from '@tml-component-library/src';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { ManageModuleTopicFields } from '../constants';
import { MANAGE_MODULE_TOPIC_FIELDS_LABELS, MANAGE_MODULE_TOPIC_FIELDS_PLACEHOLDERS } from '../messages';

interface ManageModuleTopicFormProps {
  isLoading: boolean;
  onCancel: () => void;
}

export const ManageModuleTopicForm = ({ onCancel, isLoading }: ManageModuleTopicFormProps) => {
  const { isSubmitting, submit } = useFormContext();

  return (
    <>
      <FormField
        name={ManageModuleTopicFields.NAME}
        className="m-0"
        label={MANAGE_MODULE_TOPIC_FIELDS_LABELS[ManageModuleTopicFields.NAME]}
      >
        <TextInput
          placeholder={MANAGE_MODULE_TOPIC_FIELDS_PLACEHOLDERS[ManageModuleTopicFields.NAME]}
          required
          disabled
        />
      </FormField>

      <FormField
        name={ManageModuleTopicFields.DESCRIPTION}
        className="m-0 pt-2.5"
        label={MANAGE_MODULE_TOPIC_FIELDS_LABELS[ManageModuleTopicFields.DESCRIPTION]}
      >
        <TextInput.TextArea
          disabled={isLoading}
          placeholder={MANAGE_MODULE_TOPIC_FIELDS_PLACEHOLDERS[ManageModuleTopicFields.DESCRIPTION]}
          rows={4}
        />
      </FormField>

      <Divider className="my-2.5" />

      <ManageEntityModalFooter
        onSave={submit}
        onCancel={onCancel}
        actionDisabled={isLoading || isSubmitting}
      />
    </>
  );
};
