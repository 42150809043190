import { TeamOutlined, DeploymentUnitOutlined, FundProjectionScreenOutlined } from '@tml-component-library/src/icons';
import { NavigationLayout } from 'common/navigation-layout';
import { Routes } from 'router/routes';
import { messages } from './messages';

const CUSTOMER_ADMIN_NAVIGATION_OPTIONS = [
  {
    key: Routes.companySettings(),
    icon: <DeploymentUnitOutlined />,
    label: messages.companySettings,
  },
  {
    key: Routes.companyDepartments(),
    icon: <FundProjectionScreenOutlined />,
    label: messages.departments,
  },
  {
    key: Routes.usersManagement(),
    icon: <TeamOutlined />,
    label: messages.userManagement,
  },
];

export const AdminHome = () => <NavigationLayout items={CUSTOMER_ADMIN_NAVIGATION_OPTIONS} />;
