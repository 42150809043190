import { Spin, Typography } from 'antd';
import { useCourseDetailsQuery } from 'api/course/course.api';
import { Card } from 'components/Card';
import { CreateKnowledgeBaseButton } from './CreateKnowledgeBaseButton';
import { messages } from '../messages';

interface KnowledgeBaseInitialCardProps {
  courseId: string;
}

export const KnowledgeBaseInitialCard = ({ courseId }: KnowledgeBaseInitialCardProps) => {
  const { data: { name: courseName } = {}, isFetching: isFetchingCourseDetails } = useCourseDetailsQuery(courseId);

  if (isFetchingCourseDetails) {
    return <Spin className="m-6" />;
  }

  return (
    <Card
      title={
        <>
          <Typography.Text className="text-2xl font-medium">{courseName}</Typography.Text>{' '}
          <Typography.Text className="text-base italic">{messages.noVersion}</Typography.Text>
        </>
      }
      content={
        <div className="w-full flex justify-between items-center gap-2.5">
          <div className="flex flex-col gap-2.5 text-center w-full">
            <div className="flex justify-center gap-2.5 w-full">
              <CreateKnowledgeBaseButton />
            </div>
          </div>
        </div>
      }
    />
  );
};
