import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertMessage, Button } from '@tml-component-library/src';
import { useUser } from 'common/user/useUser';
import { useLazyCompanyDetailsQuery } from 'api/company/company.api';
import { resetRoleApiState } from 'api/role/role.api';
import { UserAuthoritiesEntity } from 'api/user/types';
import { Routes } from 'router/routes';
import { useAuth } from 'common/auth';
import { messages } from './messages';
import { useAppDispatch } from '../../redux';

export const ReturnToTMStaffNotification = () => {
  const navigate = useNavigate();
  const { loginAs } = useAuth();
  const { data: { companyId } = {} } = useUser();
  const dispatch = useAppDispatch();
  const [getCompanyDetails, { data }] = useLazyCompanyDetailsQuery();

  const handleReturnToTMStaffPanel = useCallback(async () => {
    try {
      await loginAs(
        {
          role: UserAuthoritiesEntity.ROLE_STAFF,
        },
        false
      );
      dispatch(resetRoleApiState());
      navigate(Routes.staff());
    } catch {
      /*
       * Global error handler catch error and show notification
       */
    }
  }, [dispatch, loginAs, navigate]);

  useEffect(() => {
    if (companyId) getCompanyDetails(companyId);
  }, [companyId, getCompanyDetails]);

  return (
    <AlertMessage
      showIcon
      type="info"
      message={messages.loggedInAsCustomerAdminAt(data?.details?.name)}
      action={
        <Button
          size="small"
          type="primary"
          onClick={handleReturnToTMStaffPanel}
        >
          {messages.returnToTMStaffPanel}
        </Button>
      }
    />
  );
};
