import { Button, Table } from '@tml-component-library/src/components';
import { useModal } from '@tml-component-library/src';
import { useCompanyListQuery } from 'api/company/company.api';
import { columnConfig } from './configs/tableConfig';
import { messages } from './messages';
import { ManageCompanyModal } from '../manage-company/ManageCompanyModal';

export const CompanyList = () => {
  const { open, showModal, hideModal } = useModal();
  const { data, isLoading } = useCompanyListQuery();

  const companies = data?.companies;

  return (
    <div className="max-h-full flex flex-col gap-4 overflow-auto">
      <Table
        loading={isLoading}
        columns={columnConfig}
        dataSource={companies || []}
        rowKey="id"
        sticky
        pagination={false}
      />

      <Button
        type="primary"
        onClick={showModal}
      >
        {messages.createNewCompanyAccount}
      </Button>

      <ManageCompanyModal
        open={open}
        hide={hideModal}
      />
    </div>
  );
};
