import { useEffect } from 'react';
import { useLazyUserAssignedKbListQuery } from 'api/user/user.api';

export const useAssignedKbList = (userId?: string) => {
  const [fetchUserAssignKbListTrigger, { data: assignedKbs = [], isFetching: isAssignedKbsLoading }] =
    useLazyUserAssignedKbListQuery();

  useEffect(() => {
    if (userId) fetchUserAssignKbListTrigger(userId);
  }, [userId, fetchUserAssignKbListTrigger]);

  return {
    assignedKbs,
    isAssignedKbsLoading,
  };
};
