import { compiler, MarkdownToJSX } from 'markdown-to-jsx';
// @ts-expect-error: No declaration file for module
import { createEditorState } from 'medium-draft';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { convertToRaw, EditorState } from 'draft-js';
import { EXTENDED_STYLE_ITEMS } from '../constants';

export const convertToEditorValue = (value: string) =>
  createEditorState(
    markdownToDraft(value, {
      blockStyles: {
        ins_open: 'UNDERLINE', // Applying underline styles for toolbar button and connect with ins
      },
      remarkableOptions: {
        enable: {
          inline: 'ins', // For enabling underlining from the box
        },
      },
    })
  );

export const convertToMarkdownValue = (state: EditorState) =>
  draftToMarkdown(convertToRaw(state?.getCurrentContent()), {
    styleItems: EXTENDED_STYLE_ITEMS,
  });

export const compileMarkdownToJSX = (
  content: string,
  props?: MarkdownToJSX.Options
) =>
  compiler(
    content.replace(
      /\+\+([^+]+)\+\+/g,
      (_: string, match: string) => `<u>${match}</u>`
    ),
    {
      ...props,
      overrides: {
        a: {
          component: ({ children, ...linkProps }) => (
            <a
              {...linkProps}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          ),
        },
        ...props?.overrides,
      },
    }
  );
