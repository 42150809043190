import * as yup from 'yup';
import {
  ALPHABETICAL_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX,
  EMAIL_REGEX,
  LINKEDIN_PROFILE_REGEX,
  PHONE_REGEX,
  validationMessages,
} from '@tml-component-library/src';
import { ACCEPTED_CV_FORMATS, ManageStudentFields } from './constants';

export const manageStudentValidationSchema = yup.object().shape({
  [ManageStudentFields.FIRST_NAME]: yup
    .string()
    .trim()
    .matches(ALPHABETICAL_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX, {
      message: validationMessages.alphabeticalHyphensApostrophesDotsSpaces,
      excludeEmptyString: true,
    })
    .max(40, validationMessages.minMaxCharacters(1, 40))
    .required(validationMessages.requiredField),
  [ManageStudentFields.LAST_NAME]: yup
    .string()
    .trim()
    .matches(ALPHABETICAL_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX, {
      message: validationMessages.alphabeticalHyphensApostrophesDotsSpaces,
      excludeEmptyString: true,
    })
    .max(40, validationMessages.minMaxCharacters(1, 40))
    .required(validationMessages.requiredField),
  [ManageStudentFields.PHONE_NUMBER]: yup.string().trim().notRequired().matches(PHONE_REGEX, {
    message: validationMessages.invalidPhoneNumber,
    excludeEmptyString: true,
  }),
  [ManageStudentFields.SECONDARY_PHONE_NUMBER]: yup.string().trim().notRequired().matches(PHONE_REGEX, {
    message: validationMessages.invalidPhoneNumber,
    excludeEmptyString: true,
  }),
  [ManageStudentFields.EMAIL]: yup
    .string()
    .trim()
    .email(validationMessages.invalidEmail)
    .matches(EMAIL_REGEX, { message: validationMessages.invalidEmail, excludeEmptyString: true })
    .max(255, validationMessages.maxCharacters(255))
    .required(validationMessages.requiredField),
  [ManageStudentFields.STUDENT_ROLE]: yup.string().required(validationMessages.requiredField),
  [ManageStudentFields.DEPARTMENT]: yup.string().required(validationMessages.requiredField),
  [ManageStudentFields.START_DATE]: yup.date().required(validationMessages.requiredField),
  [ManageStudentFields.LINKEDIN_URL]: yup
    .string()
    .trim()
    .lowercase()
    .test({
      message: validationMessages.invalidLinkedinProfileUrl,
      test: (value) => {
        const transformedValue = value?.toLowerCase();

        return !!transformedValue && LINKEDIN_PROFILE_REGEX.test(transformedValue);
      },
    })
    .max(255, validationMessages.maxCharacters(255))
    .required(validationMessages.requiredField),
  [ManageStudentFields.CV]: yup
    .mixed()
    .test({
      message: validationMessages.allowedOnlyPDF,
      test: (value) => {
        if (value instanceof File) {
          const file = value as File;
          return ACCEPTED_CV_FORMATS.includes(file.type);
        }

        return true;
      },
    })
    .required(validationMessages.requiredField),
});
