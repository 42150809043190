import React from 'react';
import { NavLink } from 'react-router-dom';
import { TableProps } from 'antd';
import { CompanyEntity } from 'api/company/types';
import { Statuses } from 'components/Statuses';
import { convertDateFormat } from '@tml-component-library/src/utils/convertDateFormat';
import { Routes } from 'router/routes';
import { messages } from '../messages';

export const columnConfig: TableProps<CompanyEntity>['columns'] = [
  {
    title: messages.company,
    dataIndex: ['details', 'name'],
    width: '28%',
    sorter: ({ details: { name: aName } }, { details: { name: bName } }) => aName!.localeCompare(bName!),
  },
  {
    title: messages.status,
    dataIndex: 'status',
    width: '11%',
    render: (_, { status }) => status && <Statuses status={status} />,
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
  {
    title: messages.students,
    dataIndex: 'numberOfStudent',
    width: '11%',
    sorter: (a, b) => a.numberOfStudent - b.numberOfStudent,
  },
  {
    title: messages.lastActivity,
    dataIndex: 'lastActivityAt',
    width: '40%',
    render: (_, { lastActivityAt }) => (lastActivityAt ? convertDateFormat(lastActivityAt) : ''),
    sorter: ({ lastActivityAt: a = '' }, { lastActivityAt: b = '' }) => a.localeCompare(b),
  },
  {
    title: messages.action,
    width: '10%',
    render: (_, { id }) => <NavLink to={Routes.companyDetails(id)}>{messages.view}</NavLink>,
  },
];
