export const messages = {
  settingSuccessfullyUpdated: 'Student Role Setting is successfully updated',
  quizRequirements: 'Quiz Requirements',
  open: 'Open Questions',
  multiple: 'Multiple Choice',
  interactiveLearningExercise: 'Interactive Learning Exercise',
  enterInteractiveLearningExercise: 'Enter your requirements for Interactive Learning Exercise',
  requirementsForStudentRoles: 'Requirements for Student Roles',
  addILE: 'Add ILE',
};
