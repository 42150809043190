import { useState } from 'react';
import { Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import { Modal, Button, RadioGroup, commonMessages } from '@tml-component-library/src';
import { Routes } from 'router/routes';
import { SourceModalList } from './SourceModalList';
import { KnowledgeBaseCardTitle } from './KnowledgeBaseCardTitle';
import { messages } from '../messages';

type KnowledgeBaseSourcesModalProps = {
  open: boolean;
  knowledgeBaseName: string;
  version: number;
  hide: () => void;
  isCurrentVersion?: boolean;
  courseName?: string;
  publishedAt?: string;
};

enum Tab {
  COMPANY_DOCUMENTS = 'companyDocuments',
}

const tabItems = [
  {
    label: messages.companyDocuments,
    value: Tab.COMPANY_DOCUMENTS,
  },
];

export const KnowledgeBaseSourcesModal = ({
  open,
  courseName = '',
  knowledgeBaseName,
  version,
  hide,
  isCurrentVersion = false,
  publishedAt,
}: KnowledgeBaseSourcesModalProps) => {
  const [activeTab, setActiveTab] = useState(Tab.COMPANY_DOCUMENTS);

  const changeActiveTab = (value: Tab) => setActiveTab(value);

  return (
    <Modal
      width={700}
      open={open}
      title={courseName ? messages.knowledgeBaseSourceMaterials(courseName) : null}
      onCancel={hide}
      footer={null}
      destroyOnClose
    >
      <div className="flex flex-col gap-2.5">
        <div>
          <KnowledgeBaseCardTitle
            fontSize="medium"
            version={version}
            kbName={knowledgeBaseName}
            isPublished={Boolean(publishedAt)}
            publishedAt={publishedAt}
            isCurrentVersion={isCurrentVersion}
          />
        </div>

        <div className="hidden w-full">
          <RadioGroup
            defaultValue={activeTab}
            options={tabItems}
            onChange={changeActiveTab}
          />
        </div>

        <SourceModalList />

        <div className="flex justify-end">
          <div className="w-full flex items-center text-sm italic gap-1">
            <Typography.Text>{messages.youCanManageFiles}</Typography.Text>{' '}
            <NavLink to={Routes.companySettings()}>
              <Typography.Link>{messages.companySettings}</Typography.Link>
            </NavLink>
          </div>
          <Button
            onClick={hide}
            type="primary"
          >
            {commonMessages.close}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
