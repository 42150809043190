import { ManageAdminFields } from './constants';

export const messages = {
  createNewAdmin: 'Create New Admin Member',
  editAdmin: 'Edit Admin Member',
  save: 'Save',
  requiredFields: 'Required fields',
  resendLink: 'Resend Invite',
  firstName: 'First Name',
  lastName: 'Last Name',
  titleRole: 'Title/Company Role',
  phoneNumber: 'Phone Number',
  secondPhoneNumber: 'Secondary Phone Number',
  email: 'Email',
  enterFirstName: 'Enter First Name',
  enterLastName: 'Enter Last Name',
  enterTitleRole: 'Enter Title or Company Role',
  enterPhoneNumber: 'Enter Phone Number',
  enterSecondPhoneNumber: 'Enter Phone Number',
  enterEmail: 'Enter Email Address',
  select: 'Select',

  deleteAdmin: (name?: string) => `Delete the admin “${name}”`,
};

export const MANAGE_ADMIN_FIELD_LABELS = {
  [ManageAdminFields.FIRST_NAME]: messages.firstName,
  [ManageAdminFields.LAST_NAME]: messages.lastName,
  [ManageAdminFields.COMPANY_ROLE]: messages.titleRole,
  [ManageAdminFields.PHONE_NUMBER]: messages.phoneNumber,
  [ManageAdminFields.SECONDARY_PHONE_NUMBER]: messages.secondPhoneNumber,
  [ManageAdminFields.EMAIL]: messages.email,
};

export const MANAGE_ADMIN_FIELD_PLACEHOLDERS = {
  [ManageAdminFields.FIRST_NAME]: messages.enterFirstName,
  [ManageAdminFields.LAST_NAME]: messages.enterLastName,
  [ManageAdminFields.COMPANY_ROLE]: messages.enterTitleRole,
  [ManageAdminFields.PHONE_NUMBER]: messages.enterPhoneNumber,
  [ManageAdminFields.SECONDARY_PHONE_NUMBER]: messages.enterPhoneNumber,
  [ManageAdminFields.EMAIL]: messages.enterEmail,
};
