import { Typography } from 'antd';
import { Badge, Button } from '@tml-component-library/src';
import { CheckSquareOutlined, InfoCircleFilled, CourseStructureImage } from '@tml-component-library/src/icons';
import { LegendItem } from './LegendItem';
import { messages } from '../messages';

const LEGEND_SCHEMA = [
  {
    icon: <CheckSquareOutlined />,
    text: messages.moduleLegend,
  },
  {
    icon: (
      <Badge
        dot
        status="error"
        classNames={{
          indicator: 'ring-1 ring-white',
        }}
      />
    ),
    text: messages.currentlyEmpty,
  },
  {
    icon: (
      <Badge
        dot
        status="warning"
        classNames={{
          indicator: 'ring-1 ring-white',
        }}
      />
    ),
    text: messages.partiallyEmpty,
  },
  {
    icon: (
      <Badge
        dot
        status="success"
        classNames={{
          indicator: 'ring-1 ring-white',
        }}
      />
    ),
    text: messages.contentComplete,
  },
] as const;

export const Legend = () => {
  const openCourseStructureImage = () => {
    const courseStructureImage = document.getElementById('course-structure-image');
    courseStructureImage!.style.width = '498px';
    courseStructureImage!.style.height = '576px';
    const url = courseStructureImage!.getAttribute('src');

    window.open(
      url!,
      '_blank',
      'width=courseStructureImage.stylewidth,height=courseStructureImage.style.height,resizable=1'
    );
  };

  return (
    <div className="p-4 flex flex-col gap-2.5 border border-solid border-custom-natural-gray-5 bg-custom-natural-gray-3">
      <div className="flex gap-2.5">
        <Typography.Text className="!font-bold text-sm">{messages.legend}</Typography.Text>

        <div className="flex flex-col gap-2.5">
          <LegendItem
            text={LEGEND_SCHEMA[0].text}
            icon={LEGEND_SCHEMA[0].icon}
          />
          <div className="flex flex-row gap-5">
            {LEGEND_SCHEMA.slice(1).map(({ text, icon }) => (
              <LegendItem
                key={text}
                text={text}
                icon={icon}
              />
            ))}
          </div>
        </div>
      </div>

      <div>
        <img
          id="course-structure-image"
          className="hidden"
          src={CourseStructureImage}
          alt="Course structure"
        />
        <Button
          className="p-0"
          type="link"
          onClick={openCourseStructureImage}
          icon={<InfoCircleFilled />}
        >
          {messages.learnHow}
        </Button>
      </div>
    </div>
  );
};
