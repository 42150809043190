export const messages = {
  manageDocuments: 'Manage Documents',
  fileName: 'File Name',
  fileVersion: 'File Version',
  description: 'Description',
  uploadDocument: 'Upload Document',
  enterDocumentName: 'Enter file name',
  enterDocumentVersion: 'Enter file version',
  addDescription: 'Add a description',
  createFileSuccess: 'Document has been successfully uploaded',
  documentsAdded: 'Documents Added',
  availableSupplements: 'Available Supplements',
  upload: 'Upload',
  done: 'Done',
  suggestedSupplements: 'Time Machine suggests these Supplement Documents:',
  downloadTemplateDocument: 'Download Template Document',
  editToCustomize: 'Edit to Customize Document',
  uploadCustomizedDocument: 'Upload Customized Document',
  useResourcesAbove: 'Use the resources above in three simple steps:',
} as const;
