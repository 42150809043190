import dayjs from 'dayjs';
import { Button } from '@tml-component-library/src';
import { DAY_FORMAT } from '@tml-component-library/src/shared/constants';
import { Statuses } from 'components/Statuses';
import { UserEntity } from 'api/user/types';
import { UserListColumns } from 'common/user-list-table';
import { messages } from '../messages';

type StudentListActions = {
  onEdit: (userProfile?: UserEntity) => void;
  onAssign: (userProfile?: UserEntity) => void;
};

const getCourses = (courses: string[] = []) => courses.join(', ');

export const getColumnConfig = (actions: StudentListActions): UserListColumns => [
  {
    title: messages.firstName,
    dataIndex: ['profile', 'firstName'],
    width: '16%',
    sorter: (a, b) => a.profile.firstName?.localeCompare(b.profile.firstName),
  },
  {
    title: messages.lastName,
    dataIndex: ['profile', 'lastName'],
    width: '16%',
    sorter: (a, b) => a.profile.lastName?.localeCompare(b.profile.lastName),
  },
  {
    title: messages.email,
    dataIndex: ['profile', 'email'],
    width: '16%',
    sorter: (a, b) => a.profile.email?.localeCompare(b.profile.email),
  },
  {
    title: messages.role,
    dataIndex: ['role', 'name'],
    width: '8%',
    sorter: (a, b) => a.role?.name?.localeCompare(b.role?.name),
  },
  {
    title: messages.startDate,
    dataIndex: ['profile', 'startDate'],
    width: '11%',
    sorter: (a, b) => a.profile.startDate?.localeCompare(b.profile.startDate),
    render: (_, { profile }) => dayjs(profile.startDate).format(DAY_FORMAT),
  },
  {
    title: messages.courses,
    dataIndex: ['profile', 'courseNames'],
    width: '10%',
    render: (_, { profile: { courseNames } }) => getCourses(courseNames),
    sorter: ({ profile: { courseNames: aCourseNames } }, { profile: { courseNames: bCourseNames } }) =>
      getCourses(aCourseNames).localeCompare(getCourses(bCourseNames)),
  },
  {
    title: messages.status,
    dataIndex: 'status',
    width: '10%',
    render: (_, { status }) => <Statuses status={status} />,
    sorter: (a, b) => a.status?.localeCompare(b.status),
  },
  {
    title: messages.action,
    width: '13%',
    dataIndex: ['profile'],
    render: (_, user) => {
      const showAssign = Boolean(user?.department?.name && user?.role?.name);
      const showEdit = Boolean(user?.id);

      const items = [
        ...(showAssign ? [{ title: messages.assign, action: actions.onAssign }] : []),
        ...(showEdit
          ? [
              {
                title: messages.edit,
                action: actions.onEdit,
              },
            ]
          : []),
      ];

      return items.map(({ title, action }) => (
        <Button
          key={title}
          type="link"
          className="pl-0"
          onClick={() => action(user)}
        >
          {title}
        </Button>
      ));
    },
  },
];
