import { useCompanyDetailsQuery } from 'api/company/company.api';
import { useUserSelfQuery } from 'api/user/user.api';
import { CompanyProfile } from 'components/CompanyProfile';
import { Routes } from 'router/routes';
import { Tabs } from '@tml-component-library/src';
import { useState } from 'react';
import { CompanyStudentLearningDetails } from '../company-student-learning-details';
import { useHeader } from '../../../hooks';
import { CompanyDocumentList } from '../company-document-list';
import { CompanyProductList } from '../company-product-list';
import { messages } from './messages';

const enum CompanySettingsTabs {
  COMPANY_DETAILS = 'companyDetails',
  PRODUCTS = 'products',
  DOCUMENTS = 'documents',
  // TODO: uncomment when is is needed
  // VIDEOS = 'videos',
}

const COMPANY_SETTINGS_TABS_CONFIG = [
  {
    label: messages.companyDetails,
    key: CompanySettingsTabs.COMPANY_DETAILS,
    children: <CompanyStudentLearningDetails />,
  },
  {
    label: messages.products,
    key: CompanySettingsTabs.PRODUCTS,
    children: <CompanyProductList />,
  },
  {
    label: messages.documents,
    key: CompanySettingsTabs.DOCUMENTS,
    children: <CompanyDocumentList />,
  },
  // TODO: uncomment when is is needed
  // {
  //   label: messages.videos,
  //   key: CompanySettingsTabs.VIDEOS,
  //   children: 'Test4',
  // },
];

export const CompanySettings = () => {
  const [activeTab, setActiveTab] = useState<CompanySettingsTabs>(CompanySettingsTabs.COMPANY_DETAILS);

  const handleChangeTab = (activeKey: string) => setActiveTab(activeKey as CompanySettingsTabs);

  const { data: userSelfData } = useUserSelfQuery();
  const { data, isFetching } = useCompanyDetailsQuery(userSelfData!.companyId);

  useHeader(Routes.companySettings(), messages.companySettings);

  return (
    <div className="flex flex-col max-h-full gap-6 overflow-hidden">
      <CompanyProfile
        {...data}
        disabledEdit={isFetching || !data}
      />

      <Tabs
        className="h-full overflow-hidden [&>.ant-tabs-content-holder]:overflow-auto"
        defaultActiveKey={activeTab}
        items={COMPANY_SETTINGS_TABS_CONFIG}
        onChange={handleChangeTab}
      />
    </div>
  );
};
