import { Typography } from 'antd';
import { useState } from 'react';
import { Button } from '@tml-component-library/src/components';
import { useModal } from '@tml-component-library/src';
import { UserListTable } from 'common/user-list-table';
import { useUser } from 'common/user/useUser';
import { ManageAdminModal } from 'features/shared/manage-admin/ManageAdminModal';
import { UserAuthoritiesEntity, UserEntity } from 'api/user/types';
import { getColumnConfig } from './configs/tableConfig';
import { messages } from './messages';

export const AdminList = () => {
  const [selectedUser, setSelectedUser] = useState<UserEntity | undefined>();
  const { data, isLoading } = useUser();
  const { showModal, hideModal, open } = useModal();

  const showManageAdminModal = (userProfile?: UserEntity) => {
    setSelectedUser(userProfile);
    showModal();
  };

  return (
    <>
      <Typography.Title
        className="!font-medium"
        level={5}
      >
        {messages.adminUsers}
      </Typography.Title>

      <UserListTable
        companyId={data?.companyId}
        columns={getColumnConfig({
          edit: showManageAdminModal,
          currentUserId: data?.id,
        })}
        userRole={UserAuthoritiesEntity.ROLE_ADMIN}
        isInitialLoading={isLoading}
      />

      <Button
        className="my-4"
        type="primary"
        onClick={() => showManageAdminModal()}
      >
        {messages.createNewAdmin}
      </Button>

      {data && (
        <ManageAdminModal
          companyId={data!.companyId}
          open={open}
          hideModal={hideModal}
          adminUser={selectedUser}
        />
      )}
    </>
  );
};
