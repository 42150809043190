import { CustomFormProvider, Modal, useToast } from '@tml-component-library/src';
import { useMemo } from 'react';
import { ManageCompanyRequest, ManageCompanyResponse } from 'api/company/types';
import { useEditCompanyMutation } from 'api/company/company.api';
import { FormRequiredMark } from 'components/FormRequiredMark';
import { ManageDetailsForm } from './components';
import { manageStudentLearningDetailsValidationSchema } from './validation.schema';
import { ManageDetailsFields } from './constants';
import { messages } from './messages';
import { ManageStudentLearningDetailsFormValues } from './types';

interface ManageCompanyStudentLearningDetailsProps {
  hideModal: () => void;
  open: boolean;
  data?: ManageCompanyResponse;
}

export const ManageCompanyStudentLearningDetails = ({
  open,
  data,
  hideModal,
}: ManageCompanyStudentLearningDetailsProps) => {
  const [updateCompanyLearningDetailsMutation] = useEditCompanyMutation();
  const { showSuccess } = useToast();

  const handleSubmit = async (values: ManageStudentLearningDetailsFormValues) => {
    const requestBody: ManageCompanyRequest = {
      ...data,
      details: {
        ...data?.details,
        internalName: values[ManageDetailsFields.COMPANY_NAME],
        domain: values[ManageDetailsFields.DOMAIN_INDUSTRY],
      },
    };

    await updateCompanyLearningDetailsMutation(requestBody).unwrap();

    hideModal();

    if (data) {
      showSuccess(messages.successUpdate);
    } else {
      showSuccess(messages.successSave);
    }
  };

  const initialValue = useMemo(() => {
    return {
      [ManageDetailsFields.COMPANY_NAME]: data?.details?.internalName || '',
      [ManageDetailsFields.DOMAIN_INDUSTRY]: data?.details?.domain || '',
    };
  }, [data]);

  return (
    <Modal
      title={data ? messages.editCompanyDetails : messages.addCompanyDetails}
      open={open}
      className="py-5 px-6"
      onCancel={hideModal}
      footer={null}
      destroyOnClose
    >
      <CustomFormProvider
        onSubmit={handleSubmit}
        requiredMark={FormRequiredMark}
        validationSchema={manageStudentLearningDetailsValidationSchema}
        initialValues={initialValue}
      >
        <ManageDetailsForm onCancel={hideModal} />
      </CustomFormProvider>
    </Modal>
  );
};
