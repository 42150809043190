import { ReactNode } from 'react';
import { Checkbox as AntCheckbox } from 'antd';

interface CheckboxProps {
  value: any;
  onChange: (event: any) => void;
  checked: boolean;
  disabled?: boolean;
  children?: ReactNode;
}

export const Checkbox = ({
  value,
  onChange,
  disabled,
  checked,
  children,
}: CheckboxProps) => {
  return (
    <AntCheckbox
      value={value}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    >
      {children}
    </AntCheckbox>
  );
};
