import React from 'react';
import { Tag } from 'antd';
import { Status } from 'api/company/types';
import { UserStatus } from 'api/user/types';
import { capitalizeFirstLetter } from '@tml-component-library/src/utils/capitalizeFirstLetter';

interface StatusesProps {
  status: Status | UserStatus;
}

export const StatusColors = {
  [UserStatus.ACTIVE]: 'success',
  [UserStatus.PENDING]: 'default',
  [UserStatus.INACTIVE]: 'error',
  default: 'default',
};

export const Statuses = ({ status }: StatusesProps) => (
  <Tag
    color={StatusColors[status]}
    key={status}
  >
    {capitalizeFirstLetter(status)}
  </Tag>
);
