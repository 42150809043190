import { NavLink } from 'react-router-dom';
import { Button } from '@tml-component-library/src';
import { useDepartmentListQuery } from 'api/department/department.api';
import { Card } from 'components/Card';
import { CardsLayout } from 'components/CardsLayout';
import { Routes } from 'router/routes';
import { messages } from './messages';

interface DepartmentListProps {
  isStaff?: boolean;
}

export const DepartmentList = ({ isStaff }: DepartmentListProps) => {
  const { data: { departments } = {}, isLoading } = useDepartmentListQuery();

  const showContent = !isLoading && Boolean(departments?.length);
  const showEmpty = !isLoading && !departments?.length;

  return (
    <CardsLayout
      isLoading={isLoading}
      showEmpty={showEmpty}
      showContent={showContent}
      emptyText={messages.noDepartments}
    >
      {departments?.map(({ id, name }) => (
        <Card
          key={id}
          title={name}
          content={
            <>
              <NavLink to={isStaff ? Routes.roles(id) : Routes.companyRoles(id)}>
                <Button
                  type="primary"
                  disabled={isLoading}
                >
                  {messages.studentRoles}
                </Button>
              </NavLink>

              <NavLink to={isStaff ? Routes.courses(id) : Routes.companyCourses(id)}>
                <Button
                  type="primary"
                  disabled={isLoading}
                >
                  {messages.viewCourses}
                </Button>
              </NavLink>
            </>
          }
        />
      ))}
    </CardsLayout>
  );
};
