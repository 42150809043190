import { PhoneTypes } from 'api/user/types';

export enum ManageAdminFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY_ROLE = 'companyRole',
  PHONE_NUMBER = 'phoneNumber',
  PHONE_NUMBER_TYPE = 'phoneNumberType',
  SECONDARY_PHONE_NUMBER = 'secondaryPhoneNumber',
  SECONDARY_PHONE_NUMBER_TYPE = 'secondaryPhoneNumberType',
  EMAIL = 'email',
}

export enum ManageAdminActions {
  CREATE = 'create',
  EDIT = 'edit',
}

export const PHONE_NUMBER_OPTIONS = [
  {
    label: 'Mobile',
    value: PhoneTypes.MOBILE,
  },
  {
    label: 'Office',
    value: PhoneTypes.OFFICE,
  },
  {
    label: 'Home',
    value: PhoneTypes.HOME,
  },
  {
    label: 'Other',
    value: PhoneTypes.OTHER,
  },
];
