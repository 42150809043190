export enum TemplateType {
  STAFF_COURSE_TEMPLATE = 'STAFF_COURSE_TEMPLATE',
  ADMIN_COURSE_TEMPLATE = 'ADMIN_COURSE_TEMPLATE',
}

export enum LevelType {
  STANDARD = 'STANDARD',
  PROGRESSIVE = 'PROGRESSIVE',
  COMPLEX = 'COMPLEX',
}

export interface CommonEntity {
  id: string;
  name: string;
}

export interface CommonRelatedEntity {
  id: string;
  name: string;
  description: string;
}

export interface RelatedDepartmentEntity extends CommonRelatedEntity {}

export interface RelatedCourseEntity extends CommonRelatedEntity {
  id: string;
  departmentId: string;
}

export interface RelatedRoleEntity extends Omit<CommonRelatedEntity, 'name'> {
  titleFull: string;
  titleAbbreviated: string;
}

export interface RelatedModuleEntity extends CommonRelatedEntity {
  courseId: string;
  courseName: string;
  departmentId: string;
  departmentName: string;
}

export interface RelatedTopicEntity extends Omit<CommonRelatedEntity, 'description'> {}

export interface RelatedSubModuleEntity extends CommonRelatedEntity {
  subModuleId: string;
}
