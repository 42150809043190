import { Typography } from 'antd';
import dayjs from 'dayjs';
import { DAY_FORMAT } from '@tml-component-library/src';
import { messages } from '../messages';

type CourseTemplateTitleProps = {
  courseTemplateName: string;
  version: number;
  publishedAt?: string;
  isCurrentVersion?: boolean;
};

export const CourseTemplateTitle = ({
  courseTemplateName,
  version,
  publishedAt,
  isCurrentVersion = false,
}: CourseTemplateTitleProps) => (
  <>
    <Typography.Text className="text-2xl font-medium">{courseTemplateName}</Typography.Text>{' '}
    <Typography.Text className="text-base italic font-normal">
      {messages.courseTemplateVersion(version)}
    </Typography.Text>
    {publishedAt && (
      <Typography.Text className="text-base italic font-normal">
        {' '}
        - {messages.publishedAt(dayjs(publishedAt).format(DAY_FORMAT))}
      </Typography.Text>
    )}
    {isCurrentVersion && (
      <Typography.Text className="text-base italic font-normal text-custom-base-blue-6">
        {' '}
        - {messages.currentVersion}
      </Typography.Text>
    )}
  </>
);
