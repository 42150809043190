import { useCallback, useMemo } from 'react';
import { Schema } from 'yup';
import { Modal, useToast, CustomFormProvider } from '@tml-component-library/src';
import { Typography } from 'antd';
import { useDeleteQuestionMutation, useUpdateQuestionDetailsMutation } from 'api/quiz/quiz.api';
import { QuestionEntity, QuestionOptionsEntity, QuestionType } from 'api/quiz/types';
import { messages } from './messages';
import { ManageQuestionForm } from './components/ManageQuestionForm';
import { ManageQuestionFormField } from './constants';
import {
  manageILEQuestionFormValidationSchema,
  manageMultipleQuestionFormValidationSchema,
  manageOpenQuestionFormValidationSchema,
} from './validation.schema';

interface ManageQuestionModalProps {
  editHeaderTitle: string;
  open: boolean;
  hideModal: () => void;
  question: QuestionEntity;
}

interface ManageQuestionFormValues {
  [ManageQuestionFormField.QUESTION_TEXT]: string;
  [ManageQuestionFormField.ANSWER]: string;
  [ManageQuestionFormField.ANSWER_OPTIONS]?: QuestionOptionsEntity;
}

export const validationSchemasByQuestionType: Record<QuestionType, Schema> = {
  [QuestionType.ILE_ESSAY]: manageILEQuestionFormValidationSchema,
  [QuestionType.OPEN]: manageOpenQuestionFormValidationSchema,
  [QuestionType.MULTIPLE]: manageMultipleQuestionFormValidationSchema,
};

export const ManageQuestionModal = ({ open, hideModal, question, editHeaderTitle }: ManageQuestionModalProps) => {
  const { showSuccess } = useToast();
  const [deleteQuestionMutation, { isLoading: isDeleting }] = useDeleteQuestionMutation();
  const [updateQuestionMutation] = useUpdateQuestionDetailsMutation();

  const { questionId, questionText, questionOptions, correctAnswer: answer, questionType } = question;

  const onDelete = useCallback(async () => {
    await deleteQuestionMutation(questionId).unwrap();
    showSuccess(messages.questionDeleted);

    hideModal();
  }, [showSuccess, hideModal, deleteQuestionMutation, questionId]);

  const onSave = useCallback(
    async (values: ManageQuestionFormValues) => {
      await updateQuestionMutation({
        id: questionId,
        questionText: values[ManageQuestionFormField.QUESTION_TEXT],
        correctAnswer: values[ManageQuestionFormField.ANSWER],
        questionOptions: values[ManageQuestionFormField.ANSWER_OPTIONS],
      }).unwrap();

      showSuccess(messages.questionUpdated);

      hideModal();
    },
    [showSuccess, questionId, updateQuestionMutation, hideModal]
  );

  const initialValues = useMemo(() => {
    return {
      [ManageQuestionFormField.QUESTION_TEXT]: questionText,
      [ManageQuestionFormField.ANSWER]: answer,
      [ManageQuestionFormField.ANSWER_OPTIONS]: questionOptions,
    };
  }, [questionText, answer, questionOptions]);

  return (
    <Modal
      title={messages.editQuestion}
      open={open}
      onCancel={hideModal}
      width={700}
      footer={null}
      destroyOnClose
    >
      <div className="flex flex-col gap-2.5">
        <Typography.Text>{editHeaderTitle}</Typography.Text>

        <CustomFormProvider
          onSubmit={onSave}
          validationSchema={validationSchemasByQuestionType[questionType]}
          initialValues={initialValues}
        >
          <ManageQuestionForm
            actionDisabled={isDeleting}
            onCancel={hideModal}
            onDelete={onDelete}
            question={question}
          />
        </CustomFormProvider>
      </div>
    </Modal>
  );
};
