import * as yup from 'yup';
import { ALPHANUMERIC_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX, validationMessages } from '@tml-component-library/src';
import { ManageDetailsFields } from './constants';

export const manageStudentLearningDetailsValidationSchema = yup.object().shape({
  [ManageDetailsFields.COMPANY_NAME]: yup
    .string()
    .trim()
    .matches(ALPHANUMERIC_HYPHENS_APOSTROPHES_DOTS_SPACES_REGEX, {
      message: validationMessages.alphanumericHyphensApostrophesDotsSpaces,
      excludeEmptyString: true,
    })
    .max(40, validationMessages.minMaxCharacters(1, 40))
    .required(validationMessages.requiredField),
  [ManageDetailsFields.DOMAIN_INDUSTRY]: yup
    .string()
    .trim()
    .max(255, validationMessages.maxCharacters(255))
    .required(validationMessages.requiredField),
});
