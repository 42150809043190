import { useEffect } from 'react';
import { Divider } from 'antd';
import {
  CustomFormInputLabel,
  Select,
  TextInput,
  UploadDragger,
  UploadDraggerOnChangeHandler,
  UploadedList,
  useFormContext,
  FormField,
  messages as sharedMessages,
} from '@tml-component-library/src';
import { useSelectCourse, useSelectDepartment } from 'hooks';
import { messages as commonMessages } from 'shared/messages';
import { ConfirmationDescription } from 'components/ConfirmationDescription';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { ACCEPTED_FILE_FORMATS, ManageDocumentFields } from '../constants';
import {
  CATEGORY_OPTIONS,
  MANAGE_SUPPLEMENT_DOCUMENT_FIELD_LABELS,
  MANAGE_SUPPLEMENT_DOCUMENT_FIELD_PLACEHOLDERS,
  messages,
} from '../messages';
import { useSelectModule } from '../hooks';

interface ManageDocumentFormProps {
  onCancel: () => void;
  onDelete?: () => Promise<void>;
  documentName?: string;
}

export const ManageSupplementDocumentForm = ({ onCancel, onDelete, documentName }: ManageDocumentFormProps) => {
  const { values, setFieldValue, isSubmitting, submit, initialValues } = useFormContext();

  const { isLoading: isFetchingDepartments, options: departmentsOptions } = useSelectDepartment();
  const { isCoursesLoading: isFetchingCourseList, coursesOptions, fetchCourseList } = useSelectCourse();
  const { isFetchingModuleList, fetchModuleList, options: modulesOptions } = useSelectModule();

  const courseSelectOptions = coursesOptions || [
    { label: messages.select, value: initialValues[ManageDocumentFields.COURSE_TEMPLATE] },
  ];
  const modulesSelectOptions = modulesOptions || [
    { label: messages.select, value: initialValues[ManageDocumentFields.MODULES] },
  ];
  const departmentsSelectOptions = departmentsOptions || [
    { label: messages.select, value: initialValues[ManageDocumentFields.DEPARTMENTS] },
  ];

  useEffect(() => {
    const departmentId = initialValues[ManageDocumentFields.DEPARTMENTS];
    const courseId = initialValues[ManageDocumentFields.COURSE_TEMPLATE];

    if (departmentId) fetchCourseList(departmentId);
    if (courseId) fetchModuleList(courseId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeDepartment = (value: string) => {
    fetchCourseList(value);
    setFieldValue(ManageDocumentFields.DEPARTMENTS, value);

    /* setFieldValue triggers onChange event which triggers validation and validation message will disappear even if new value is undefined
    validationTrigger prop works unexpectedly https://github.com/ant-design/ant-design/issues/6538, so we can't show message only onSubmit,
    so we just don't set value when value of student role is falsy to avoid message disappearing */
    if (values[ManageDocumentFields.COURSE_TEMPLATE]) setFieldValue(ManageDocumentFields.COURSE_TEMPLATE, undefined);
    if (values[ManageDocumentFields.MODULES]) setFieldValue(ManageDocumentFields.MODULES, undefined);
  };

  const onChangeCourse = (value: string) => {
    fetchModuleList(value);
    setFieldValue(ManageDocumentFields.COURSE_TEMPLATE, value);
    /* setFieldValue triggers onChange event which triggers validation and validation message will disappear even if new value is undefined
    validationTrigger prop works unexpectedly https://github.com/ant-design/ant-design/issues/6538, so we can't show message only onSubmit,
    so we just don't set value when value of student role is falsy to avoid message disappearing */
    if (values[ManageDocumentFields.MODULES]) setFieldValue(ManageDocumentFields.MODULES, undefined);
  };

  const onChangeFile: UploadDraggerOnChangeHandler = ({ fileList }) => {
    const value = fileList[0]?.originFileObj;
    setFieldValue(ManageDocumentFields.UPLOAD_DOCUMENT, value);
  };

  const isThereInitialFileValue =
    values[ManageDocumentFields.UPLOAD_DOCUMENT] && typeof values[ManageDocumentFields.UPLOAD_DOCUMENT] === 'string';

  const fileName = typeof values.document === 'string' ? values.document : (values.document as File)?.name;

  const isSelectDepartmentDisabled = isSubmitting || !departmentsSelectOptions.length || isFetchingDepartments;

  const isSelectCourseDisabled =
    isSubmitting ||
    isFetchingDepartments ||
    !departmentsOptions?.length ||
    !values[ManageDocumentFields.DEPARTMENTS]?.length ||
    isFetchingCourseList ||
    !coursesOptions?.length;

  const isSelectModuleDisabled =
    isSubmitting ||
    isSelectCourseDisabled ||
    !values[ManageDocumentFields.COURSE_TEMPLATE]?.length ||
    isFetchingModuleList ||
    !modulesSelectOptions.length;

  const deleteConfirmationProps = {
    title: sharedMessages.confirmDeletion,
    description: <ConfirmationDescription description={commonMessages.deleteDocument(documentName)} />,
    okText: sharedMessages.delete,
    cancelText: sharedMessages.cancel,
  };

  return (
    <div>
      <div className="flex flex-col gap-2.5">
        <div className="flex flex-row gap-2.5">
          <FormField
            name={ManageDocumentFields.FILE_NAME}
            className="w-full m-0"
            label={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_LABELS[ManageDocumentFields.FILE_NAME]}
            required
          >
            <TextInput
              placeholder={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.FILE_NAME]}
              required
            />
          </FormField>
        </div>

        <div className="flex flex-row gap-2.5">
          <FormField
            name={ManageDocumentFields.FILE_CATEGORY}
            className="w-full m-0"
            label={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_LABELS[ManageDocumentFields.FILE_CATEGORY]}
            required
          >
            <Select
              allowClear
              placeholder={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.FILE_CATEGORY]}
              options={CATEGORY_OPTIONS}
              disabled
            />
          </FormField>

          <FormField
            name={ManageDocumentFields.FILE_VERSION}
            className="w-full m-0"
            label={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_LABELS[ManageDocumentFields.FILE_VERSION]}
            required
          >
            <TextInput placeholder={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.FILE_VERSION]} />
          </FormField>
        </div>

        <div className="w-full flex flex-row gap-2.5 mb-2.5">
          <FormField
            name={ManageDocumentFields.DEPARTMENTS}
            label={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_LABELS[ManageDocumentFields.DEPARTMENTS]}
            className="w-full m-0 truncate"
            required
          >
            <Select
              onChange={onChangeDepartment}
              placeholder={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.DEPARTMENTS]}
              loading={isFetchingDepartments}
              disabled={isSelectDepartmentDisabled}
              options={departmentsSelectOptions}
            />
          </FormField>

          <FormField
            label={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_LABELS[ManageDocumentFields.COURSE_TEMPLATE]}
            name={ManageDocumentFields.COURSE_TEMPLATE}
            className="w-full m-0 truncate"
            required
          >
            <Select
              loading={isFetchingCourseList}
              options={courseSelectOptions}
              disabled={isSelectCourseDisabled}
              onChange={onChangeCourse}
              placeholder={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.COURSE_TEMPLATE]}
            />
          </FormField>

          <FormField
            name={ManageDocumentFields.MODULES}
            className="w-full m-0 truncate"
            label={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_LABELS[ManageDocumentFields.MODULES]}
            required
          >
            <Select
              placeholder={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_PLACEHOLDERS[ManageDocumentFields.MODULES]}
              loading={isFetchingModuleList}
              disabled={isSelectModuleDisabled}
              options={modulesSelectOptions}
            />
          </FormField>
        </div>
      </div>

      <FormField
        name={ManageDocumentFields.UPLOAD_DOCUMENT}
        label={MANAGE_SUPPLEMENT_DOCUMENT_FIELD_LABELS[ManageDocumentFields.UPLOAD_DOCUMENT]}
        {...(!isThereInitialFileValue ? { required: true } : {})}
      >
        {!isThereInitialFileValue ? (
          <UploadDragger
            uploadProps={{
              beforeUpload: () => false,
              multiple: false,
              maxCount: 1,
              accept: ACCEPTED_FILE_FORMATS.join(','),
              onChange: onChangeFile,
              withCredentials: true,
            }}
          />
        ) : (
          <UploadedList name={fileName} />
        )}
      </FormField>

      <Divider className="my-2.5" />

      <CustomFormInputLabel
        content={messages.requiredFields}
        required
      />

      <div className="mt-3">
        <ManageEntityModalFooter
          deleteConfirmationProps={deleteConfirmationProps}
          actionDisabled={isSubmitting}
          onDelete={onDelete}
          onCancel={onCancel}
          onSave={submit}
          editSaveLoading={isSubmitting}
        />
      </div>
    </div>
  );
};
