import * as yup from 'yup';
import { validationMessages } from '@tml-component-library/src';
import { QuestionType } from 'api/quiz/types';
import { ManageRoleSettingsFields } from './constants';

export const manageRoleSettingsValidationSchema = yup.object().shape({
  [ManageRoleSettingsFields[QuestionType.MULTIPLE]]: yup.number().required(validationMessages.requiredField),
  [ManageRoleSettingsFields[QuestionType.OPEN]]: yup.number().required(validationMessages.requiredField),
  [ManageRoleSettingsFields.ILE_ESSAY]: yup.array().of(
    yup.object().shape({
      id: yup.string().trim().notRequired(),
      requirement: yup.string().trim().notRequired(),
    })
  ),
});
