import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { DEFAULT_LIST_PARAMS } from 'shared';
import {
  CourseTemplateShortListRequest,
  CourseTemplateShortListResponse,
  CourseTemplateDetailsRequest,
  CourseTemplateDetailsResponse,
  CreateCourseTemplateRequest,
  RoleSettingEntity,
  CourseTemplateModuleListResponse,
  CourseTemplateModuleListRequest,
  CourseTemplateModuleDetailsRequest,
  CourseTemplateModuleEntity,
  CourseTemplateTopicListRequest,
  CourseTemplateTopicDetailsRequest,
  CourseTemplateTopicListResponse,
  CourseTemplateTopicEntity,
} from './types';

export const courseTemplateApi = createApi({
  reducerPath: 'course-template',
  tagTypes: [
    'courseTemplateList',
    'courseTemplateDetails',
    'roleSetting',
    'courseTemplateModuleList',
    'courseTemplateModuleDetails',
    'courseTemplateTopicList',
    'courseTemplateTopicDetails',
  ],
  baseQuery: axiosQuery({ baseUrl: '/template' }),
  endpoints: (builder) => ({
    courseTemplateList: builder.query<CourseTemplateShortListResponse, CourseTemplateShortListRequest>({
      query: (params) => ({
        url: `/list`,
        params: { ...DEFAULT_LIST_PARAMS, ...params },
      }),
      providesTags: ['courseTemplateList'],
    }),

    courseTemplateDetails: builder.query<CourseTemplateDetailsResponse, CourseTemplateDetailsRequest>({
      query: ({ id, ...params }) => ({
        url: `/${id}`,
        params,
      }),
      providesTags: ['courseTemplateDetails'],
    }),

    createCourseTemplate: builder.mutation<CourseTemplateDetailsResponse, CreateCourseTemplateRequest>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['courseTemplateList', 'courseTemplateDetails'],
    }),

    editRoleSetting: builder.mutation<RoleSettingEntity, RoleSettingEntity>({
      query: (body) => ({
        url: '/setting',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['roleSetting'],
    }),

    roleSettingDetails: builder.query<RoleSettingEntity, string>({
      query: (id) => ({
        url: `/setting/${id}`,
      }),
      providesTags: ['roleSetting'],
    }),

    courseTemplateModuleList: builder.query<CourseTemplateModuleListResponse, CourseTemplateModuleListRequest>({
      query: (params) => ({
        url: '/module/list',
        params: { ...DEFAULT_LIST_PARAMS, ...params },
      }),
      providesTags: ['courseTemplateModuleList'],
    }),

    courseTemplateModuleDetails: builder.query<CourseTemplateModuleEntity, CourseTemplateModuleDetailsRequest>({
      query: ({ id, ...params }) => ({
        url: `/module/${id}`,
        params,
      }),
      providesTags: ['courseTemplateModuleDetails'],
    }),

    updateCourseTemplateModuleDetails: builder.mutation<CourseTemplateModuleEntity, CourseTemplateModuleEntity>({
      query: (body) => ({
        url: '/module',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['courseTemplateModuleList', 'courseTemplateModuleDetails'],
    }),

    courseTemplateTopicList: builder.query<CourseTemplateTopicListResponse, CourseTemplateTopicListRequest>({
      query: ({ ...params }) => ({
        url: `/topic/list`,
        params: { ...DEFAULT_LIST_PARAMS, ...params },
        method: 'GET',
      }),
      providesTags: ['courseTemplateTopicList'],
    }),

    courseTemplateTopicDetails: builder.query<CourseTemplateTopicEntity, CourseTemplateTopicDetailsRequest>({
      query: ({ id, ...params }) => ({
        url: `/topic/${id}`,
        params,
      }),
      providesTags: ['courseTemplateTopicDetails'],
    }),

    updateCourseTemplateTopicDetails: builder.mutation<CourseTemplateTopicEntity, CourseTemplateTopicEntity>({
      query: (body) => ({
        url: '/topic',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['courseTemplateTopicList', 'courseTemplateTopicDetails'],
    }),
  }),
});

export const resetCourseTemplateApiState = () => courseTemplateApi.util?.resetApiState();

export const {
  useCourseTemplateDetailsQuery,
  useLazyCourseTemplateDetailsQuery,
  useCourseTemplateListQuery,
  useCreateCourseTemplateMutation,
  useLazyRoleSettingDetailsQuery,
  useEditRoleSettingMutation,
  useUpdateCourseTemplateModuleDetailsMutation,
  useUpdateCourseTemplateTopicDetailsMutation,
  useLazyCourseTemplateTopicDetailsQuery,
  useLazyCourseTemplateModuleDetailsQuery,
} = courseTemplateApi;
