import React from 'react';
import { Typography, Divider, Tag } from 'antd';
import { Button, compileMarkdownToJSX, useModal } from '@tml-component-library/src';
import { CheckCircleFilled } from '@tml-component-library/src/icons';
import { QuestionEntity } from 'api/quiz/types';
import { ManageQuestionModal } from 'features/company-admin/manage-question/ManageQuestionModal';
import { LastInteractionInfo } from 'components/LastInteractionInfo';
import { QuestionTypeMessages } from '../constants';
import { messages } from '../messages';

interface QuestionProps {
  question: QuestionEntity;
  editHeaderTitle: string;
  disableEditing: boolean;
}

export const Question = ({ question, disableEditing, editHeaderTitle }: QuestionProps) => {
  const { questionText, correctAnswer, questionType, questionOptions, createdAt, updatedAt, updatedByUserName } =
    question;
  const { open, showModal, hideModal } = useModal();

  const answerArray = correctAnswer?.split(',');
  const optionsKeys = questionOptions ? Object.keys(questionOptions) : [];

  const isQuestionUpdated = Boolean(updatedByUserName);

  return (
    <div className="px-4 py-2 flex flex-col gap-4 w-full my-4 bg-white rounded-lg border border-solid border-custom-natural-gray-5">
      <div>
        <Typography.Text className="font-bold">Question </Typography.Text>
        <Typography.Text className="mr-2.5">(Type: {`${QuestionTypeMessages[questionType]}`})</Typography.Text>
        {isQuestionUpdated && <Tag color="blue">{messages.updated}</Tag>}

        <Divider className="my-1" />

        <div className="mt-1">
          <Typography.Text>{compileMarkdownToJSX(questionText)}</Typography.Text>
        </div>
      </div>

      {correctAnswer && (
        <div>
          <Typography.Text className="font-bold">Answer</Typography.Text>
          <Divider className="my-1" />

          {optionsKeys?.length ? (
            <div className="mt-2 flex flex-col gap-1">
              {optionsKeys.map((key) => {
                const isCorrect = answerArray?.includes(key.toUpperCase());

                return (
                  <div
                    key={key}
                    className={`w-full flex items-center justify-between px-2 py-1 rounded-lg border border-solid ${isCorrect ? 'border-custom-green-5' : ' border-custom-natural-gray-5'}`}
                  >
                    <div>
                      <Typography.Text className="font-bold">{key.toUpperCase()} :</Typography.Text>
                      <Typography.Text className="ml-2.5">{questionOptions?.[key]}</Typography.Text>
                    </div>

                    {isCorrect && <CheckCircleFilled className="text-2xl text-custom-green-5" />}
                  </div>
                );
              })}
            </div>
          ) : (
            <Typography.Text className="m-0">{compileMarkdownToJSX(correctAnswer)}</Typography.Text>
          )}
        </div>
      )}

      <div className="w-full flex justify-between items-center">
        <LastInteractionInfo
          createdAt={createdAt}
          updatedAt={updatedAt}
          updatedByUserName={updatedByUserName}
        />
        <Button
          size="small"
          type="primary"
          onClick={showModal}
          disabled={disableEditing}
        >
          {messages.editQuestion}
        </Button>
      </div>

      <ManageQuestionModal
        editHeaderTitle={editHeaderTitle}
        open={open}
        hideModal={hideModal}
        question={question}
      />
    </div>
  );
};
