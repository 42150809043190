import {
  CustomFormInputLabel,
  FormField,
  TextInput,
  useFormContext,
  messages as sharedMessages,
} from '@tml-component-library/src';
import { ManageEntityModalFooter } from 'components/ManageEntityModalFooter';
import { Divider } from 'antd';
import { messages } from '../messages';
import { ManageDetailsFields } from '../constants';

interface ManageDetailsFormProps {
  onCancel: () => void;
}

export const ManageDetailsForm = ({ onCancel }: ManageDetailsFormProps) => {
  const { isSubmitting, submit } = useFormContext();

  return (
    <div>
      <div className="flex flex-col gap-2.5">
        <FormField
          name={ManageDetailsFields.COMPANY_NAME}
          label={messages.internalCompanyName}
          className="w-full m-0"
          required
        >
          <TextInput
            placeholder={messages.enterCompanyName}
            required
          />
        </FormField>

        <FormField
          name={ManageDetailsFields.DOMAIN_INDUSTRY}
          label={messages.domainIndustry}
          className="w-full m-0"
          required
        >
          <TextInput
            placeholder={messages.enterDomainIndustry}
            required
          />
        </FormField>
      </div>

      <Divider className="my-2.5" />

      <CustomFormInputLabel
        content={sharedMessages.requiredFields}
        required
      />

      <div className="mt-3">
        <ManageEntityModalFooter
          actionDisabled={isSubmitting}
          onCancel={onCancel}
          onSave={submit}
        />
      </div>
    </div>
  );
};
