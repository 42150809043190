import { NavigateFunction } from 'react-router-dom';
import { ExchangeTokenStatuses } from '../../../common/auth/AuthProvider';
import { Routes } from '../../routes';

export const handleNavigateByStatus = (navigate: NavigateFunction) => (status: ExchangeTokenStatuses) => {
  switch (status) {
    case ExchangeTokenStatuses.SUCCESS:
      navigate(Routes.signInSuccess());
      break;
    case ExchangeTokenStatuses.TOKEN_NOT_EXIST:
    case ExchangeTokenStatuses.ERROR:
      navigate(Routes.signInError(), { state: { error: true } });
      break;
    default:
      break;
  }
};
