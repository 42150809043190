import { FileCategory } from 'api/file/types';
import { QuestionType } from 'api/quiz/types';

export const messages = {
  customizableTemplate: 'Customizable Template',
  additionalLessons: 'Additional Lessons',
  exercises: 'Exercises',
  deleteDocument: (name?: string) => `Delete the document “${name}”`,
};

export const CATEGORY_TYPES = {
  [FileCategory.CUSTOMIZABLE_TEMPLATE]: messages.customizableTemplate,
  [FileCategory.ADDITIONAL_LESSONS]: messages.additionalLessons,
  [FileCategory.EXERCISES]: messages.exercises,
};

export const QUESTION_TYPE_TITLES: Record<QuestionType, string> = {
  [QuestionType.ILE_ESSAY]: 'Interactive Learning Exercises',
  [QuestionType.OPEN]: 'Open',
  [QuestionType.MULTIPLE]: 'Multiple Choice',
};
