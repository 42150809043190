import { ReactNode } from 'react';
import { Layout, theme } from 'antd';

type MainLayoutProps = {
  sider: ReactNode;
  content: ReactNode;
  header?: ReactNode;
  notification?: ReactNode;
};

export const MainLayout = ({
  sider,
  content,
  header,
  notification,
}: MainLayoutProps) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout className="h-screen">
      {notification && (
        <Layout.Header
          className="h-10 p-0"
          style={{ background: colorBgContainer }}
        >
          {notification}
        </Layout.Header>
      )}
      <Layout>
        <Layout.Sider width="w-64">{sider}</Layout.Sider>
        <Layout>
          {header && (
            <Layout.Header
              className="flex items-center p-0"
              style={{ background: colorBgContainer }}
            >
              <div className="px-6 text-base">{header}</div>
            </Layout.Header>
          )}
          <Layout.Content className="m-6">
            <div
              className="h-full p-6 pb-0 min-h-96"
              style={{
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              {content}
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
