import { Typography } from 'antd';
import { CloudDownloadOutlined, EditOutlined, UploadOutlined } from '@tml-component-library/src/icons';
import { FileType } from 'api/file/types';
import { KnowledgeBaseFileList } from 'features/company-admin/knowledge-base-file-list';
import { messages } from './messages';
import { SupplementEditTooltip, SupplementEditTooltipProps } from './components';
import { useManageKbDocumentContext } from './ManageKbDocumentProvider';

export const EDIT_SUPPLEMENT_TOOLTIPS: SupplementEditTooltipProps['tooltips'] = [
  {
    icon: <CloudDownloadOutlined />,
    text: messages.downloadTemplateDocument,
  },
  {
    icon: <EditOutlined />,
    text: messages.editToCustomize,
  },
  {
    icon: <UploadOutlined />,
    text: messages.uploadCustomizedDocument,
  },
];

export const SupplementsTab = () => {
  const { moduleData } = useManageKbDocumentContext();

  return (
    <div className="flex flex-col gap-3.5">
      <div className="flex flex-col gap-2.5">
        <Typography.Text className="text-base font-bold">{messages.suggestedSupplements}</Typography.Text>

        <div className="h-60 overflow-hidden">
          <KnowledgeBaseFileList
            fileType={FileType.TM_DOCUMENT}
            componentId={moduleData?.basedOnModuleId}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2.5">
        <Typography.Text className="text-base font-bold">{messages.useResourcesAbove}</Typography.Text>

        <SupplementEditTooltip tooltips={EDIT_SUPPLEMENT_TOOLTIPS} />
      </div>
    </div>
  );
};
