import { useDepartmentListQuery } from 'api/department/department.api';

export const useSelectDepartment = () => {
  const { data: departmentListData, isLoading } = useDepartmentListQuery();

  const options = departmentListData?.departments?.map(({ name, id }) => ({
    key: id,
    label: name,
    value: id,
  }));

  return {
    isLoading,
    options,
  };
};
