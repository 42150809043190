import { Outlet } from 'react-router-dom';
import { LogoBlack } from '../icons';

export const LoginLayout = () => {
  return (
    <main className="w-full h-screen flex items-center justify-center bg-custom-natural-gray-3">
      <div className="flex flex-col items-center justify-center px-24 pt-14 pb-6 rounded-lg bg-white">
        <div className="mb-4">
          <LogoBlack />
        </div>
        <Outlet />
      </div>
    </main>
  );
};
