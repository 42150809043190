import { useMemo, useState, Key } from 'react';
import { Divider, Tree, TreeProps, Typography } from 'antd';
import { Button, messages as sharedMessages } from '@tml-component-library/src';
import { CaretDownOutlined } from '@tml-component-library/src/icons';
import { CourseTemplateDetailsResponse } from 'api/course-template/types';
import { KnowledgeBaseComponent } from 'api/knowledge-base/types';
import { ComponentType } from 'api/quiz/types';
import { messages } from '../messages';
import { prepareTreeData } from '../helpers/prepareTreeData';
import { TreeItem } from '../types';

interface ManageRegenerationKnowledgeBaseFormProps {
  data?: CourseTemplateDetailsResponse;
  onCancel: () => void;
  onRegenerate: (components: KnowledgeBaseComponent[]) => Promise<void>;
  isSubmitting: boolean;
}

export const ManageRegenerationKnowledgeBaseForm = ({
  data,
  onCancel,
  onRegenerate,
  isSubmitting,
}: ManageRegenerationKnowledgeBaseFormProps) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>();
  const [checkedKeys, setCheckedKeys] = useState<TreeItem[]>([]);
  const [halfCheckedKeys, setHalfCheckedKeys] = useState<TreeItem[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

  const onExpand: TreeProps['onExpand'] = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck: TreeProps['onCheck'] = (_, { checkedNodes, halfCheckedKeys: halfCheckedItems }) => {
    const checkedValues = checkedNodes.map((node: any) => {
      return {
        id: node.key,
        isRoot: node.isRoot,
      };
    });

    const halfCheckedValues = halfCheckedItems?.map((key) => ({
      id: key as string,
      isRoot: true,
    }));

    if (halfCheckedValues) setHalfCheckedKeys(halfCheckedValues);
    setCheckedKeys(checkedValues);
  };

  const treeData = useMemo(() => (data ? prepareTreeData(data) : undefined), [data]);

  const selectAll = () => {
    if (treeData) setCheckedKeys(treeData.allKeys);
  };

  const unselectAll = () => setCheckedKeys([]);

  const handleRegeneration = () => {
    const components: KnowledgeBaseComponent[] = [...checkedKeys, ...halfCheckedKeys].map((key) => ({
      componentId: key.id,
      isFinalized: false,
      componentType: key.isRoot ? ComponentType.MODULE : ComponentType.TOPIC,
    }));

    onRegenerate(components);
  };

  return (
    <div className="flex flex-col gap-2.5">
      <div className="flex gap-2.5">
        <Button
          onClick={selectAll}
          className="p-0"
          type="link"
        >
          {messages.selectAll}
        </Button>

        <Button
          onClick={unselectAll}
          className="p-0"
          type="link"
        >
          {messages.unselectAll}
        </Button>
      </div>

      <Divider className="m-0" />

      <div>
        <Tree
          className=""
          treeData={treeData?.tree || []}
          checkable
          showIcon
          showLine
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={onCheck}
          checkedKeys={checkedKeys.map((key) => key.id)}
          switcherIcon={<CaretDownOutlined />}
        />

        <Divider className="mt-1 mb-3" />
      </div>

      <div className="flex justify-between">
        <div className="flex items-center">
          <Typography.Text className="text-base">{messages.selectedItemsCount(checkedKeys.length)}</Typography.Text>
        </div>

        <div className="flex items-center gap-2">
          <Button onClick={onCancel}>{sharedMessages.cancel}</Button>

          <Button
            disabled={!checkedKeys.length || isSubmitting}
            type="primary"
            onClick={handleRegeneration}
          >
            {messages.regenerateSelected}
          </Button>
        </div>
      </div>
    </div>
  );
};
