import { KnowledgeBaseList } from 'features/company-admin/knowledge-base-list';
import { Meta } from '@tml-component-library/src/components/Meta';

export const KnowledgeBasesPage = () => (
  <>
    <Meta title="Course Versions" />

    <KnowledgeBaseList />
  </>
);
