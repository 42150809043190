export const messages = {
  companyDocuments: 'Company Documents',
  addDocument: 'Add a Document',
  fileName: 'File Name',
  description: 'Description',
  department: 'Departments',
  course: 'Course',
  module: 'Module',
  topic: 'Topic',
  date: 'Date',
  version: 'Version',
  action: 'Action',
  edit: 'Edit',
};
