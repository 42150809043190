import { DatePicker, DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DAY_FORMAT } from '../../shared';

dayjs.extend(utc);

export const Datepicker = ({
  value,
  defaultValue,
  ...props
}: DatePickerProps) => (
  <DatePicker
    format={DAY_FORMAT}
    {...props}
    value={value && dayjs(value).utc(true)}
    defaultValue={defaultValue && dayjs(defaultValue).utc(true)}
  />
);
