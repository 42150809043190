import { ComponentType } from '../quiz/types';
import { ListParams, ListResponse } from '../../utils';

export enum StatusType {
  CREATED = 'CREATED',
  PREPARING_FILES = 'PREPARING_FILES',
  CREATING_KNOWLEDGE_BASE = 'CREATING_KNOWLEDGE_BASE',
  READY_TO_GENERATE_QUESTIONS = 'READY_TO_GENERATE_QUESTIONS',
  CREATING_QUESTIONS = 'CREATING_QUESTIONS',
  READY_PUBLISH = 'READY_PUBLISH',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum KnowledgeBaseCommand {
  PUBLISH_KNOWLEDGE_BASE = 'PUBLISH_KNOWLEDGE_BASE',
  ARCHIVED_PUBLISH_KNOWLEDGE_BASE = 'ARCHIVED_PUBLISH_KNOWLEDGE_BASE',
  REGENERATE_KNOWLEDGE_BASE = 'REGENERATE_KNOWLEDGE_BASE',
}

export interface KnowledgeBaseEntity {
  id: string;
  companyId: string;
  courseTemplateId: string;
  courseId: string;
  name: string;
  status: StatusType;
  errorMessage?: string;
  isReadyForGenerate: boolean;
  version: number;
  regenerationVersion: number;
  createdAt: string;
  updatedAt: string;
}

export interface KnowledgeBaseListResponse extends ListResponse {
  totalKnowledgeBases: number;
  knowledgeBases: KnowledgeBaseEntity[];
}

export interface KnowledgeBaseListRequest extends ListParams {
  courseId?: string;
  courseTemplateId?: string;
  status?: StatusType;
}

export interface KnowledgeBaseDetailsRequest {
  id: string;
}

export interface UpdateKnowledgeBaseRequest extends KnowledgeBaseDetailsRequest {
  command: KnowledgeBaseCommand;
}

interface KnowledgeBaseCourseEntity {
  id: string;
}

export interface CreateKnowledgeBaseRequest {
  course: KnowledgeBaseCourseEntity;
  name?: string;
  courseTemplateId?: string;
}

export interface KnowledgeBaseComponent {
  componentId: string;
  componentType: ComponentType;
  isFinalized: boolean;
}

export interface RegenerateKnowledgeBaseComponentsRequest {
  knowledgeBaseId: string;
  components: KnowledgeBaseComponent[];
}
